import React from 'react';
import { Image } from 'antd';

function ImageViewer({ 
    isPreviewVisible, 
    selectedImage1, 
    onClose, 
    isMultiImage 
}) {
    const handlePreviewClose = () => { 
        if (onClose) {
            onClose(); 
        }
    };

    // Render images based on whether it's multi-image or single-image
    return (
        <Image.PreviewGroup preview={{ visible: isPreviewVisible, onClose: handlePreviewClose }}>
            {isMultiImage ? (
                selectedImage1?.map((image, index) => (
                    <Image
                        key={index}
                        src={image}
                        alt={`Image ${index + 1}`}
                        style={{ maxWidth: '100%', maxHeight: '80vh', zIndex: '9999',display:'none' }}
                    />
                ))
            ) : (
                <Image
                    src={selectedImage1[0]} // Display the first image if in single-image mode
                    alt="Enlarged Image"
                    style={{ maxWidth: '100%', maxHeight: '80vh', zIndex: '9999',display:'none' }}
                />
            )}
        </Image.PreviewGroup>
    );
}

export default ImageViewer;
