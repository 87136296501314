import React, { memo,useEffect,useState } from 'react';
import Editbankdetailmodal from './editbankdetailmodal';
import { useCountryData } from '../../api/hook/mobilehook';
const Bankeditcomponents = memo(({ show,mapvalues,setMapvalues,handleClose,getbanksall, setIsLoading4, showErrorToast})=> {
  const {
    mccId, dialCode, countryCode, countryOptions,mobile_length,mobile_starting_digits,isLoading,setMccId,setDialCode,setCountryCode,setMobile_length,setMobile_starting_digits,
    loadCountryBySelection,loadCountryByIp
  } = useCountryData();
  const [mobileChange, setMobileChange] = useState('');
    const [accountdetails,setAccountdetails] = useState({accountholdername:'',accountholdernumber:'',phonenumber:'',countryOptions:[], dial_code:'',mcc_id: null,country_code:'',isLoading:false,country: null, bank: null, city: null, branch: null,id:'',mobileerror:''}); 
    useEffect(()=>{
      if(mapvalues){
        setAccountdetails((prev)=>({
            ...prev,
            accountholdername:mapvalues.account_holdername || '',
            accountholdernumber:mapvalues.account_number || '',
            // phonenumber:mapvalues.phone_number || '',
            // country_code:mapvalues.country_code || '',
            country:mapvalues.country_id || '',
            city:mapvalues.city_id || '',
            bank:mapvalues.bank_id || '',
            branch:mapvalues.branch_id || '',
            id:mapvalues.id || ''
        }))
        setMobileChange(mapvalues.phone_number || '');
        setCountryCode(mapvalues.country_code || '');
        setMccId(mapvalues.mcc_id || '');
        setDialCode(mapvalues.code || '');
      }
    },[mapvalues]); 
  return (
    <>
     <Editbankdetailmodal show={show} accountdetails={accountdetails} setMapvalues={setMapvalues} setAccountdetails={setAccountdetails} handleClose={handleClose} getbanksall={getbanksall} setIsLoading4={setIsLoading4} showErrorToast={showErrorToast} setMobileChange ={setMobileChange}  mobileChange ={mobileChange}  countryData={{
          mccId,
          dialCode,
          countryCode,
          countryOptions,
          mobile_length,
          mobile_starting_digits,
          isLoading,
          setMccId,
          setDialCode,
          setCountryCode,
          setMobile_length,
          setMobile_starting_digits,
          loadCountryBySelection,
          loadCountryByIp,
        }} />
    </>
  )
})

export default Bankeditcomponents;