import React,{useState,useEffect} from 'react';
import Navbar from '../navbar';
import Cards from './cardspages/cards';
import Registerservices from './registerservices';
import '../App.css';
import Mobilemoney from './mobilemoneypages/mobilemoney';
import Organization from './organizationpages/organization';
import Bank from './bankpages/bank';
function Addddsource({setIsLoading4}) {
    const [source, setSource] = useState(() => {
        const storedActiveSection = localStorage.getItem('activeSection');
        return storedActiveSection || 'cards';
      });
      useEffect(() => {
        localStorage.setItem('activeSection', source);
      }, [source]);
  return (
    <>
    <Navbar/>
    <div className="container-fluid addsourcesdiv" style={{backgroundColor:'#F8F8F8',width:'100%',minHeight:'100vh'}}>
    <div className="row collectionrow">
    <div className="col-12 col-lg-3 col-md-4 col-xl-3 col-xxl-3 col_corre_2">
        <Registerservices  setSource={setSource} source={source}/>
    </div>
    {source === "cards" && 
     <Cards setIsLoading4={setIsLoading4}/>}
    {source === "mobilemoney" &&
     <Mobilemoney setIsLoading4={setIsLoading4}/>} 
    {source === "organization" && 
     <Organization setIsLoading4={setIsLoading4}/>}  
    {source === "bank" && 
     <Bank setIsLoading4={setIsLoading4}/>} 
    </div>            
    </div>  
    </>
  )
}

export default Addddsource;