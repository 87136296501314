import React, { useState, useEffect, useCallback } from 'react';
import OtpInput from 'react-otp-input';
import Modal from 'react-bootstrap/Modal';
import modaldelete from '../../images/times-circle-01.png';
import resetpasswordotp from '../../images/Enter-Otp-code.png';
import { getMaskedEmail, getMaskedPhoneNumber } from '../../api/commonfunct.js/common';
import axiosInstance from '../../api/commonfunct.js/axiosinstance';
import { handleApiError } from '../../api/commonfunct.js/common';
import { kycverifyotpapi, kycrecoveryverifyotpapi } from '../../api/url';
import WithToast from '../../api/commonfunct.js/withtoast';

const verifyotpapiurl = kycverifyotpapi();
const kycrecoveryverifyotpapiurl = kycrecoveryverifyotpapi();

function Otpmodal({ show, handleClose, selectedOption, responseemail, responsephoneNumber, responsecountrycode, resendotp, showErrorToast, setIsLoading4, kyc_id,getkycemailmobile,ip,isRecoveryText  }) {
  const [otpState, setOtpState] = useState({
    otp: '',
    countdown: 60, 
  });


  
  const [error422, setError422] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleChange = (otp) => {
    setError422('');
    setOtpState(prevState => ({ ...prevState, otp }));
    if (otp.length === 6 && !isSubmitting) {
      setIsSubmitting(true); // Set flag to true to prevent further submissions
    }
  };

  const handleInputChangeotp = (e) => {
    const isNumber = /^\d*$/.test(e.key);
    if (!isNumber) {
      e.preventDefault();
    }
  };

  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData('text/plain');
    const isNumeric = /^\d*$/.test(pastedText);

    if (!isNumeric) {
      e.preventDefault();
    } else {
      setOtpState((prev) => ({
        ...prev, otp: pastedText
      }));
    }
  };

  const handleClose1 = useCallback(()=>{
    setError422('');
    setOtpState((prev) => ({
      ...prev, otp: ''
    }));
    handleClose();
    setIsLoading4(false);
  },[handleClose,setIsLoading4]);

  const submitOtp = useCallback(async (apiUrl) => {
    const data = {
      data_mode: selectedOption,
      otp: otpState.otp,
      kyc_id: kyc_id
    };
    
    try {
      setIsLoading4(true);
      const response = await axiosInstance.post(apiUrl, data);
      if (response.status === 200) {
        setOtpState(prevState => ({ ...prevState, otp: '', countdown: 60 }));
        setError422('');
        handleClose();
        if(isRecoveryText){
          await getkycemailmobile();
         ip();
        }else{
        getkycemailmobile();
        }
        setIsLoading4(false);
      }
    } catch (error) {
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
  }, [otpState.otp, selectedOption, kyc_id, setIsLoading4,showErrorToast,getkycemailmobile,handleClose,ip,isRecoveryText]);

  const handleOtpSubmit = useCallback(() => {
    submitOtp(verifyotpapiurl);
  }, [submitOtp]);

  const handleOtpSubmitrecovery = useCallback(() => {
    submitOtp(kycrecoveryverifyotpapiurl);
  }, [submitOtp]);

  useEffect(() => {
    if (otpState.otp.length === 6 && isSubmitting) {
      if (!isRecoveryText) {
        handleOtpSubmit();       
      } else {
        handleOtpSubmitrecovery();
      }
    }
  }, [otpState.otp, handleOtpSubmit, handleOtpSubmitrecovery,isSubmitting,isRecoveryText]);

  return (
    <>

      <Modal show={show} dialogClassName="example-walletprofiledialog example-walletprofiledialog1" contentClassName="example-walletcontent example-walletcontent1 " onHide={handleClose1} centered>
        <Modal.Body style={{ margin: '0', padding: '0' }}>
          <div className="text-end">
            <img src={modaldelete} onClick={handleClose1} style={{cursor:'pointer'}} className="text-end cursor-pointer" alt="modaldelete" width='30px' height='auto'></img>
          </div>
          <div className='resetpasspad'>
            <div className='d-flex flex-column justify-content-center align-items-center '>
              <img src={resetpasswordotp} alt='images' className='resetppasslock '></img>
              <h6 className='mt-2 resetpassh6'>Enter OTP Code</h6>
              <p className='resetpassp resetpassp1'>
                Enter the 6-digit code sent<br />
                to <strong>{selectedOption === 'email' ? `${getMaskedEmail(responseemail)}` : `${responsecountrycode}${getMaskedPhoneNumber(responsephoneNumber)}`}</strong><br /> Did not receive OTP?
                <strong className={`${otpState.countdown > 0 ? 'inactiveotpsssp' : 'activeotpsssp'}`} style={{ cursor: otpState.countdown > 0 ? 'not-allowed' : 'pointer' }} onClick={otpState.countdown <= 0 ? resendotp : null}> Resend OTP </strong>
              </p>
              <OtpInput
                value={otpState.otp}
                onChange={handleChange}
                numInputs={6}
                renderInput={(props, index) => (
                  <input {...props} onPaste={handlePaste} className={`custom-inputotp mt-4`} style={{ border: otpState.otp[index] ? '1px solid black' : '' }} onKeyPress={handleInputChangeotp} />
                )}
              />
              {error422 && <div className="error-message">{error422}</div>}
              <div className='mt-3  d-flex justify-content-center w-100'>
                <button type="button" className='resendotp'>
                  {otpState.countdown > 0 ? `${otpState.countdown}S` : `${otpState.countdown}S`}
                </button>
              </div>
              <p className='mt-4 mb-4 custom_trouble'>Having any trouble?<strong className='custom_trouble_help'> Get Help</strong></p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default WithToast(Otpmodal);
