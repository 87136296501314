import React, { useCallback, useEffect, useState } from 'react';
import tick from "../../images/user-circle.png";
import axiosInstance from '../../api/utils/axiosutils';
import { getkycprofileapi, updatekycprofileapi,publicDomain } from '../../api/helper/urlhelper';
import { handleApiError, customStyles1, CustomSuffixIcon ,handleKeyDown, useRouteParams,allfunctions, validateFileSize} from '../../api/utils/utils';
import WithToast from '../../api/hook/withtoasthook';
import eye2 from '../../images/eye-02.png';
import Select from 'react-select';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import Imageviewer from '../../components/imageviewer';
import ProfileImage from '../../components/profileimage';

const getkycprofileapiurl = getkycprofileapi();
const updatekycprofileapiurl = updatekycprofileapi();
const publicDomainurl = publicDomain();
const genderOptions = [
  { value: 'M', label: 'Male' },
  { value: 'F', label: 'Female' },
  { value: 'O', label: 'Others' }
];

function Profile({ setActiveStep, activeStep,setIsLoading4,showErrorToast }) {
  const navigate = useNavigate();
  const params = useRouteParams();
  const [formValues, setFormValues] = useState({
    image: '',
    name: '',
    gender: '',
    dob: '',
    changeimage: false,
    id: '',
    Selectimage:[],
    isPreviewVisible1:true
  });
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [enabledNext, setEnabledNext] = useState(false);
  const [formValuesError, setFormValuesError] = useState({ imageError: '', nameError: '', genderError: '', dobError: '' });
  const [error422, setError422] = useState('');
  const getKycProfile = useCallback(async () => {
    try {
      const response = await axiosInstance.get(getkycprofileapiurl);
      if (response.status === 200) {
        const profileData = response.data.data;
        // if(profileData.image){
        //    const profile = `${publicDomainurl}/${profileData.image}`; 
        //    setFormValues((prev)=>({
        //     ...prev,
        //     image:profile
        //    }))
        // }else{
        //   setFormValues({
        //     image:''
        //    })
        // }
        setFormValues({
          image:profileData.image || '',
          name: profileData.name || '',
          gender: profileData.gender || '',
          dob: profileData.dob || '',
          changeimage:  profileData.image_status ? true : false,
          id: profileData.kyc_id || ''
        });
      }
    } catch (error) {
      if(error.response){
        if(error.response.status === 422){
          const code = error.response.data.error_code;
          if(code === 'Kyc not available'){
            navigate(`/u/${params}`);
          }else{
            handleApiError(error, null, null, true, showErrorToast);
          }
        }
      }
      handleApiError(error, null, null, true, showErrorToast);
    }
  }, [showErrorToast]);
  useEffect(() => {
    allfunctions([getKycProfile]);
    // getKycProfile();
  }, [allfunctions]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const error = validateFileSize(file, 2);
      if (error) {
        setFormValuesError((prev)=>({
          ...prev,imageError:error
        }))
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormValues((prev) => ({
          ...prev,
          image: reader.result,
          changeimage: true
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    document.getElementById('fileInput').click();
    setError422('');
    setFormValuesError((prev)=>({
      ...prev,imageError:''
    }))
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [actionMeta.name]: selectedOption.value,
    }));
  };

  const handleKeyDown1 = (e) => {
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(e.key)) {
      e.preventDefault();
      setFormValuesError((prev) => ({
        ...prev,
        nameError: 'Only alphabets are allowed'
      }));
    } else {
      setFormValuesError((prev) => ({
        ...prev,
        nameError: ''
      }));
    }
  };

  const handledelete = () => {
    setFormValues((prev)=>({
        ...prev,changeimage:false,image:''
    }));
  }
  
  const imageview = (image) => {
    if(image.startsWith('data:')){
      setFormValues((prev)=>({
        ...prev,Selectimage:[image]
     })); 
    }else{
      const profile = `${publicDomainurl}/${image}`;
      setFormValues((prev)=>({
        ...prev,Selectimage:[profile]
     }));
    }
     setPreviewVisible(true);
  }
  
  const check = useCallback(()=>{
       const valid = (
        formValues.name !== '' && formValues.gender !== '' && formValues.dob !== '' && formValues.image !== '' && formValues.changeimage !== false
       );    
       setEnabledNext(valid);
  },[ formValues.name,formValues.gender,formValues.dob,formValues.image,formValues.changeimage]);

  useEffect(()=>{
    check();
  },[check])
  
  const handleNext = async () => {
    const data = {
      kyc_id: formValues.id,
      name: formValues.name,
      gender: formValues.gender,
      dob: formValues.dob,
      image: formValues.image
    };
    try {
      setIsLoading4(true);
      const response = await axiosInstance.post(updatekycprofileapiurl, data);
      if (response.status === 200) {
        const nextStep = activeStep + 1;
        setActiveStep(nextStep);
        localStorage.setItem('activeStep', nextStep);
        setIsLoading4(false);
      }
    } catch (error) {
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
  };
//   useEffect(() => {
//     const storedActiveSection = localStorage.getItem('activeNavItem'); // Retrieve the active section from localStorage
 
//     if (storedActiveSection) {
//       setSource(storedActiveSection); 
//     }
//  }, []);
  const handleBack = () => {
    navigate(`/u/${params}/`)
  };
  const disableFutureDates = (current) => {
    return current && current > dayjs().endOf('day');
  };
  // const stalin = () => {
  //   window.location.href = `https://myaccounts.mc.heptotechnologies.org/login?redirecturl=${encodeURIComponent('http://localhost:8000/u/0/wallet/profile-setups')}`
  // }
  return (
    <>
    <div className="wallet-profilepad">
      {/* <button onClick={stalin}>sas</button> */}
      {formValues.changeimage ? (
        <div className="wallet-profilediv">
          <div className="profilediv profilediv1">
            <ProfileImage containerClass="profile_user_image_div" imageSrc={formValues.image.startsWith('data:') ? formValues.image : `${publicDomainurl}/${formValues.image}`} imageview={imageview} viewimage={formValues.image} showeye={true} eyeclassname='profile_eye_' imgClass="modal_user_profile"/>
            {/* <div className="header-profile-user border-dark user" style={{ width: "70px", height: "70px", borderRadius: "50%", marginRight: "10px", position: 'relative' }}>
              <img id="login_img_for_profile" src={formValues.image.startsWith('data:') ? formValues.image : `${publicDomainurl}/${formValues.image}` } className="rounded-circle border-dark" style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center center" }} />
              <img src={eye2} onClick={()=>imageview(formValues.image)} alt='eye2' style={{ width: "28px", height: "auto", position: "absolute", top: "63%", right: "-5px", objectFit: "cover" }} />
            </div> */}
            <div>
              <p className="walletmaximagep">Upload Your Picture</p>
              <p className='walletmaximage'>Max image size - 2mb</p>
            </div>
          </div>
          <div className="profile-uploadbuttondiv profile-uploadbuttondiv1">
            <button className='profile-upload profile-uploadreplace' onClick={triggerFileInput}>Replace</button>
            <input type="file" accept="image/png, image/jpg, image/jpeg" id="fileInput" style={{ display: 'none' }} onChange={handleImageUpload} />
            <button className="profile-upload profile-uploaddelete" onClick={handledelete}>Delete</button>
          </div>
        </div>
      ) : (
        <div className="wallet-profilediv">
          <div className="profilediv">
          <ProfileImage containerClass="profile_user_image_div" imageSrc={tick} imageview={imageview} viewimage={tick} showeye={true} eyeclassname='profile_eye_' imgClass="modal_user_profile"/>
            {/* <div className="header-profile-user border-dark user" style={{ width: "70px", height: "70px", borderRadius: "50%", marginRight: "10px", position: 'relative' }}>
              <img id="login_img_for_profile" src={tick} className="rounded-circle border-dark" style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center center" }} />
              <img src={eye2} onClick={()=>imageview(tick)} alt='eye2' style={{ width: "28px", height: "auto", position: "absolute", top: "63%", right: "-5px", objectFit: "cover" }} />
            </div> */}
            <div>
              <p className=" walletmaximagep">Upload Your Picture</p>
              <p className='walletmaximage'>Max image size - 2mb</p>
            </div>
          </div>
          <div className="profile-uploadbuttondiv">
            <input type="file" accept="image/png, image/jpg, image/jpeg" id="fileInput" style={{ display: 'none' }} onChange={handleImageUpload} />
            <button className="profile-upload" onClick={triggerFileInput}>Upload</button>
          </div>
        </div>
      )}
       {formValuesError.imageError && <p className="error-message">{formValuesError.imageError}</p>}
      <hr style={{ margin: '1.5rem 0px 1rem' }} />
      <div className="mt-3">
        <label className="walletprofile-label">Name<span className="docpopstar">*</span></label>
        <input type="text" name='name' value={formValues.name} onKeyDown={handleKeyDown1} onChange={handleChange} className={`form-control mt-1 walletprofile-input ${formValues.name ? 'walletprofile-input-active' : ''}`} placeholder="Enter your name" />
        {formValuesError.nameError && <p className="error-message">{formValuesError.nameError}</p>}
      </div>
      <div className="mt-3 mb-4">
        <div className="row">
          <div className="col-6">
            <label className="walletprofile-label">
              Gender<span className="docpopstar">*</span>
            </label>
            <Select name="gender" options={genderOptions} className='mt-1' placeholder="Select gender" value={genderOptions.find(option => option.value === formValues.gender)} styles={customStyles1(Boolean(formValues.gender))} onChange={handleSelectChange} />
          </div>
          <div className="col-6">
            <label className="walletprofile-label">
              Date of Birth<span className="docpopstar">*</span>
            </label>
            <DatePicker onKeyDown={handleKeyDown} className='mt-1 custom-pick' disabledDate={disableFutureDates} value={formValues.dob ? dayjs(formValues.dob) : null} onChange={(date) => { setFormValues((prevValues) => ({ ...prevValues, dob: date ? dayjs(date).format('YYYY-MM-DD') : '', })); }} placeholder='Select your DOB' style={{ width: '100%', height: '45px', borderRadius: '6px', border: formValues.dob ? '1px solid black' : '1px solid #DCDCDC', color: formValues.dob ? 'black' : '' }} suffixIcon={<CustomSuffixIcon isActive={formValues.dob} />} />
          </div>
        </div>
      </div>
      <hr className='walletprofile-mar'/>
      {error422 && (<div className='errors'>{error422}</div>)}
      <div className="walletprofile-buttons mt-3">
        <button className={`walletprofile-next me-3 ${enabledNext ? 'walletprofile-next-active' : 'walletprofile-next-disabled'}`} disabled={!enabledNext}  onClick={handleNext}>Next</button>
        <button className="walletprofile-cancel"  onClick={handleBack}>Cancel</button>
      </div>
    </div>

    {formValues.Selectimage && (
                <Imageviewer isPreviewVisible={isPreviewVisible} selectedImage1={formValues.Selectimage}onClose={() => setPreviewVisible(false)} isMultiImage={false} />
    )}
   </>
  );
}



export default WithToast(Profile);
