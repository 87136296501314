import React,{useState,useCallback, useEffect} from 'react'
import WithToast from '../../api/commonfunct.js/withtoast';
import PasscodeModal from './passcodemodal';
import axiosInstance from '../../api/commonfunct.js/axiosinstance';
import { getkyctermsapi,getkycpasscodeapi,updatekyctermsapi } from '../../api/url';
import { allfunctions, handleApiError, useRouteParams } from '../../api/commonfunct.js/common';
import expand from '../../images/expand icon-02.svg';
import { useNavigate } from 'react-router-dom';
const getkyctermsapiurl = getkyctermsapi();
const updatekyctermsapiurl = updatekyctermsapi(); 
const getkycpasscodeapiurl = getkycpasscodeapi();
function Privacyterms({setActiveStep, activeStep,setIsLoading4, showErrorToast}) {
    const [walletpasscodehide,setWalletpasscodehide] = useState(false);
    const [ischecked,setIsChecked] = useState('');
    const [enabled,setEnabled] = useState(false);
    const [showPasscodeModal, setShowPasscodeModal] = useState(false);
    const [passexist,setPassexist] = useState(false);
    const [kyc_id,setKyc_id]=useState('');
    const navigate = useNavigate();
    const params = useRouteParams();
        const getKycData = useCallback(async () => {
          try {
            const [passcodeResponse, termsResponse] = await Promise.all([
              axiosInstance.get(getkycpasscodeapiurl),
              axiosInstance.get(getkyctermsapiurl)
            ]);
      
            if (passcodeResponse.status === 200) {
              const val = passcodeResponse.data.data;
              if (val === null || (Array.isArray(val) && val.length === 0)) {
                setPassexist(true);
              }else{
                setPassexist(false);
              }
            }
      
            if (termsResponse.status === 200) {
              const terms = termsResponse.data.data;
              setKyc_id(terms.kyc_id);
              setIsChecked(terms.is_terms_conditions || '');
              setWalletpasscodehide(terms.is_terms_conditions ? true : false);
            }
          } catch (error) {
            if(error.response){
              if(error.response.status === 422){
                const code = error.response.data.error_code;
                if(code === 'Kyc not available'){
                  navigate(`/u/${params}`);
                }else{
                  handleApiError(error, null, null, true, showErrorToast);
                }
              }
            }
            handleApiError(error, null, null, true, showErrorToast);
          }
        }, [showErrorToast]);
      
        useEffect(() => {
          // getKycData();
          allfunctions([getKycData]);
        }, [allfunctions]);
        const handleClick = (event) => {
          event.preventDefault();
          event.stopPropagation();
          window.open('https://evzone.app/', '_blank', 'noopener,noreferrer');
        };
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        setWalletpasscodehide(!ischecked);
        // setPassexist(!ischecked);
      };
    const setWalletpass = () => {
       setShowPasscodeModal(true);
    }  
    const check = useCallback(()=>{
        const valid = (
            ischecked
        )
        setEnabled(valid);
    },[ischecked]);
    useEffect(()=>{
        check();
    },[check]);
    const handleClosePasscodeModal = () => {
        getKycData();
        setShowPasscodeModal(false); 
      };
    
    const handleNext = async() => {
        const data = {
            kyc_id : kyc_id,
            is_terms_conditions:ischecked ? 1 : 0
        }
        try{
          setIsLoading4(true);
          const response = await axiosInstance.post(updatekyctermsapiurl,data);
          if(response.status === 200){
            // setActiveStep((prevActiveStep) => prevActiveStep + 1);
            const nextStep = activeStep + 1;
        setActiveStep(nextStep);
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        localStorage.setItem('activeStep', nextStep);
        setIsLoading4(false);
          }
        }catch(error){
            handleApiError(error, setIsLoading4, null, true, showErrorToast);
        }
    }
  //   useEffect(() => {
  //     const storedActiveSection = localStorage.getItem('activeNavItem'); // Retrieve the active section from localStorage
   
  //     if (storedActiveSection) {
  //       setSource(storedActiveSection); 
  //     }
  //  }, []);
    const handleBack = () => {
      const nextStep = activeStep - 1;
        setActiveStep(nextStep);
        localStorage.setItem('activeStep', nextStep);
        // setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  return (
    <>
     <div className="wallet-profilepad">
                  <h5>Terms and Condition</h5>
                  <div className="wallet-profilescroll">
                    <p className='termsp'>
                      These terms and conditions outline the rules and regulations for the use of Company Name's Website, located
                      at Website.com. By accessing this website we assume you
                      accept these terms and conditions. Do not continue to use
                      Website Name if you do not agree to take all of the terms
                      and conditions stated on this page. The following
                      terminology applies to these Terms and Conditions, Privacy
                      Statement and Disclaimer Notice and all Agreements:
                      “Client”, “You” and “Your” refers to you, the person log
                      on this website and compliant to the Company's terms and
                      conditions. “The Company”, “Ourselves”, “We”, “Our” and
                      “Us”, refers to our Company. “Party”, “Parties”, or “Us”,
                      refers to both the Client and ourselves. All terms refer
                      to the offer, acceptance and consideration of payment
                      necessary to undertake the process of our assistance to
                      the Client in the most appropriate manner for the express
                      purpose of meeting the Client's needs in respect of
                      provision of the Company's stated services, in accordance
                      with and subject to, prevailing law of Netherlands. Any
                      use of the above terminology or other words in the
                      singular, plural, capitalization and/or he/she or they,
                      are taken as interchangeable and therefore as referring to
                      same. Cookies We employ the use of cookies. By accessing
                      Website Name, you agreed to use cookies in agreement with
                      the Company Name's Privacy Policy. Most interactive
                      websites use cookies to let us retrieve the user's details
                      for each visit. Cookies are used by our website to enable
                      the functionality of certain areas to make it easier for
                      people visiting our website. Some of our
                      affiliate/advertising partners may also use cookies.
                    </p>
                  </div>
                  <div className="form-check mt-3 d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="form-check-input input_check me-2"
                      id="customControlInline" 
                      checked={ischecked}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      className="form-check-label forgotpagecheckboxlabel"
                      htmlFor="customControlInline"
                    >
                      I agree to the <strong onClick={handleClick} style={{color:'#0897FF',textDecoration:'underline',cursor:'pointer'}}>Privacy & Terms</strong> <img src={expand} style={{cursor:'pointer'}} onClick={handleClick} alt='expand' width='20px'></img>
                    </label>
                  </div>
                  {walletpasscodehide && passexist &&  (
                    <>
                    <hr className="walletprofile-mar"></hr>
                    <p>To keep your wallet safe! Create a 5-digit memorable Passcode. Don't share it with anyone for extra security.</p>
                    <button className="setwalletprofile-code" onClick={setWalletpass}>Set Wallet Passcode</button>
                    </>
                  )}
                  <hr className="walletprofile-mar"></hr>
                  <div className="walletprofile-buttons mt-3">
                    {/* <button className="walletprofile-save me-3">Save</button> */}
                    <button className={`walletprofile-next me-3 ${enabled ? 'walletprofile-next-active' : 'walletprofile-next-disabled'}`} disabled={!enabled} onClick={handleNext}>Next</button>
                    <button className="walletprofile-cancel"  onClick={handleBack}>Cancel</button>
                  </div>
                </div>
                <PasscodeModal show={showPasscodeModal} kyc_id={kyc_id} handleClose={handleClosePasscodeModal} />
    </>
  )
}

export default WithToast(Privacyterms);