import React,{useState,useCallback,memo,useEffect} from 'react';
import { allfunctions,handleApiError, useRouteParams } from '../../../api/utils/utils';
import axiosInstance from '../../../api/utils/axiosutils';
import WithToast from '../../../api/hook/withtoasthook';
import { getkycgeneraldocumentlistapi } from '../../../api/helper/urlhelper';
import Generaldocument from './generaldocument';
import Generaldocumentlist from './generaldocumentlist';
import { useNavigate } from 'react-router-dom';
const getemergencyrelationlistapiurl = getkycgeneraldocumentlistapi();
const Generalseperate = memo(({setActiveStep, activeStep, showErrorToast, setIsLoading4})=>{
  const navigate = useNavigate();
  const params = useRouteParams();
    const [getgeneraldoc,setGetgeneraldoc] = useState([]);
    const [showAddNew, setShowAddNew] = useState(false);
    const getgeneral = useCallback(async () => {
        try {
            const response = await axiosInstance.get(getemergencyrelationlistapiurl);
            if (response.status === 200) {
                const general = response.data.data;                
                setGetgeneraldoc(general);
            }
        } catch (error) {
          if(error.response){
            if(error.response.status === 422){
              const code = error.response.data.error_code;
              if(code === 'Kyc not available'){
                navigate(`/u/${params}`);
              }else{
                handleApiError(error, null, null, true, showErrorToast);
              }
            }
          }
            handleApiError(error, null, null, true, showErrorToast);
        }
    }, [showErrorToast]);
    useEffect(() => {
        allfunctions([getgeneral])
    }, [allfunctions]);
    const handleAddNewClick = () => {
      setShowAddNew(true); // Show the Emergency component when adding new
  };
  return (
    <>
     {showAddNew ? (
        <Generaldocument  setActiveStep={setActiveStep}
        activeStep={activeStep}
        setIsLoading4={setIsLoading4}
        getgeneraldocid={getgeneraldoc.kyc_id}
        getgeneraldoc={getgeneraldoc}
        setShowAddNew={setShowAddNew}
        getgeneral={getgeneral}/>

     ) : getgeneraldoc.general_documents && getgeneraldoc.general_documents.length > 0 ? (
       <Generaldocumentlist
       setActiveStep={setActiveStep}
      activeStep={activeStep}
      setIsLoading4={setIsLoading4}
      getgeneraldocid={getgeneraldoc.kyc_id}
      getgeneraldoc={getgeneraldoc}
      getgeneral={getgeneral}
      setShowAddNew={setShowAddNew}
      onAddNewClick={handleAddNewClick} 
       />
     ) : (
      <Generaldocument  setActiveStep={setActiveStep}
      activeStep={activeStep}
      setIsLoading4={setIsLoading4}
      getgeneraldocid={getgeneraldoc.kyc_id}
      getgeneraldoc={getgeneraldoc}
      setShowAddNew={setShowAddNew}
      getgeneral={getgeneral}/>
     )}
    </>
  )
})

export default WithToast(Generalseperate);