import {React,useEffect,useState} from "react";
import card from '../images/registered-services/card-black-01.svg';
import cardwhite from '../images/registered-services/card-white-01.svg';
import paypal from '../images/registered-services/paypal-black-01.svg';
import paypalwhite from '../images/registered-services/paypal-white-01.svg';
import bank from '../images/registered-services/bank-black-01.svg';
import bankwhite from '../images/registered-services/bank-white-01.svg';
import mobilemoney from '../images/registered-services/mobile-pay-black-01.svg';
import mobilemoneywhite from '../images/registered-services/mobile-pay-white-01.svg';
import organization from '../images/registered-services/organization-black-01.svg';
import organizationwhite from '../images/registered-services/organization-white-01.svg';
import applepay from '../images/registered-services/apple-pay-black-01.svg';
import applepaywhite from '../images/registered-services/apple-pay-white-01.svg';
import googlepay from '../images/registered-services/google-pay-black-01.svg';
import googlepaywhite from '../images/registered-services/google-pay-white-01.svg';
import '../transactions.css';
function Registerservices({setSource, source}) {
    const [showGooglePay, setShowGooglePay] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setShowGooglePay(window.innerWidth <= 991);
        };

        window.addEventListener('resize', handleResize);

        // Initial check on component mount
        handleResize();

        // Cleanup the event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const handleSource = (source_value) => {
         setSource(source_value);
         localStorage.setItem('activeSection', source);
    }
     useEffect(() => {
    const storedActiveSection = localStorage.getItem('activeSection'); // Retrieve the active section from localStorage

    if (storedActiveSection) {
      setSource(storedActiveSection); 
    }
}, []);
    return (
        <>
            {/* <div style={{backgroundColor:'#F8F8F8', width:'100%',height:'100vh',padding:'20px'}}> */}
            <div className="aside">
                <aside>
                    <div className="pt-4 ps-4 registerservicediv">
                        <h6 className="registerserviceh61">Registered Services</h6>
                    </div>
                    <hr className="registerhr" style={{ width: '100%', maxWidth: '90%', color: '#ADA9A9', margin: '0px 5%', border: 'none' }}></hr>
                    <div className="registerul mt-3">
                        <ul>
                            <li onClick={()=>handleSource("cards")}
                               className={`${source === 'cards' ? 'registerserviceliactive activesystem' : 'registerserviceliinactive'}`}
                                // style={{ backgroundColor: source === "cards" ? '#C5FFED' : 'transparent', borderLeft: source === "cards" ? '6px solid #04D08E' : 'none' }}
                                >{source === 'cards' ? 
                                <img src={cardwhite} alt="Active Icon" className="registerulimg" /> :
                                <img src={card} alt="Inactive Icon" className="registerulimg" />
                               }
                                <p className={`mb-1 ${source === 'cards' ? 'registerservicepactive ' : 'registerservicepinactive'}`}>Cards</p>
                            </li>
                            {showGooglePay &&
                            <li onClick={() => handleSource("Google Pay")}
                                className={` googlepay ${source === 'Google Pay' ? 'registerserviceliactive activesystem' : 'registerserviceliinactive'}`}
                            >{source === 'Google Pay' ?
                                <img src={googlepaywhite} alt="Active Icon" className="registerulimg" /> :
                                <img src={googlepay} alt="Inactive Icon" className="registerulimg" />
                            }
                                <p className={`mb-1 ${source === 'Google Pay' ? 'registerservicepactive ' : 'registerservicepinactive'}`}>Google Pay</p>
                            </li>
                        }
                        {showGooglePay &&
                            <li onClick={() => handleSource("Apple Pay")}
                                className={` googlepay ${source === 'Apple Pay' ? 'registerserviceliactive activesystem' : 'registerserviceliinactive'}`}
                            >{source === 'Apple Pay' ?
                                <img src={applepaywhite} alt="Active Icon" className="registerulimg" /> :
                                <img src={applepay} alt="Inactive Icon" className="registerulimg" />
                            }
                                <p className={`mb-1 ${source === 'Apple Pay' ? 'registerservicepactive' : 'registerservicepinactive'}`}>Apple Pay</p>
                            </li>
                        }
                            
                            {/* <li  onClick={()=>handleSource("paypal")}
                            className={`${source === 'paypal' ? 'registerserviceliactive activesystem' : 'registerserviceliinactive'}`}
                                // style={{ backgroundColor: source === "paypal" ? '#C5FFED' : 'transparent', borderLeft: source === "paypal" ? '6px solid #04D08E' : 'none' }}
                            >  {source === 'paypal' ? 
                            <img src={paypalwhite} alt="Active Icon" className="registerulimg" /> :
                            <img src={paypal} alt="Inactive Icon" className="registerulimg" />
                           }
                                <p className={`mb-1 ${source === 'paypal' ? 'registerservicepactive' : 'registerservicepinactive'}`}>Paypal</p>
                            </li> */}
                            <li  onClick={()=>handleSource('mobilemoney')}
                            className={`${source === 'mobilemoney' ? 'registerserviceliactive activesystem' : 'registerserviceliinactive'}`}
                                // style={{ backgroundColor: source === "mobilemoney" ? '#C5FFED' : 'transparent', borderLeft: source === "mobilemoney" ? '6px solid #04D08E' : 'none' }}
                            >   {source === 'mobilemoney' ? 
                            <img src={mobilemoneywhite} alt="Active Icon" className="registerulimg" /> :
                            <img src={mobilemoney} alt="Inactive Icon" className="registerulimg" />
                           }
                                <p className={`mb-1 ${source === 'mobilemoney' ? 'registerservicepactive' : 'registerservicepinactive'}`}>Mobile Money</p>
                            </li>
                            <li onClick={()=>handleSource('bank')}
                            className={`${source === 'bank' ? 'registerserviceliactive activesystem' : 'registerserviceliinactive'}`}
                                // style={{ backgroundColor: source === "bank" ? '#C5FFED' : 'transparent', borderLeft: source === "bank" ? '6px solid #04D08E' : 'none' }}
                            >{source === 'bank' ? 
                            <img src={bankwhite} alt="Active Icon" className="registerulimg" /> :
                            <img src={bank} alt="Inactive Icon" className="registerulimg" />
                           }
                                <p className={`mb-1 ${source === 'bank' ? 'registerservicepactive' : 'registerservicepinactive'}`}>Bank</p>
                            </li>
                            <li onClick={()=>handleSource('organization')}
                            className={`${source === 'organization' ? 'registerserviceliactive activesystem' : 'registerserviceliinactive'}`}
                                // style={{ backgroundColor: source === "organization" ? '#C5FFED' : 'transparent', borderLeft: source === "organization" ? '6px solid #04D08E' : 'none' }}
                            >{source === 'organization' ? 
                            <img src={organizationwhite} alt="Active Icon" className="registerulimg" /> :
                            <img src={organization} alt="Inactive Icon" className="registerulimg" />
                           }
                                <p className={`mb-1 ${source === 'organization' ? 'registerservicepactive' : 'registerservicepinactive'}`}>Organization/Company</p>
                            </li>
                        </ul>
                    </div>
                </aside>
            </div>
          
            
            {/* </div> */}
        </>
    );
}
export default Registerservices;