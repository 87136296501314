import React, { useState } from 'react';
import '../../App.css';
import editimage from '../../images/Group 1000001768.svg';
import deleteimage from '../../images/Group 1000001769.svg';
import Editcardmodal from './editcardmodal';
import Swal from 'sweetalert2';
import WithToast from '../../api/commonfunct.js/withtoast';
import axiosInstance from '../../api/commonfunct.js/axiosinstance';
import wifi from '../../images/wifi-03.svg';
import { deletecardsapi } from '../../api/url';
import { handleApiError } from '../../api/commonfunct.js/common';
const deletecardsapiurl = deletecardsapi();
function Creditcard({card,getcardall,setIsLoading4,showErrorToast}) {
  const formattedMonth = card.expired_month.length === 1 ? `0${card.expired_month}` : card.expired_month;
  const formattedCardNumber = card.card_number.replace(/(.{4})/g, '$1 ');
  const [show,setShow] = useState(false);
  const [mapvalues,setMapvalues] = useState([]);
  const handleClose = () => {
    setShow(false);
    setMapvalues([]);
  }
  const cardedit = (card) => {
   setShow(true);
   setMapvalues(card);
  }
  const carddelete = async(card) => {
    try{
      const result = await Swal.fire({
        title: `Delete ${card.card_type} Card?`,
        text: `Are you sure you want to delete this ${card.card_type} Card`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });
      if(result.isConfirmed){
        setIsLoading4(true);
        const response = await axiosInstance.get(`${deletecardsapiurl}?card_id=${card.id}`);
        if(response.status === 200){
            await getcardall();
            setIsLoading4(false);
        }
      }
    }catch(error){
        handleApiError(error,setIsLoading4,null,true,showErrorToast);
    }
  }
  return (
    <>
    <div className={`${card.card_type === 'credit' ? 'stalin1' : 'stalin'} creditpad`}>  
      <div className='d-flex justify-content-between'>
        <p className='cardp'>{card.card_type.toUpperCase()} CARD</p>
        <div className='d-flex align-items-center'>
        <p className='cardp me-1'>BANK NAME</p>
        <img src={wifi} className='cardpwifi'></img>
        </div>
      </div>
      <div className='card_center'><p className='cardnumber'>{formattedCardNumber}</p></div>
      <div className='d-flex justify-content-between align-items-center'>
        <p className='card4_digit'>{card.card_number.slice(0,4)}</p>
        <p className='card4_digit_valid'>{`VALID THRU ${formattedMonth}/${card.expired_year.slice(2,4)}`}</p>
      </div>
      <div className='d-flex align-items-center mt-2 justify-content-between'>
        <p className='cardholderaname'>{card.cardholder_name.toUpperCase()}</p>
        <div className='d-flex align-items-center gap-1'>
          <img src={editimage} onClick={()=>cardedit(card)} alt='edit' className='cardeditimage'></img>
          <img src={deleteimage} onClick={()=>carddelete(card)} alt='delete' className='cardeditimage'></img>
        </div>
      </div>
      </div>  
      <Editcardmodal show={show} handleClose={handleClose} getcardall={getcardall} mapvalues={mapvalues} setIsLoading4={setIsLoading4}/>
    </>
  )
}

export default WithToast(Creditcard);