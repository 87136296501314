import React from 'react';
import defaultCloseIcon from '../images/times-circle-01.png'; // Update this path as needed

function Closeicon({ src, onClick , className}) {
  return (
    <>
      <img src={src || defaultCloseIcon} onClick={onClick} className={className} alt="closeicon"/>
    </>
  );
}

export default Closeicon;
