import React,{useState,useRef,useEffect,useCallback} from 'react'
import WithToast from '../../../api/hook/withtoasthook';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { disablePastDates,disableFutureDates,handleKeyDown,CustomSuffixIcon, handleApiError} from '../../../api/utils/utils';
import ImageViewer from '../../../components/imageviewer';
import axiosInstance from '../../../api/utils/axiosutils';
import { updatekycgeneraldocumnetapi } from '../../../api/helper/urlhelper';
import Fileuploader from '../../../components/fileuploader';
const updatekycgeneraldocumnetapiurl = updatekycgeneraldocumnetapi();
function Generaldocument({setActiveStep, activeStep, setIsLoading4,getgeneraldoc,getgeneraldocid,getgeneral,setShowAddNew,showErrorToast}) {
    const [error422,setError422] =useState('');
    const [isenabled,setIsenbled] = useState(true);
    const fileInputRef = useRef(null);
    const [formValues, setFormValues] = useState({
        type:'',
        DocumentName:'',
        dateOfIssue: '',
        kyc_id:getgeneraldocid,
        expiryDate: '',
      });
      const[isPreviewVisible,setPreviewVisible]=useState(false);
      const [fileSize, setFileSize] = useState(0);
      const [format, setFormat] = useState('');
      const [docname,setDocname] = useState('');
      const [uploading, setUploading] = useState(false);
      const [progress, setProgress] = useState(0);
      const [progressColor, setProgressColor] = useState("#28a745");
      const [image, setImage] = useState('');
      const [isOpen, setIsOpen] = useState(false);
      const [error,setError] = useState({
        dateofissueerror:'',frontimageerror:''
      });
      useEffect(()=>{
        setFormValues((prev)=>({
          ...prev,kyc_id:getgeneraldocid
        }));  
      },[getgeneraldocid]);
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        setError((prev)=>({
          ...prev,dateofissueerror:'',frontimageerror:''
        }))
        setError422('');
      };
      const handleOpenChange = (open) => {
        setIsOpen(open);
      }; 
      const handleExpiryClick = (e) => {
        if (!formValues.dateOfIssue) {
          setError((prevState) => ({
            ...prevState,
            dateofissueerror:'Please select Date of Issue first'
          }));
          // setExpiryerror('Please select Date of Issue first');
          setIsOpen(false);
        } else {
          setError((prevState) => ({
            ...prevState,
            dateofissueerror:''
          }));
          setIsOpen(true);
        }
      };
      const handleFrontview = (image) => {
        setFormValues((prev)=>({
            ...prev,selectimage:[image]
           }));
           setPreviewVisible(true);
      }
      const empty = () => {
        setFormValues((prev)=>({
         ...prev,dateOfIssue:'',expiryDate:'',kyc_id:'',type:'',DocumentName:''
        }));
        setImage('');
        setUploading(false);
        setProgress(0);
      }
      const handleNext = async() => {
        const data = {
            kyc_id : formValues.kyc_id,
            document_type: formValues.type,
            document_name:formValues.DocumentName,
            issue_date:formValues.dateOfIssue,
            expiry_date:formValues.expiryDate,
            front_img:image.toString()
        }
        try{
          setIsLoading4(true);
          const response = await axiosInstance.post(updatekycgeneraldocumnetapiurl,data);
          if(response.status === 200){
            await getgeneral();
             empty();
             const nextStep = activeStep;
              setActiveStep(nextStep);
              setShowAddNew(false);
              // setActiveStep((prevActiveStep) => prevActiveStep + 1);
              localStorage.setItem('activeStep', nextStep);
              setError422('');
              setIsLoading4(false);
          }
        }catch(error){
          handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
        }
      }
      const check = useCallback(()=>{
        const valid = (
           formValues.type !== '' && formValues.dateOfIssue !== '' && formValues.expiryDate !== '' && formValues.DocumentName !== '' && image !== ''
        )
        setIsenbled(valid);
    },[formValues.type,formValues.dateOfIssue,formValues.expiryDate,formValues.DocumentName,image]);
    useEffect(()=>{
        check();
    },[check]);
      const handleBack = () => {
        if(getgeneraldoc.general_documents.length > 0){
          setShowAddNew(false);
          const nextStep = activeStep;
          setActiveStep(nextStep);
          localStorage.setItem('activeStep', nextStep);
        } else{
          const nextStep = activeStep - 1;
          setActiveStep(nextStep);
          localStorage.setItem('activeStep', nextStep);
        }
      }
  return (
  <>
     <div className="wallet-profilepad">
        <div className='row'>
        <div className='col-6'>
        <label className="walletprofile-label">Document Type<span className="docpopstar">*</span></label>
        {/* <Select name="type" options={formValues.type} onChange={(selectOption)=>setFormValues((prev)=>({...prev,type:selectOption}))} className='mt-1' placeholder="Select Document Type"  styles={customStyles1(Boolean(formValues.type))}  /> */}
        <input type="text" value={formValues.type} onChange={handleInputChange} name='type' className={`form-control mt-1 walletprofile-input ${formValues.type ? 'walletprofile-input-active' : ''}`} placeholder="Enter Document Type" /> 
        </div>
        <div className='col-6'>
        <label className="walletprofile-label">Document Name<span className="docpopstar">*</span></label>
        <input type="text" value={formValues.DocumentName} onChange={handleInputChange} name='DocumentName' className={`form-control mt-1 walletprofile-input ${formValues.DocumentName ? 'walletprofile-input-active' : ''}`} placeholder="Enter Document Name" /> 
         </div>   
        </div>
        <div className='mt-3'>
        <div className='row'>
        <div className='col-6'>
        <label className="walletprofile-label">Date Of Issue<span className="docpopstar">*</span></label>
        <DatePicker onKeyDown={handleKeyDown} format="DD-MM-YYYY" className='mt-1 custom-pick' disabledDate={disableFutureDates} value={formValues.dateOfIssue ? dayjs(formValues.dateOfIssue) : null} onChange={(date) => { setFormValues((prevValues) => ({ ...prevValues, dateOfIssue: date ? dayjs(date).format('YYYY-MM-DD') : '', })); setError((prev)=>({...prev,dateofissueerror:'',frontimageerror:''}))}} placeholder='YYYY-MM-DD' style={{ width: '100%', height: '45px', borderRadius: '6px', border: formValues.dob ? '1px solid black' : '1px solid #DCDCDC', color: formValues.dateOfIssue ? 'black' : '' }} suffixIcon={<CustomSuffixIcon isActive={formValues.dateOfIssue} />} />
        {error.dateofissueerror && <p className="error-message">{error.dateofissueerror}</p>}
        </div>
        <div className='col-6'>
        <label className="walletprofile-label">Expiry Date<span className="docpopstar">*</span></label>
        <DatePicker onKeyDown={handleKeyDown} format="DD-MM-YYYY" onClick={handleExpiryClick} open={isOpen} onOpenChange={handleOpenChange} className='mt-1 custom-pick' disabledDate={disablePastDates(formValues.dateOfIssue)} value={formValues.expiryDate ? dayjs(formValues.expiryDate) : null} onChange={(date) => { setFormValues((prevValues) => ({ ...prevValues, expiryDate: date ? dayjs(date).format('YYYY-MM-DD') : '', })); setError((prev)=>({...prev,frontimageerror:''}))}} placeholder='YYYY-MM-DD' style={{ width: '100%', height: '45px', borderRadius: '6px', border: formValues.expiryDate ? '1px solid black' : '1px solid #DCDCDC', color: formValues.expiryDate ? 'black' : '' }} suffixIcon={<CustomSuffixIcon isActive={formValues.expiryDate} />} />
         </div>   
        </div>
        </div>
        <Fileuploader handleFrontview={handleFrontview} docname={docname} acceptPDF={false} fileInputRef={fileInputRef} setImage={setImage} format={format} fileSize={fileSize} image={image} progress={progress} setProgress={setProgress} progressColor={progressColor} setProgressColor = {setProgressColor} setFileSize={setFileSize} uploading={uploading} setUploading={setUploading} setFormat={setFormat} setDocname={setDocname} setError422={setError422} setImageerror={(message) =>setError((prev) => ({...prev,frontimageerror: message}))} Acceptformat={['jpg','jpeg','png']}/>
        {error.frontimageerror && <p className="error-message">{error.frontimageerror}</p>}
              <hr className="walletprofile-mar" />
        {error422 && <p className="error-message">{error422}</p>}
        <div className="walletprofile-buttons mt-3">
          <button
            className={`walletprofile-next me-3 ${isenabled ? 'walletprofile-next-active' : 'walletprofile-next-disabled'}`}
            disabled={!isenabled}
            onClick={handleNext}
          >
            Next
          </button>
          <button
            className="walletprofile-cancel"
            // disabled={activeStep === 0}
            onClick={handleBack}
          >
            Cancel
          </button>
        </div>
     </div>
     {formValues.selectimage &&
    <ImageViewer isPreviewVisible={isPreviewVisible} selectedImage1={formValues.selectimage} onClose={() => setPreviewVisible(false)} />
    }
  </>
  )
}

export default WithToast(Generaldocument);