import React,{useEffect,useState,memo} from 'react';
import WithToast from '../../../api/commonfunct.js/withtoast';
import { handleApiError } from '../../../api/commonfunct.js/common';
import eye from '../../../images/eye_green-01.png';
import Swal from 'sweetalert2';
import delet from '../../../images/cancel-02.png';
import { publicDomain,deletenationaldocumentapi } from '../../../api/url';
import edit1 from '../../../images/edit_1-01.png';
import documentgreen from '../../../images/Document_green-01.png';
import Nationaleditmodal from './nationaleditmodal';
import Imageviewer from '../../../api/commonfunct.js/imageviewer';
import axiosInstance from '../../../api/commonfunct.js/axiosinstance';
const publicDomainurl = publicDomain();
const deletenationaldocumentapiurl = deletenationaldocumentapi();
const Nationaldocumentlist = memo(({setActiveStep, activeStep,setIsLoading4,getnationaldocid,getnational,getnationaldoc,showErrorToast})=>{
    const [docvalues,setDocvalues] = useState([]);
    const [mapvalues,setMapvalues] = useState([]);
    const [kyc_id,setKyc_id] = useState('');
    const [show, setShow] = useState(false);
    useEffect(() => {
        setDocvalues(getnationaldoc);
        setKyc_id(getnationaldocid)
    }, [getnationaldoc,getnationaldocid]);
    const [formValues, setFormValues] = useState({
        selectimage:[],
      });
      const [isPreviewVisible, setPreviewVisible] = useState(false);
    const nationaldelete = async(doc) => {
      try{
        const result = await Swal.fire({
          title: 'Delete National Document?',
          text: 'Are you sure you want to delete this National Document',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        });
        if(result.isConfirmed){
          setIsLoading4(true);
          const response = await axiosInstance.get(`${deletenationaldocumentapiurl}?kyc_national_document_id=${doc.id}`);
          if(response.status === 200){
              await getnational();
              setIsLoading4(false);
          }
        }
      }catch(error){
          handleApiError(error,setIsLoading4,null,true,showErrorToast);
      }
    }
    const imageview = (image,image1) => {
    //   const front = `${publicDomainurl}/${image}`;
    //   const back = `${publicDomainurl}/${image1}`;  
      const front = image.includes(publicDomainurl) ? image : `${publicDomainurl}/${image}`;
      const back = image1.includes(publicDomainurl) ? image1 : `${publicDomainurl}/${image1}`;
      setFormValues((prev)=>({
        ...prev,selectimage: [front,back]
      }));
      setPreviewVisible(true);
    }
    const nationaledit = (doc) => {
       setMapvalues(doc)
       setShow(true);
    }
    const handleClose = () =>{
        setShow(false);
        setMapvalues([]);
    }
    const handleNext = () => {
        const nextStep = activeStep + 1;
        setActiveStep(nextStep);
        localStorage.setItem('activeStep', nextStep);
    }
    const handleBack = () => {
        const nextStep = activeStep - 1;
        setActiveStep(nextStep);
        localStorage.setItem('activeStep', nextStep);
    }
  return (
    <>
       <div className="wallet-profilepad" style={{ padding: '0px 40px 10px 55px' }}>
                {docvalues?.national_documents?.map((doc, index) => (
                    <div className="emer_box emer_box1" key={index}>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className=' wid70emer wid70emer1'>
                               <div>
                                <img src={documentgreen} alt='document' className='documentgreen' ></img>
                               </div>
                               <div>
                                <h6 className='emerp1' >National ID</h6>
                                <p className=' emerh6 emerh6_bottom'>{doc.national_id_number}</p>
                               </div>
                            </div>
                            <div className='wid30emer'>
                                <div className='d-flex align-items-center' style={{ gap: '10px' }}>
                                    <div className='w-100'>
                                        <button className='emereditbutton' onClick={() => nationaledit(doc)}><img src={edit1} alt='edit' width='15px' height='15px' className='me-2'></img>Edit</button>
                                    </div>
                                    <div>
                                        <img src={eye} onClick={() => imageview(doc.front_img,doc.back_img)} alt='eye' className='emereyeimage mb-2'></img>
                                        <img src={delet} onClick={() => nationaldelete(doc)} alt='delete' className='emereyeimage'></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <hr className="walletprofile-mar" />
        <div className="walletprofile-buttons mt-3">
          <button
            className='walletprofile-next me-3 walletprofile-next-active'
            onClick={handleNext}
          >
            Next
          </button>
          <button
            className="walletprofile-cancel"
            // disabled={activeStep === 0}
            onClick={handleBack}
          >
            Cancel
          </button>
        </div>
            </div>
            <Nationaleditmodal show={show} setShow={setShow} handleClose={handleClose} doc={mapvalues} setActiveStep={setActiveStep} activeStep={activeStep}  setIsLoading4={setIsLoading4}  getnationaldocid={getnationaldocid} getnational={getnational}/>
            {formValues.selectimage &&
          <Imageviewer isPreviewVisible={isPreviewVisible} selectedImage1={formValues.selectimage} onClose={() => {setPreviewVisible(false);}} isMultiImage={true} />
           }
    </>
  )
})

export default WithToast(Nationaldocumentlist)