import React,{useState,useCallback,memo,useEffect} from 'react';
import { allfunctions,handleApiError, useRouteParams } from '../../../api/commonfunct.js/common';
import axiosInstance from '../../../api/commonfunct.js/axiosinstance';
import WithToast from '../../../api/commonfunct.js/withtoast';
import { getkycnationaldocumentapi } from '../../../api/url';
import Nationaldocument from './nationaldocument';
import Nationaldocumentlist from './nationaldocumentlist';
import { useNavigate } from 'react-router-dom';
const getkycnationaldocumentapiurl = getkycnationaldocumentapi();
const Nationalseperate = memo(({setActiveStep, activeStep, showErrorToast, setIsLoading4})=>{
    const navigate = useNavigate();
    const params = useRouteParams();
    const [getnationaldoc,setGetnationaldoc] = useState([]);
    const getnational = useCallback(async () => {
        try {
            const response = await axiosInstance.get(getkycnationaldocumentapiurl);
            if (response.status === 200) {
                const national = response.data.data;                
                setGetnationaldoc(national);
            }
        } catch (error) {
            if(error.response){
                if(error.response.status === 422){
                  const code = error.response.data.error_code;
                  if(code === 'Kyc not available'){
                    navigate(`/u/${params}`);
                  }else{
                    handleApiError(error, null, null, true, showErrorToast);
                  }
                }
              }
            handleApiError(error, null, null, true, showErrorToast);
        }
    }, [showErrorToast]);
    useEffect(() => {
        allfunctions([getnational])
    }, [allfunctions]);
  return (
  <>
    {getnationaldoc.national_documents && getnationaldoc.national_documents.length > 0 ? (
       <Nationaldocumentlist
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            setIsLoading4={setIsLoading4}
            getnationaldocid={getnationaldoc.kyc_id}
            getnationaldoc={getnationaldoc}
            getnational={getnational}
       />
    ) : (
       <Nationaldocument
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            setIsLoading4={setIsLoading4}
            getnationaldocid={getnationaldoc.kyc_id}
            getnational={getnational}
       />
    )
    }
  </>
  );
})

export default WithToast(Nationalseperate)