import React,{useState,useEffect,memo} from 'react';
import WithToast from '../../../api/hook/withtoasthook';
import eye from '../../../images/eye_green-01.png';
import delet from '../../../images/cancel-02.png';
import Swal from 'sweetalert2';
import { publicDomain,deletegeneraldocumentapi } from '../../../api/helper/urlhelper';
import edit1 from '../../../images/edit_1-01.png';
import documentgreen from '../../../images/Document_green-01.png';
import Imageviewer from '../../../components/imageviewer';
import Generaldocumentmodal from './generaldocumentmodal';
import { handleApiError } from '../../../api/utils/utils';
import axiosInstance from '../../../api/utils/axiosutils';
const publicDomainurl = publicDomain();
const deletegeneraldocumentapiurl = deletegeneraldocumentapi();
const Generaldocumentlist = memo(({setActiveStep, activeStep,setIsLoading4,getgeneral,getgeneraldocid,getgeneraldoc,onAddNewClick,setShowAddNew,showErrorToast})=>{
    const [docvalues,setDocvalues] = useState([]);
    const [mapvalues,setMapvalues] = useState([]);
    const [show, setShow] = useState(false);
    useEffect(() => {
        setDocvalues(getgeneraldoc);
    }, [getgeneraldoc]);
    const [formValues, setFormValues] = useState({
        selectimage:[],
      });
      const [isPreviewVisible, setPreviewVisible] = useState(false);
      const generaldelete = async(doc) => {
          try{
          const result = await Swal.fire({
            title: 'Delete General Document?',
            text: 'Are you sure you want to delete this General Document',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          });
          if(result.isConfirmed){
            setIsLoading4(true);
            const response = await axiosInstance.get(`${deletegeneraldocumentapiurl}?kyc_general_document_id=${doc.id}`);
            if(response.status === 200){
                await getgeneral();
                setIsLoading4(false);
            }
          }
        }catch(error){
            handleApiError(error,setIsLoading4,null,true,showErrorToast);
        }
      }
      const imageview = (image) => {
        const front = image.includes(publicDomainurl) ? image : `${publicDomainurl}/${image}`;
        setFormValues((prev)=>({
          ...prev,selectimage: [front]
        }));
        setPreviewVisible(true);
      }
      const nationaledit = (doc) => {
         setMapvalues(doc)
         setShow(true);
      }
      const handleClose = () =>{
          setShow(false);
          setMapvalues([]);
      }
      const handleNext = () => {
          const nextStep = activeStep + 1;
          setActiveStep(nextStep);
          localStorage.setItem('activeStep', nextStep);
      }
      const handleBack = () => {
          const nextStep = activeStep - 1;
          setActiveStep(nextStep);
          localStorage.setItem('activeStep', nextStep);
      }
  return (
    <>
     <div className="wallet-profilepad" style={{ padding: '0px 40px 10px 55px' }}>
     {docvalues?.general_documents?.map((doc, index) => (
                    <div className="emer_box emer_box1" key={index}>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className=' wid70emer wid70emer1'>
                               <div>
                                <img src={documentgreen} alt='document' className='documentgreen' ></img>
                               </div>
                               <div>
                                <h6 className='emerp1'>Document Name</h6>
                                <p className=' emerh61 emerh6_bottom'>{doc.document_name}</p>
                               </div>
                            </div>
                            <div className='wid30emer'>
                                <div className='d-flex align-items-center' style={{ gap: '10px' }}>
                                    <div className='w-100'>
                                        <button className='emereditbutton' onClick={() => nationaledit(doc)}><img src={edit1} alt='edit' width='15px' height='15px' className='me-2'></img>Edit</button>
                                    </div>
                                    <div>
                                        <img src={eye} onClick={() => imageview(doc.front_img)} alt='eye' className='emereyeimage mb-2'></img>
                                        <img src={delet} onClick={() => generaldelete(doc)} alt='delete' className='emereyeimage'></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                 <div className='text-end'>
            <button className='addmewemer' onClick={onAddNewClick}>Add new</button>
        </div>
        <hr className="walletprofile-mar" />
        <div className="walletprofile-buttons mt-3">
          <button
            className={`walletprofile-next me-3  walletprofile-next-active`}
            onClick={handleNext}
          >
            Next
          </button>
          <button
            className="walletprofile-cancel"
            onClick={handleBack}
          >
            Cancel
          </button>
        </div>
     </div>
     <Generaldocumentmodal show={show} setShow={setShow} handleClose={handleClose} doc={mapvalues} setActiveStep={setActiveStep} activeStep={activeStep}  setIsLoading4={setIsLoading4}  getgeneraldocid={getgeneraldocid} setShowAddNew={setShowAddNew} getgeneral={getgeneral}/>
     {formValues.selectimage &&
          <Imageviewer isPreviewVisible={isPreviewVisible} selectedImage1={formValues.selectimage} onClose={() => {setPreviewVisible(false);}} isMultiImage={true} />
           }
    </>
  )
})
export default WithToast(Generaldocumentlist);