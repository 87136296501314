// import './App.css';

import { BrowserRouter as Router, Routes, Route , useLocation} from 'react-router-dom';
import { useEffect,useState } from 'react';
import Navbars from "./navbar";
import Transactions from "./transactions";
// import { UserProvider } from './api/usercontext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Addsources from './addsource';
import Dtransfer from './d-transfer';
import Ctransfer from './c-transfer';
import Qrtransaction from './qr-transaction';
import Walletemail from './wallet profile/walletemail';
import Walletgeneralid from './wallet profile/walletgeneralid';
import Walletnationaliid from './wallet profile/walletnationalid';
import Walletprofileshow from './wallet profile/walletprofile';
import Corporatesec from './wallet profile/corporatesec';
import Walletpassword from './wallet profile/wallerpassword';
// import ProfileSetup from './wallet profile/profilesetupgroups/profilesetup';
import Profilesetupcomponentchange from './wallet profile/profilesetupgroups/profilesetupcomponentchange';
import Addddsource from './addsources/addsource';
import Loader from './loader';
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    // makeAjaxCall();
  }, [pathname]);

  return null;
}
function App() {
  const [isLoading4,setIsLoading4] = useState(false);
  return (
    <>
       {isLoading4 && <Loader/>}  
      <ToastContainer/>
      <Router>
        <ScrollToTop/>
        <Routes>
          <Route path='/Wallet/D-Transfer' element={<Dtransfer/>}/>
          <Route path='/navbars'element={<Navbars/>}/>
          <Route exact path='/u/:user' element={<Transactions/>} />
          <Route path='/u/:user/addsource' element={<Addddsource setIsLoading4={setIsLoading4}/>}/>
          {/* <Route path='/Wallet/Addsources'element={<Addsources/>}/> */}
          <Route path='/Wallet/C-Transfer' element={<Ctransfer/>}/>
          <Route path='/Wallet/QR-Transactions'element={<Qrtransaction/>}/>
          {/* <Route path='/u/:user/wallet/profile-setup' element={<ProfileSetup/>}/> */}
          <Route path='/u/:user/wallet/profile-setups' element={<Profilesetupcomponentchange/>}/>
          {/* <Route path='/Wallet/Profilesidebar' element={<Profilesidebar/>}/> */}
          <Route path='/Wallet/profile' element={<Walletprofileshow/>}/>
          <Route path='/Wallet/Walletemail' element={<Walletemail/>}/>
          <Route path='/Wallet/Walletgeneral' element={<Walletgeneralid/>}/>
          <Route path='/Wallet/Walletnational' element={<Walletnationaliid/>}/>
          <Route path='/Wallet/corporate' element={<Corporatesec/>}/>
          <Route path='/Wallet/reset-password' element={<Walletpassword/>}/>

          {/* <Route path='/' */}
        </Routes>
      </Router>
    

    </>
  );
}


export default App;

