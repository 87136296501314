import React from "react";
import user from "../images/user-3.jpg";
import file from "../images/Document_green-01.png";
import eye from "../images/eye_green-01.png";
import cancel from "../images/cancel-02.png";
import copy from "../images/Icons (1)/Copy-01.png";
import share from "../images/Icons (1)/share-01.png";
import walletorange from "../images/Profile Setup/Wallet-orange-01.png";
import edit from '../images/Profile Setup/edit-01.png';
import call from '../images/Profile Setup/Call-01.png';
import message from '../images/Profile Setup/message-01.png';
import eyegreen from '../images/eye-02.png';
import editwhite from '../images/edit_1-01.png';
// import Profilesidebar from "./profilesidebar";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbars from "../navbar";
function Walletprofile() {
  const ref = useRef(null);
  return (
    <>
      <div
        className="walletprofileblue p-4 section"
        ref={ref}
        data-nav="Profile"
      >
        <div className="d-flex justify-content-end align-items-center">
          <img src={edit} width="19.5px" className="me-2" height="19.5px"></img>
          <p className="wallletprofileeditp mb-0">Edit</p>
        </div>
        <div className="d-flex align-items-center" >
          <div
            className="header-profile-user  user"
            style={{
              width: "70px",
              height: "70px",
              borderRadius: "50%",
              // overflow: "hidden",
              marginRight: "5px",
              border: "3px solid white",
              position:'relative'
            }}
          >
            <img
              id="login_img_for_profile"
              src={user}
              className="rounded-circle nav-user header-profile-user border-dark"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center center",
              }}
            />
            <img src={eyegreen} alt="green"
            style={{
              width: "24px",
              height: "auto",
              position: "absolute",
              top: "41px",
              /* left: 0px; */
              right: "-5px",
              objectFit: "cover",
            }}></img>
          </div>
          <p className="mb-0">Upload Your Picture</p>
        </div>
        <div className="mt-4">
          <div className="row">
            <div className="col-6">
              <label>Name</label>
              <div className="boxwidth">
                <p className="walletprofile-boxp">Stalin</p>
              </div>
            </div>
            <div className="col-6">
              <label>Country</label>
              <div className="boxwidth">
                <p className="walletprofile-boxp">Uganda</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2">
          <div className="row">
            <div className="col-6">
              <label>Gender</label>
              <div className="boxwidth">
                <p className="walletprofile-boxp">Male</p>
              </div>
            </div>
            <div className="col-6">
              <label>Date of birth</label>
              <div className="boxwidth">
                <p className="walletprofile-boxp">31.03.2002</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
// export default Walletprofile;

function Walletemail() {
  const ref = useRef(null);
  return (
    <>
      <div
        className="walletprofileblue mt-4 p-4 section"
        ref={ref}
        data-nav="Email & Phone"
      >
        <div className="d-flex justify-content-end align-items-center">
          <img src={edit} width="19.5px" className="me-2" height="19.5px"></img>
          <p className="wallletprofileeditp mb-0">Edit</p>
        </div>
        <div className="mt-4">
          <div className="row">
            <div className="col-6">
              <label>Email</label>
              <div className="boxwidth">
                <p className="walletprofile-boxp">Stalin@gmail.com</p>
              </div>
            </div>
            <div className="col-6">
              <label>Mobile Number</label>
              <div className="boxwidth">
                <p className="walletprofile-boxp">+256 7558196453</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Walletnational() {
  const ref = useRef(null);
  return (
    <>
      <div
        className="walletprofileblue mt-4 section p-4"
        ref={ref}
        data-nav="National ID Documents"
      >
        <div className="d-flex justify-content-end align-items-center">
          {/* <img src={edit} width='19.5px'className="me-2" height='19.5px'></img> */}
          <p className="wallletprofileeditp mb-0">+ Add Document</p>
        </div>
        <div className="docbox">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div className="me-3">
                <img src={file} alt="file" width="60px" height="auto" />
              </div>
              <div>
                <p className="docviewp truncated-text ">National ID</p>
                <p className="docviewp2 truncated-text ">National ID</p>
              </div>
            </div>
            {/* <div>
                            <p className="docviewp truncated-text">Document name</p>
                            <p className="docviewp2 truncated-text"></p>
                          </div>
                          <div>
                            <p className="docviewp truncated-text">Date of Issue</p>
                            <p className="docviewp2 truncated-text"></p>
                          </div>
                          <div>
                            <p className="docviewp truncated-text">Expire Date</p>
                            <p className="docviewp2 truncated-text"></p>
                          </div> */}
            <div className="d-flex align-items-center">
              <div className="me-3">
                <button className="docedit">
                  <img
                    className="me-3"
                    src={editwhite}
                    alt="edit"
                    width="18px"
                    height="auto"
                  />
                  Edit
                </button>
              </div>
              <div className="d-flex flex-column">
                <img className="mb-2 documenteyeview" src={eye} alt="eye" />
                <img
                  className="documenteyeview"
                  src={cancel}
                  alt="file"
                  width="29.89px"
                  height="auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function Walletgeneralid(){
    const ref = useRef(null);
    return(
        <> 
          <div
        className="walletprofileblue mt-4 mb-4 p-4 section"
        ref={ref}
        data-nav="General ID Documents"
      >
      <div className="d-flex justify-content-end align-items-center">
          {/* <img src={edit} width='19.5px'className="me-2" height='19.5px'></img> */}
          <p className="wallletprofileeditp mb-0">+ Add Document</p>
        </div>
        <div className="docbox">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div className="me-3">
                <img src={file} alt="file" width="60px" height="auto" />
              </div>
              <div>
                <p className="docviewp truncated-text ">National ID</p>
                <p className="docviewp2 truncated-text ">National ID</p>
              </div>
            </div>
            {/* <div>
                            <p className="docviewp truncated-text">Document name</p>
                            <p className="docviewp2 truncated-text"></p>
                          </div>
                          <div>
                            <p className="docviewp truncated-text">Date of Issue</p>
                            <p className="docviewp2 truncated-text"></p>
                          </div>
                          <div>
                            <p className="docviewp truncated-text">Expire Date</p>
                            <p className="docviewp2 truncated-text"></p>
                          </div> */}
            <div className="d-flex align-items-center">
              <div className="me-3">
                <button className="docedit">
                  <img
                    className="me-3"
                    src={editwhite}
                    alt="edit"
                    width="18px"
                    height="auto"
                  />
                  Edit
                </button>
              </div>
              <div className="d-flex flex-column">
                <img className="mb-2 documenteyeview" src={eye} alt="eye" />
                <img
                  className="documenteyeview"
                  src={cancel}
                  alt="file"
                  width="29.89px"
                  height="auto"
                />
              </div>
            </div>
          </div>
        </div>
        </div>
        </>
    );
}
function Walletrecovery() {
  const ref = useRef(null);
  return (
    <>
      <div
        className="walletprofileblue mt-4 mb-4 p-4 section"
        ref={ref}
        data-nav="Recovery Email & Mobile"
      >
        <div className="d-flex justify-content-end align-items-center">
          <img src={edit} width="19.5px" className="me-2" height="19.5px"></img>
          <p className="wallletprofileeditp mb-0">Edit</p>
        </div>
        <div className="mt-4">
          <div className="row">
            <div className="col-6">
              <label>Recovery Email</label>
              <div className="boxwidth">
                <p className="walletprofile-boxp">Stalin@gmail.com</p>
              </div>
            </div>
            <div className="col-6">
              <label>Recovery Mobile Number</label>
              <div className="boxwidth">
                <p className="walletprofile-boxp">+256 7558196453</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function Emergencycontacts(){
    const ref = useRef(null);
    return(
        <>
            <div
        className="walletprofileblue mt-4 mb-4 p-4 section"
        ref={ref}
        data-nav="Emergency contacts"
        style={{ marginBottom: "250px !important" }}
      >
      <div className="d-flex justify-content-end align-items-center">
          {/* <img src={edit} width='19.5px'className="me-2" height='19.5px'></img> */}
          <p className="wallletprofileeditp mb-0">+ Add Document</p>
        </div>
        <div className="docbox">
          <div className="d-flex justify-content-between ">
            <div className="d-flex ">
              <div className="me-3 align-items-baseline">
                <img src={user} alt="file" className="emergencyimage" width="60px" height="auto" />
              </div>
              <div>
                <p className="emergencyname">Emily </p>
                <p className="docviewp truncated-text "><img src={call} className="emergencycallimage" alt="call"></img>+256 782 521480</p>
                <p className="docviewp2 truncated-text"><img src={message} className="emergencycallimage" alt="call"/>emilycaty@gmail.com</p>
              </div>
            </div>
            {/* <div>
                            <p className="docviewp truncated-text">Document name</p>
                            <p className="docviewp2 truncated-text"></p>
                          </div>
                          <div>
                            <p className="docviewp truncated-text">Date of Issue</p>
                            <p className="docviewp2 truncated-text"></p>
                          </div>
                          <div>
                            <p className="docviewp truncated-text">Expire Date</p>
                            <p className="docviewp2 truncated-text"></p>
                          </div> */}
            <div className="d-flex align-items-center">
              <div className="me-3">
                <button className="docedit">
                  <img
                    className="me-3"
                    src={editwhite}
                    alt="edit"
                    width="18px"
                    height="auto"
                  />
                  Edit
                </button>
              </div>
              <div className="d-flex flex-column">
                <img className="mb-2 documenteyeview" src={eye} alt="eye" />
                <img
                  className="documenteyeview"
                  src={cancel}
                  alt="file"
                  width="29.89px"
                  height="auto"
                />
              </div>
            </div>
          </div>
        </div>
        </div>
        </>
    )
}
function Profilesidebar() {
    const [activeItem, setActiveItem] = useState("Profile");
    const navigate = useNavigate();  
    const handleItemClick = (navItem) => {
      const section = document.querySelector(`.section[data-nav="${navItem}"]`);

      if (section) {
          const topOffset = section.offsetTop - 110; // Adjust for fixed header
          window.scrollTo({ top: topOffset, behavior: "smooth" });
          setActiveItem(navItem); // Set active item directly
      }
    };
    
    useEffect(() => {
      const handleScroll = () => {
          const sections = document.querySelectorAll(".section");
          let closestSection = null;
          let minDistance = Infinity;

          sections.forEach(section => {
              const { top } = section.getBoundingClientRect();
              const distance = Math.abs(top - 110); // Adjusted for fixed header

              if (distance < minDistance) {
                  minDistance = distance;
                  closestSection = section.dataset.nav;
              }
          });

          if (closestSection && closestSection !== activeItem) {
              setActiveItem(closestSection); // Update if different
          }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
          window.removeEventListener("scroll", handleScroll);
      };
  }, [activeItem]);
    const resetpasscode = () => {
      navigate('/Wallet/reset-password')
    }
  return (
    <>
      <div className="wallersidebarfix">
      <div className="sharefriend-div w-100">
        <div className="d-flex align-items-center">
        <img src={walletorange} className="sharefriend-divimg2 me-2" alt="wallet"></img>
        <p className="sharefriend-divp">W-9656865694</p>
        </div>
        <div className="d-flex align-items-center">
        <img src={copy} alt="wallet" className="me-2 sharefriend-divimg"></img>
       <img src={share} alt="wallet" className="sharefriend-divimg"></img>
        </div>
      </div>
      <div className="walletprofileside100">
        <ul>
          <li
            className={activeItem === "Profile" ? "active" : ""}
            onClick={() => handleItemClick("Profile")}
          >
            Profile
          </li>
          <li
            className={activeItem === "Email & Phone" ? "active" : ""}
            onClick={() => handleItemClick("Email & Phone")}
          >
            Email & Phone
          </li>
          <li
            className={activeItem === "National ID Documents" ? "active" : ""}
            onClick={() => handleItemClick("National ID Documents")}
          >
            National ID Documents
          </li>
          <li
            className={activeItem === "General ID Documents" ? "active" : ""}
            onClick={() => handleItemClick("General ID Documents")}
          >
            General ID Documents
          </li>
          <li
            className={activeItem === "Recovery Email & Mobile" ? "active" : ""}
            onClick={() => handleItemClick("Recovery Email & Mobile")}
          >
            Recovery Email & Mobile
          </li>
          <li
            className={activeItem === "Emergency contacts" ? "active" : ""}
            onClick={() => handleItemClick("Emergency contacts")}
          >
            Emergency contacts
          </li>
        </ul>
      </div>
      <div className="sharefriend-divpass">
        <button className="resetpassbutton" onClick={resetpasscode}>Reset Passcode</button>
      </div>
      </div>
    </>
  );
}
function Walletprofileshow() {
  // const [activeItem, setActiveItem] = useState("Profile");
  return (
    <>
    <Navbars/>
      <div className="container-fluid wallet-profiletoprow">
        <div className="row">
          <div className="col-3">
            <Profilesidebar />
          </div>
          <div className="col-9">
            <Walletprofile />
            <Walletemail />
            <Walletnational />
            <Walletgeneralid/>
            <Walletrecovery />
            <Emergencycontacts/>
          </div>
        </div>
      </div>
    </>
  );
}
export default Walletprofileshow;
