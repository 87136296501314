import React, { memo,useState,useRef,useCallback,useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import WithToast from '../../../api/hook/withtoasthook';
import { disableFutureDates, handleKeyDown,CustomSuffixIcon, disablePastDates,handleApiError} from '../../../api/utils/utils';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import delet from '../../../images/times-circle-01.png';
import Imageviewer from '../../../components/imageviewer';
import { publicDomain, updatekycnationaldocumentapi } from '../../../api/helper/urlhelper';
import axiosInstance from '../../../api/utils/axiosutils';
import Fileuploader from '../../../components/fileuploader';
const publicDomainurl = publicDomain();
const updatekycnationaldocumentapiurl = updatekycnationaldocumentapi();
const Nationaleditmodal = memo(({show,showErrorToast,setShow,handleClose,doc,setIsLoading4,setActiveStep, activeStep,getnational,getnationaldocid})=> {
    const [error422,setError422] =useState('');
    const [isenabled,setIsenbled] = useState(false);
    const fileInputRef = useRef(null);
    const fileInputRef1 = useRef(null);
    const [fileSize, setFileSize] = useState('');
    const [format, setFormat] = useState('');
    const [docname,setDocname] = useState('');
    const [error,setError] = useState({
        frontimageerror:'',backimageerror:'',dateofissueerror:''
      })
    const [editImage,setEditImage,] = useState('');
    const [editformat, setEditFormat] = useState('');
    const [editimagedetails,setEditImageDetails] = useState(false);
    const [editProgress, setEditProgress] = useState(0);
    const [editProgressColor, setEditProgressColor] = useState('#28a745');
    const [editDocName, setEditDocName] = useState('');
    const [editFileSize, setEditFileSize] = useState('');
    const [formValues, setFormValues] = useState({
        firstName: '',
        otherNames: '',
        nationalId: '',
        dateOfIssue: '',
        expiryDate: '',
        selectimage:[],
        kyc_id:'',
        id:''
      });
      const [isPreviewVisible, setPreviewVisible] = useState(false);
      const [uploading, setUploading] = useState(false);
      const [progress, setProgress] = useState(0);
      const [progressColor, setProgressColor] = useState("#28a745");
      const [image, setImage] = useState('');
      useEffect(() => {    
        if (doc) {     
            setFormValues((prev)=>({
                ...prev,firstName:doc.first_name || '',
                otherNames:doc.other_name || '',
                nationalId:doc.national_id_number || '',
                dateOfIssue:doc.issue_date || '',
                expiryDate:doc.expiry_date || '',
                kyc_id:doc.kyc_id,
                id:doc.id
            }));
            if(doc.front_img){
              const front = doc.front_img.includes(publicDomainurl) ? doc.front_img : `${publicDomainurl}/${doc.front_img}`
              setImage(front);
            } else{
              setImage('');
            }
            if(doc.back_img){
              const back = doc.back_img.includes(publicDomainurl) ? doc.back_img : `${publicDomainurl}/${doc.back_img}`;
              setEditImage(back);
            }
            setProgress(doc.front_img ? 100 : 0);
            setUploading(doc.front_img ? true : false);
            setEditProgress(doc.back_img ? 100 : 0);
            setEditImageDetails(doc.back_img ? true : false);
        }
    }, [doc]); 
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      };
      const handleFrontview = (image) => {
         setFormValues((prev)=>({
          ...prev,selectimage:[image]
         }));
         setShow(false);
         setPreviewVisible(true);
      }
      const handleBackview = (image) => {
        setFormValues((prev)=>({
         ...prev,selectimage:[image]
        }));
        setShow(false);
        setPreviewVisible(true);
     }
     const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (open) => {
    setIsOpen(open);
  }; 
  const handleExpiryClick = (e) => {
    if (!formValues.dateOfIssue) {
      setError((prevState) => ({
        ...prevState,
        dateofissueerror:'Please select Date of Issue first'
      }));
      setIsOpen(false);
    } else {
      setError((prevState) => ({
        ...prevState,
        dateofissueerror:''
      }));
      setIsOpen(true);
    }
  };
  const handleClose1 = () =>{
    handleClose();
    setError((prev) => ({
      ...prev,
      frontimageerror:'',backimageerror:'',dateofissueerror:''
    }));
    setError422('');
}  
  const handleBack = () => {
      handleClose1();
  }
  const handleNext = async() => {
    const data = {
        id:formValues.id,
        kyc_id : formValues.kyc_id,
        first_name : formValues.firstName,
        other_name : formValues.otherNames,
        national_id_number : formValues.nationalId,
        front_img : image.toString(),
        back_img:editImage.toString(),
        issue_date : formValues.dateOfIssue,
        expiry_date : formValues.expiryDate
     }
     try{
       setIsLoading4(true);
       const response = await axiosInstance.post(updatekycnationaldocumentapiurl,data);
       if(response.status === 200){
          await getnational();
          handleClose1();
          const nextStep = activeStep;
           setActiveStep(nextStep);
           // setActiveStep((prevActiveStep) => prevActiveStep + 1);
           localStorage.setItem('activeStep', nextStep);
           setError422('');
           setIsLoading4(false);
       }
     }catch(error){
       handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
     }
  }  
  const check = useCallback(()=>{
    const valid = (
      formValues.firstName !== '' && formValues.otherNames !== '' && formValues.nationalId !== '' && formValues.dateOfIssue !== '' && formValues.expiryDate !== '' && image.length > 0 && editImage.length > 0 
    )
    setIsenbled(valid);
  },[formValues.firstName,formValues.otherNames,formValues.nationalId,formValues.dateOfIssue,formValues.expiryDate,image,editImage]);
  useEffect(()=>{
    check();
  },[check]);
  return (
    <>
      <Modal show={show} size='lg' onHide={handleClose1}>
        <Modal.Body style={{ margin: '0', padding: '0' }}>
           <div className="wallet-profilepad2">
           <div className='text-end modal_delete'>
        <img src={delet} onClick={handleClose1} style={{cursor:'pointer'}} alt='delete' width='30px' height='auto'></img>
       </div>
        <div className='row'>
            <div className='col-6'>
            <label className="walletprofile-label">First Name<span className="docpopstar">*</span></label>
             <input type="text" value={formValues.firstName} onChange={handleInputChange} name='firstName' className={`form-control mt-1 walletprofile-input ${formValues.firstName ? 'walletprofile-input-active' : ''}`} placeholder="Type as it appears on ID" /> 
            </div>
            <div className='col-6'>
            <label className="walletprofile-label">Other Name(S)<span className="docpopstar">*</span></label>
            <input type="text" name='otherNames' value={formValues.otherNames} onChange={handleInputChange} className={`form-control mt-1 walletprofile-input ${formValues.otherNames ? 'walletprofile-input-active' : ''}`} placeholder="Enter other name" /> 
            </div>
        </div>
        <div className='mt-3'>
        <label className="walletprofile-label">National ID number<span className="docpopstar">*</span></label>
        <input type="text" name='nationalId' value={formValues.nationalId} onChange={handleInputChange} className={`form-control mt-1 walletprofile-input ${formValues.nationalId ? 'walletprofile-input-active' : ''}`} placeholder="Enter national ID number" /> 
        </div>
        <div className='mt-3'>
        <div className='row'>  
        <div className='col-6'>      
        <label className="walletprofile-label">Date Of Issue<span className="docpopstar">*</span></label>
        <DatePicker onKeyDown={handleKeyDown} format="DD-MM-YYYY" className='mt-1 custom-pick' disabledDate={disableFutureDates} value={formValues.dateOfIssue ? dayjs(formValues.dateOfIssue) : null} onChange={(date) => { setFormValues((prevValues) => ({ ...prevValues, dateOfIssue: date ? dayjs(date).format('YYYY-MM-DD') : '',expiryDate:'' }));setError((prev)=>({...prev,dateofissueerror:''}))}} placeholder='Select your DOB' getPopupContainer={(trigger) => trigger.parentNode} style={{ width: '100%', height: '45px', borderRadius: '6px', border: formValues.dateOfIssue ? '1px solid black' : '1px solid #DCDCDC', color: formValues.dateOfIssue ? 'black' : '' }} suffixIcon={<CustomSuffixIcon isActive={formValues.dateOfIssue} />} />
        {error.dateofissueerror && <p className="error-message">{error.dateofissueerror}</p>}
        </div>
        <div className='col-6'>      
        <label className="walletprofile-label">Expiry Date<span className="docpopstar">*</span></label>
        <DatePicker onKeyDown={handleKeyDown} format="DD-MM-YYYY" onClick={handleExpiryClick} open={isOpen} onOpenChange={handleOpenChange} className='mt-1 custom-pick' disabledDate={disablePastDates(formValues.dateOfIssue)} value={formValues.expiryDate ? dayjs(formValues.expiryDate) : null} onChange={(date) => { setFormValues((prevValues) => ({ ...prevValues, expiryDate: date ? dayjs(date).format('YYYY-MM-DD') : '', }));setError422('')}} getPopupContainer={(trigger) => trigger.parentNode} placeholder='Select your DOB' style={{ width: '100%', height: '45px', borderRadius: '6px', border: formValues.expiryDate ? '1px solid black' : '1px solid #DCDCDC', color: formValues.expiryDate ? 'black' : '' }} suffixIcon={<CustomSuffixIcon isActive={formValues.expiryDate} />} />
        </div>
        </div>
        </div>
        <div className='mt-3'>
            <div className='row'>
           <div className='col-6'>
        <label className="walletprofile-label">Front<span className="docpopstar">*</span></label>
        <Fileuploader handleFrontview={handleFrontview} docname={docname} acceptPDF={false} fileInputRef={fileInputRef} setImage={setImage} format={format} fileSize={fileSize} image={image} progress={progress} setProgress={setProgress} progressColor={progressColor} setProgressColor = {setProgressColor} setFileSize={setFileSize} uploading={uploading} setUploading={setUploading} setFormat={setFormat} setDocname={setDocname} setError422={setError422} setImageerror={(message) =>setError((prev) => ({...prev,frontimageerror: message}))} Acceptformat={['jpg','jpeg','png']} National={false}/>
              {error.frontimageerror && <p className="error-message">{error.frontimageerror}</p>}
            </div> 
           <div className='col-6'>
        <label className="walletprofile-label">Back<span className="docpopstar">*</span></label>
        <Fileuploader handleFrontview={handleBackview} docname={editDocName} acceptPDF={false} fileInputRef={fileInputRef1} setImage={setEditImage} format={editformat} fileSize={editFileSize} image={editImage} progress={editProgress} setProgress={setEditProgress} progressColor={editProgressColor} setProgressColor = {setEditProgressColor} setFileSize={setEditFileSize} uploading={editimagedetails} setUploading={setEditImageDetails} setFormat={setEditFormat} setDocname={setEditDocName} setError422={setError422} setImageerror={(message) =>setError((prev) => ({...prev,frontimageerror: message}))} Acceptformat={['jpg','jpeg','png']} National={false}/>
              {error.backimageerror && <p className="error-message">{error.backimageerror}</p>}
              </div>
              </div> 
        </div>
        {error422 && <p className="error-message">{error422}</p>}
        <div className="walletprofile-buttons mt-3">
          <button
            className={`walletprofile-next me-3 ${isenabled ? 'walletprofile-next-active' : 'walletprofile-next-disabled'}`}
            disabled={!isenabled}
            onClick={handleNext}
          >
            Save
          </button>
          <button
            className="walletprofile-cancel"
            // disabled={activeStep === 0}
            onClick={handleBack}
          >
            Cancel
          </button>
        </div>
    </div>
        </Modal.Body> 
      </Modal>
      {formValues.selectimage &&
    <Imageviewer isPreviewVisible={isPreviewVisible} selectedImage1={formValues.selectimage} onClose={() => {setPreviewVisible(false);setShow(true)}} isMultiImage={false}/>
    }
    </>
  )
})

export default WithToast(Nationaleditmodal);