import React from 'react';
import PropTypes from 'prop-types';
import eye2 from '../images/eye-02.png';
import tick from "../images/user-circle.png";
const ProfileImage = ({
  containerClass = '', 
  imageSrc = '', 
  onClick = () => {}, 
  imgClass = '', 
  altText = 'Profile Image', 
  imageview = () => {}, 
  viewimage = '', 
  showeye = false,
  eyeclassname = ''
}) => {
  return (
    <div className={containerClass} onClick={onClick} >
      <img id="profile_image" src={imageSrc || tick} className={imgClass} alt={altText}/>
      {showeye && (
        <img src={eye2} onClick={(e) => {e.stopPropagation(); imageview(viewimage);}} className={eyeclassname} alt="View"/>
      )}
    </div>
  );
};

ProfileImage.propTypes = {
  containerClass: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  imgClass: PropTypes.string,
  altText: PropTypes.string,
  imageview: PropTypes.func,
  viewimage: PropTypes.string,
  showeye: PropTypes.bool,
};

export default ProfileImage;
