import React, { memo, useState, useCallback, useEffect,useRef } from 'react';
import { Modal } from 'react-bootstrap';
import closeicon from '../../images/times-circle-01.png';
import useMenuHeight, {handleApiError, handleKeyPress } from '../../api/commonfunct.js/common';
import axiosInstance from '../../api/commonfunct.js/axiosinstance';
import Select, { components } from 'react-select';
import { mcclistapi, updatebankapi } from '../../api/url';
import { useIpContext } from '../../api/commonfunct.js/ipContext';
const mcclistapiurl = mcclistapi();
const updatebankapiurl = updatebankapi();
const Editaccountdetailmodal = memo(({show,accountdetails,setMapvalues,setAccountdetails,handleClose,getbanksall, setIsLoading4, showErrorToast}) => {
    const [error422,setError422] = useState('');
    const [enabled,setEnabled] = useState(false);
    const { ipCountryCode, getUserIpCountryCode } = useIpContext();
    const mobileInputRef = useRef(null);
    const updateExampleNumber = useCallback((iso2) => {
        if (window.intlTelInputUtils) {
          try {
            const exampleNumber = window.intlTelInputUtils.getExampleNumber(
              iso2,
              true,
              window.intlTelInputUtils.numberFormat.E164
            );
            const cleanedNumber = exampleNumber.replace(/\D/g, '').replace(/^0+/, '');
            const maxLength = cleanedNumber.length;
            return maxLength;
          } catch (error) {
            console.error('Error fetching example number for country code:', iso2, error);
            return 15; // Fallback to a default length
          }
        } else {
          console.error('intlTelInputUtils is not loaded');
          return 15; // Fallback to a default length
        }
      }, []);
    const fetchCountryCode = useCallback(async()=>{
        if(accountdetails.country_code || ipCountryCode){
        if(accountdetails.countryOptions.length > 0) return;    
        setAccountdetails((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
        
        try {
          const response = await axiosInstance.get(mcclistapiurl);
          const data = response.data;
      
          if (data.message === 'Success') {
            const mccList = data.data;
            const codes = mccList.map((mcc) => ({
              country_code: mcc.country_code,
              dial_code: mcc.code,
              mcc_id: mcc.id,
              label: `${mcc.name} (+${mcc.code})`,
              value: mcc.country_code,
              flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
            }));
      
            setAccountdetails((prevState) => ({
              ...prevState,
              countryOptions: codes,
            }));
            let code = accountdetails.country_code || ipCountryCode;
            const selectedCountryData = codes.find(
              (country) => country.country_code === code
            );
      
            if (selectedCountryData) {
              setAccountdetails((prevState) => ({
                ...prevState,
                mcc_id: selectedCountryData.mcc_id,
                dial_code: `+${selectedCountryData.dial_code}`,
                country_code: selectedCountryData.country_code,
              }));
      
              const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
              if (maxLength !== null && mobileInputRef.current) {
                mobileInputRef.current.setAttribute('maxlength', maxLength);
              }
            }
          } else {
            console.error('Failed to fetch country codes');
          }
        } catch (error) {
          handleApiError(error, null, null, true, showErrorToast);
        } finally {
          setAccountdetails((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
        }
      }
      },[accountdetails.country_code,showErrorToast,updateExampleNumber,ipCountryCode,setAccountdetails]);
        const ip = useCallback(async() =>{
            if(accountdetails.country_code){
                fetchCountryCode(); 
            }else{
                if(!ipCountryCode){
                    await getUserIpCountryCode()
                    fetchCountryCode();
                }else{
                    fetchCountryCode();
                }
            } 
        },[fetchCountryCode,accountdetails.country_code,ipCountryCode,getUserIpCountryCode]);        
        useEffect(()=>{
          if(show){  
          ip();
          }
        },[ip,show]);
        const handleKeyPress1 = (e) => {
          const char = String.fromCharCode(e.which);
          if (!/^[a-zA-Z ]$/.test(char)) {
            e.preventDefault();
          }
        };
        const handleCountryChange = (selectedOption) => {
            const selectedCountryData = accountdetails.countryOptions.find(
              country => country.value === selectedOption.value
            );
          
            if (selectedCountryData) {
              setAccountdetails(prevState => ({
                ...prevState,
                country_code: selectedCountryData.country_code,
                dial_code: `+${selectedCountryData.dial_code}`,
                mcc_id: selectedCountryData.mcc_id
              }));
          
              // Fetch maximum length based on selected country
              const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
              if (maxLength !== null && mobileInputRef.current) {
                mobileInputRef.current.setAttribute('maxlength', maxLength);
              }
          
              // Handle phone number truncation if necessary
              setAccountdetails(prevState => ({
                ...prevState,
                phonenumber: prevState.phonenumber.slice(0, maxLength)
              }));
            }
            setError422('');
          };
          const handlePaste = (e) => {
            setError422('');
            const pastedText = e.clipboardData.getData('text');
            let numericPastedText = pastedText.replace(/[^0-9]/g, '');
        
            // Define the dial code without the '+'
            const dialCodeWithoutPlus = accountdetails.dial_code.replace('+', '');
        
            // Remove dial code if the pasted text starts with '+'
            if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
                numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
            }
        
            // Remove leading '0' if present
            if (numericPastedText.startsWith('0')) {
                numericPastedText = numericPastedText.slice(1);
            }
        
            // Calculate the max length and truncate the number if necessary
            const maxLength = mobileInputRef.current.getAttribute('maxlength');
            const truncatedPastedText = numericPastedText.slice(0, maxLength);
            setAccountdetails(prevState => ({
              ...prevState,
              phonenumber: truncatedPastedText
            }));
          }
          const handlePhoneNumberChange = (value) => {
            if (value.startsWith('0')) {
              value = value.slice(1);
            }
            const maxLength = mobileInputRef.current.getAttribute('maxlength');
            if (value.length > maxLength) {
              value = value.slice(0, maxLength);
            }
            setAccountdetails(prevState => ({
              ...prevState,
              phonenumber: value
            }));
            setError422('');
        };
          const customSingleValue = ({ data }) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
                src={data.flag}
                alt=""
                style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
            />
            </div>
          );
          const customOption = (props) => (
            <components.Option {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                src={props.data.flag}
                alt=""
                style={{ width: '25px', height: 'auto', marginRight: '10px' }}
                />
                {props.data.label}
            </div>
            </components.Option>
          );   
    const handleChange = (e) => {
        const { name, value } = e.target;
        setAccountdetails((prevValues) => ({
        ...prevValues,
        [name]: value,
        }));
        setError422('');
    };
    const resetvalues = () => {
        setMapvalues([]);
    }
    const handleClose1 = () =>{
        handleClose();
        resetvalues();
        setError422('');
    }
    const check = useCallback(()=>{
     const valid = (
        accountdetails.accountholdername !== '' && accountdetails.accountholdernumber !== '' && accountdetails.phonenumber !== ''
     )
     setEnabled(valid);
    },[accountdetails.accountholdername,accountdetails.accountholdernumber,accountdetails.phonenumber]);
    useEffect(()=>{
        check();
    },[check]);
    const save = async() => {
        const data = {
            bank_account_id:accountdetails.id,
            country_id : accountdetails.country,
            city_id : accountdetails.city,
            branch_id : accountdetails.branch,
            bank_id : accountdetails.bank,
            account_holdername : accountdetails.accountholdername,
            account_number : accountdetails.accountholdernumber,
            phone_number : accountdetails.phonenumber,
            mcc_id:accountdetails.mcc_id
        }
      try{
        setIsLoading4(true);
        const response = await axiosInstance.post(updatebankapiurl,data);
        if(response.status === 200){
           await getbanksall();
           handleClose1();
           setIsLoading4(false);
        }
      }catch(error){
         handleApiError(error, setIsLoading4, setError422, false, showErrorToast)
      }
    }
    const calculateWidth = (label) => {
        const tempDiv = document.createElement('p');
        tempDiv.style.position = 'absolute';
        tempDiv.style.visibility = 'hidden';
        tempDiv.style.fontSize = '14px';
        tempDiv.style.fontWeight = '400';
        tempDiv.style.whiteSpace = 'nowrap';
        tempDiv.style.margin = '0';
        document.body.appendChild(tempDiv);
    
        tempDiv.innerText = label;
        const newWidth = tempDiv.offsetWidth;
        document.body.removeChild(tempDiv);
    
        return newWidth;
      };
    
      const [selwidth, setSelwidth] = useState(0);
    
      const selectWidth = useCallback(() => {
        const label = accountdetails.dial_code || '';
        const newWidth = Math.round(calculateWidth(label));
        setSelwidth(newWidth + 82);
      }, [accountdetails.dial_code]);
    
      useEffect(() => {
        selectWidth();
      }, [selectWidth]);
    
      useEffect(() => {
        const handleResize = () => {
          selectWidth();
        };
        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [selectWidth]);
    
      const menuHeight = useMenuHeight(200, 400);
  return (
    <>
    <Modal show={show} dialogClassName="card-example-dialog" contentClassName="card-example-content" onHide={handleClose1} centered>
        <Modal.Body className="card-example-dialog-body">
          <div className="text-end">
            <img src={closeicon} onClick={handleClose1} style={{ cursor: 'pointer' }} className="closeicon mx-1 mt-2" alt="closeicon"></img>
          </div>
          <div className="card-example-dialog-bodypadd">
            <h5 className="addnewh5">Add Account Details</h5>
            <div className='mt-3'>
            <label htmlFor="Accountholdernumber" className="cardlabel">Account Number<span className="docpopstar">*</span></label>
            <input type="text" id="Accountholdernumber" maxLength={16} onKeyPress={handleKeyPress} placeholder='Enter Account Number'  className={`form-control cardinputbox ${accountdetails.accountholdernumber ? 'cardinputbox_active' : ''}`} value={accountdetails.accountholdernumber} onChange={handleChange} name="accountholdernumber"  />
         </div>
         <div className='mt-2'>
            <label htmlFor="Accountholdername" className="cardlabel">Account Holder<span className="docpopstar">*</span></label>
            <input type="text" id="Accountholdername" placeholder='Enter Account Holder' onKeyPress={handleKeyPress1} className={`form-control cardinputbox ${accountdetails.accountholdername ? 'cardinputbox_active' : ''}`} value={accountdetails.accountholdername} onChange={handleChange} name="accountholdername"  />
         </div>
         <div className='mt-2'>
            <label htmlFor="Accountholderphonenumber" className="cardlabel">Phone Number<span className="docpopstar">*</span></label>
            <div className='position-relative'>
            <div style={{ position: 'absolute', left: 0, zIndex: 1 }}>
              <Select
                options={accountdetails.countryOptions || []}
                onChange={handleCountryChange}
                value={accountdetails.countryOptions.find(option => option.value === accountdetails.country_code)}
                components={{ SingleValue: customSingleValue, Option: customOption }}
                placeholder=""
                isLoading={accountdetails.isLoading}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width:  '80px',
                    height: '40px',
                    border: 'none',
                    boxShadow: 'none',
                    background: 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    padding: '0 8px',
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    display: 'flex',
                    alignItems: 'center',
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: '0',
                    display: 'flex',
                    alignItems: 'center',
                  }),
                  input: (provided) => ({
                    ...provided,
                    margin: '0',
                    padding: '0',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    margin: '0',
                    padding: '0',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    minWidth: '300px',
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    padding: 0,
                    height: menuHeight,
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
            <div style={{ height: '30px', width: '2px', backgroundColor: '#ccc', margin: '0 8px', position: 'absolute', left: `${selwidth}px`, top: '50%', transform: 'translateY(-50%)' }}></div>
            <p style={{ position: 'absolute', left: '80px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer', color: 'hsl(0, 0%, 20%)', fontSize: '14px', lineHeight: '1.5', marginBottom: '0px', fontWeight: '400' }}>{accountdetails.dial_code}</p>
            <input type="text" id="Accountholderphonenumber" onPaste={(e) => handlePaste(e)} onKeyPress={handleKeyPress} onChange={(e) => handlePhoneNumberChange(e.target.value)} ref={mobileInputRef}  placeholder='Enter Phone Number'  className={`form-control cardinputbox ${accountdetails.phonenumber ? 'cardinputbox_active' : ''}`} value={accountdetails.phonenumber} style={{ paddingLeft: `${selwidth + 18}px` }}  name="phonenumber"  />
            </div>
         </div>
         {error422 && <p className="error-message">{error422}</p>}
        <div className='mt-4'>
          <button className={`modal_save_card ${enabled ? 'modal_save_card_active' : 'modal_save_card_disabled'}`} disabled={!enabled} onClick={save}>Save</button>
        </div>
          </div>  
        </Modal.Body>
        </Modal>  
    </>
  )
});
export default Editaccountdetailmodal;