import React, { useEffect } from "react";
import card from "../images/registered-services/card-black-01.svg";
import cardwhite from "../images/registered-services/card-white-01.svg";
import bank from "../images/registered-services/bank-black-01.svg";
import bankwhite from "../images/registered-services/bank-white-01.svg";
import mobilemoney from "../images/registered-services/mobile-pay-black-01.svg";
import mobilemoneywhite from "../images/registered-services/mobile-pay-white-01.svg";
import organization from "../images/registered-services/organization-black-01.svg";
import organizationwhite from "../images/registered-services/organization-white-01.svg";
import "../transactions.css";

function Registerservices({ setSource, source }) {
  const services = [
    {
      key: "cards",
      name: "Cards",
      activeIcon: cardwhite,
      inactiveIcon: card,
    },
    {
      key: "mobilemoney",
      name: "Mobile Money",
      activeIcon: mobilemoneywhite,
      inactiveIcon: mobilemoney,
    },
    {
      key: "bank",
      name: "Bank",
      activeIcon: bankwhite,
      inactiveIcon: bank,
    },
    {
      key: "organization",
      name: "Organization/Company",
      activeIcon: organizationwhite,
      inactiveIcon: organization,
    },
  ];

  const handleSource = (sourceValue) => {
    setSource(sourceValue);
    localStorage.setItem("activeSection", sourceValue);
  };

  useEffect(() => {
    const storedActiveSection = localStorage.getItem("activeSection");
    if (storedActiveSection) {
      setSource(storedActiveSection);
    }
  }, [setSource]);

  return (
    <div className="aside">
      <aside>
        <div className="pt-4 ps-4 registerservicediv">
          <h6 className="registerserviceh61">Registered Services</h6>
        </div>
        <hr className="registerhr"/>
        <div className="registerul mt-3">
          <ul>
            {services.map((service) => (
              <li key={service.key} onClick={() => handleSource(service.key)} className={`${source === service.key ? "registerserviceliactive activesystem" : "registerserviceliinactive"}`}>
                <img src={source === service.key ? service.activeIcon : service.inactiveIcon} alt={`${service.name} Icon`}className="registerulimg"/>
                <p className={`mb-1 ${source === service.key ? "registerservicepactive" : "registerservicepinactive"}`}>{service.name}</p>
              </li>
            ))}
          </ul>
        </div>
      </aside>
    </div>
  );
}

export default Registerservices;
