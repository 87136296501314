import React,{useEffect, useState,useCallback} from 'react';
import WithToast from '../../api/hook/withtoasthook';
import call from '../../images/Profile Setup/Call-01.png';
import message from '../../images/Profile Setup/message-01.png';
import eye from '../../images/eye_green-01.png';
import delet from '../../images/cancel-02.png';
import Swal from 'sweetalert2';
import edit1 from '../../images/edit_1-01.png';
import { getkycpasscodeapi, publicDomain, updatekycemergencycontacsapi, userupdatekycapi,getemergencyrelationlistapi, deletekycemergencycontactapi } from '../../api/helper/urlhelper';
import Imageviewer from '../../components/imageviewer';
import Emergencyeditmodal from './emergencyeditmodal';
import axiosInstance from '../../api/utils/axiosutils';
import { handleApiError, useRouteParams } from '../../api/utils/utils';
import Passcodemodal from './passcodemodal';
import tick from "../../images/user-circle.png";
import { useNavigate } from 'react-router-dom';
import ProfileImage from '../../components/profileimage';
const publicDomainurl = publicDomain();
const updateemergencycontactsapiurl = updatekycemergencycontacsapi();
const getemergencyrelationlistapiurl = getemergencyrelationlistapi();
const getkycpasscodeapiurl = getkycpasscodeapi();
const userupdatekycapiurl = userupdatekycapi();
const deletekycemergencycontactapiurl = deletekycemergencycontactapi();
const Emergencylist = (({setActiveStep, activeStep,showErrorToast,onAddNewClick,getemergencydoc,getemergency,setIsLoading4,setShowAddNew,getemergencydocid})=>{
    const [error422,setError422] = useState('');
    const params = useRouteParams();
    const [docvalues,setDocvalues] = useState([]);
    const navigate = useNavigate();
    const [showPasscodeModal, setShowPasscodeModal] = useState(false);
    const [show, setShow] = useState(false);
    const [isPreviewVisible, setPreviewVisible] = useState(false);
    const [formValues,setFormValues] = useState({
      selectimage:[],kyc_id :'',relationoption:[]
    })
    const [mapvalues,setMapvalues] = useState([]);
    useEffect(() => {
        setDocvalues(getemergencydoc);
        setFormValues((prev)=>({
          ...prev,kyc_id : getemergencydocid
        }))
    }, [getemergencydoc,getemergencydocid]);;
    const getrelationlist = useCallback(async()=>{
      try{
         const response = await axiosInstance.get(getemergencyrelationlistapiurl);
         if(response.status === 200){
            const relationlist = response.data.data;
            setFormValues((prev)=>({
             ...prev,relationoption:relationlist.map(relat => ({value:relat.id,label:relat.name}))
            }))              
         }
      }catch(error){
       handleApiError(error, null, null, true, showErrorToast);
      }
   },[showErrorToast]);
   useEffect(()=>{
    getrelationlist();
   },[getrelationlist]);
    const imageview = (image) => {
      if(image){
      const profile = `${publicDomainurl}/${image}`
      setFormValues((prev)=>({
         ...prev,selectimage:[profile]
      }));
      }else{
        setFormValues((prev)=>({
          ...prev,selectimage:[tick]
       }));
      }
      setPreviewVisible(true);
   }
   const handleClosePasscodeModal = () => {
    setShowPasscodeModal(false);
     userupdatekyc();
    // passcode();
   };
   const [contactErrors, setContactErrors] = useState(null);
   const [contactErrors1, setContactErrors1] = useState(null);
   const userupdatekyc = async () => {
    const data = {
        kyc_id: formValues.kyc_id
    };

    try {
        const response = await axiosInstance.post(userupdatekycapiurl, data);
        if (response.status === 200) {
            navigate(`/u/${params}`);
        }
    } catch (error) {
        if (error.response) {
            const { status, data } = error.response;

            if (status === 422) {
                const sa = [
                    { type: 'kyc_profile', index: 0 },
                    { type: 'kyc_terms_conditions', index: 1 },
                    { type: 'kyc_email_mobile', index: 2 },
                    { type: 'kyc_recovery_email_mobile', index: 5 },
                    { type: 'kyc_emergency_contact', index: 6 }
                ];

                const matchingEntry = sa.find(entry => entry.type === data.error_code);
                if (matchingEntry) {
                    const nextStep = matchingEntry.index;
                    setActiveStep(nextStep);
                    localStorage.setItem('activeStep', nextStep);
                } else {
                    handleApiError(error, null, null, true, showErrorToast);
                }
            } else {
                handleApiError(error, null, null, true, showErrorToast);
            }
        } else {
            handleApiError(error, null, null, true, showErrorToast);
        }
    }
   };
   const passcode = async()=>{
    try{
     const response = await axiosInstance.get(getkycpasscodeapiurl);
     if(response.status === 200){
      const pass = response.data.data
      if((Array.isArray(pass) && pass.length === 0)){
         setShowPasscodeModal(true);
      }else{
          userupdatekyc();
        // const nextStep = activeStep;
        // setActiveStep(nextStep);
        // localStorage.setItem('activeStep', nextStep);
      }
     }
    }catch(error){
      handleApiError(error, null, null, true, showErrorToast);
    }
   }
    const handleNext = async() => {
      const coreEmergencyIds = docvalues?.emergency_contacts
      .filter(contact => contact.kyc_emergency_id === null)
      .map(contact => contact.core_emergency_id);
      const data = {
        core_emergency_contacts_id : coreEmergencyIds,
        kyc_id : formValues.kyc_id
      }
      try{
        setIsLoading4(true);
        const response = await axiosInstance.post(updateemergencycontactsapiurl,data);
        if(response.status === 200){
           await passcode();
           setIsLoading4(false);
        }
      }catch(error){
        if (error.response && error.response.status === 422) {
          const errorMessage = error.response.data.mobile_error;
          setContactErrors1(errorMessage.split(/[0.]+/));
          const lastSegment = errorMessage[errorMessage.length - 2];
          // const match = errorMessage.match(/The email field is required in emergency contact (\d+)/);
          if (lastSegment) {
            const lsad = docvalues?.emergency_contacts
            .filter(contact => contact.kyc_emergency_id === null)
            .map(contact => contact.core_emergency_id);
            const sadIndex = lsad[lastSegment];
            setContactErrors(sadIndex);
            setIsLoading4(false);
          }
        } else {

          handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
        }
      }
    }
    // const 
    const handleBack = () => {
      const nextStep = activeStep - 1;
        setActiveStep(nextStep);
        localStorage.setItem('activeStep', nextStep);
    }
    const emergencyedit = (doc) => {
      setMapvalues(doc);
      setShow(true);
      setContactErrors('');
      setContactErrors1('');
    }
    const [deleteid,setDeleteid] = useState([]);
    const emergencydelete = async(doc) => {
        if(doc.kyc_emergency_id === null){
          setDeleteid((prevIds) => prevIds.includes(doc.core_emergency_id) ? prevIds : [...prevIds, doc.core_emergency_id]);
          return;
        }
        try{
        const result = await Swal.fire({
          title: 'Delete Emergency Contact?',
          text: 'Are you sure you want to delete this Emergency Contact',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        });
        if(result.isConfirmed){
          setIsLoading4(true);
          const response = await axiosInstance.get(`${deletekycemergencycontactapiurl}?kyc_emergency_contact_id=${doc.kyc_emergency_id}`);
          if(response.status === 200){
              await getemergency();
              setIsLoading4(false);
          }
        }
      }catch(error){
          handleApiError(error,setIsLoading4,null,true,showErrorToast);
      }
    }
    const handleClose = () =>{
      setShow(false);
      setMapvalues([]);
    }
    const allContactsDeleted = docvalues?.emergency_contacts?.every(
      (doc) =>
        doc.kyc_emergency_id === null &&
        deleteid.includes(doc.core_emergency_id)
    );
    useEffect(() => {
      if (allContactsDeleted) {
        onAddNewClick();
      }
    }, [allContactsDeleted]);
  return (
    <>
     <div className="wallet-profilepad" style={{padding:'0px 40px 10px 55px'}}>
     {docvalues?.emergency_contacts?.map((doc, index) => {
  // Ensure that core_emergency_id is unique or use a combination of values to make it unique
  const uniqueKey = doc.core_emergency_id ? doc.core_emergency_id : `fallback-${index}`;
  const relationLabel = formValues.relationoption.find(
    (option) => option.value === doc.relation
  )?.label;

  const hasError = doc.kyc_emergency_id === null && doc.core_emergency_id === contactErrors;
  const isdelete = doc.kyc_emergency_id === null && deleteid.includes(doc.core_emergency_id);;
  if(isdelete){
    return null;
  }
  
  return (
    <React.Fragment key={uniqueKey}> {/* Use a unique key */}
      <div className={`emer_box ${hasError ? 'emer_box_bottom' : ''}`}>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex wid70emer'>
            <div>
             <ProfileImage containerClass="profile_emergecy_list" imageSrc={doc.image ? `${publicDomainurl}/${doc.image}` : ''} showeye={false} eyeclassname='profile_eye_' imgClass="modal_user_profile"/>
            </div>
            <div>
              <div>
                <h6 className='emerh6'>{doc.name}({relationLabel || doc.relation})</h6>
              </div>
              <div className='d-flex align-items-center mb-2' style={{ gap: '10px' }}>
                <img src={call} alt='call' className='emerimage'></img>
                <p className='emerp'>{doc.mobile}</p>
              </div>
              <div className='d-flex align-items-center' style={{ gap: '10px' }}>
                <img src={message} alt='message' className='emerimage'></img>
                <p className='emerp'>{doc.email}</p>
              </div>
            </div>
          </div>
          <div className='wid30emer'>
            <div className='d-flex align-items-center' style={{ gap: '10px' }}>
              <div className='w-100'>
                <button className='emereditbutton' onClick={() => emergencyedit(doc)}><img src={edit1} alt='edit' width='15px' height='15px' className='me-2'></img>Edit</button>
              </div>
              <div>
                <img src={eye} onClick={() => imageview(doc.image)} alt='eye' className='emereyeimage mb-2'></img>
                <img src={delet} onClick={() => emergencydelete(doc)} alt='delete' className='emereyeimage'></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      {hasError && (
        <div className="error-message">{contactErrors1}</div>
      )}
    </React.Fragment>
  );
})}

        <div className='text-end'>
            <button className='addmewemer' onClick={onAddNewClick}>Add new</button>
        </div>
        <hr className="walletprofile-mar" />
        {error422 && <p className="errors">{error422}</p>}
        <div className="walletprofile-buttons mt-3">
          <button
            className={`walletprofile-next me-3  walletprofile-next-active`}
            onClick={handleNext}
          >
            Finish
          </button>
          <button
            className="walletprofile-cancel"
            onClick={handleBack}
          >
            Cancel
          </button>
        </div>
     </div>
     {formValues.selectimage && (
                <Imageviewer isPreviewVisible={isPreviewVisible} selectedImage1={formValues.selectimage}onClose={() => setPreviewVisible(false)} isMultiImage={false} />
     )}
     <Emergencyeditmodal show={show}  handleClose={handleClose} doc={mapvalues} setActiveStep={setActiveStep} activeStep={activeStep}  setIsLoading4={setIsLoading4} setShowAddNew={setShowAddNew} getemergencydocid={getemergencydocid} getemergency={getemergency} />
     <Passcodemodal show={showPasscodeModal} kyc_id={formValues.kyc_id} handleClose={handleClosePasscodeModal} />
    </>
  )
})

export default WithToast(Emergencylist)