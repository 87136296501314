import React from 'react';
import { Modal} from 'react-bootstrap';

const ReusableModal = ({
  show,
  handleClose,
  dialogClassName = '',
  contentClassName = '',
  size = 'lg',
  centered = true,
  children,
}) => {
  return (
    <Modal show={show} dialogClassName = {dialogClassName} contentClassName={contentClassName}  onHide={handleClose} size={size} centered={centered}>
      <Modal.Body className='card-example-dialog-body'>{children}</Modal.Body>
    </Modal>
  );
};

export default ReusableModal;
 