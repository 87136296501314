import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import eye from "../../images/eye-01 (1).png";
import eyeslash from '../../images/Profile Setup/eye-slash-01.png';
import eyegrey from '../../images/eye-01pass.png';
import eyeslashgrey from '../../images/eye-slash-01passw.png';
import { updatekycpasscodeapi} from '../../api/helper/urlhelper';
import axiosInstance from '../../api/utils/axiosutils';
import { handleApiError } from '../../api/utils/utils';
import WithToast from '../../api/hook/withtoasthook';
const updatekycpasscodeapiurl = updatekycpasscodeapi();
const PasscodeModal = ({ show,kyc_id ,handleClose,showErrorToast,}) => {
  const [password, setPassword] = useState({
    newpasscode: '',
    confirmpasscode: '',
    newpasscodeshow: false,
    confirmpasscodeshow: false
  });
  const [enabled ,setEnabled] = useState(false);
  const [error422,setError422]=useState('');
  const handleInputChange = ({ target: { name, value } }) => {
    // Extract numeric value and limit to 5 digits
    const numericValue = value.replace(/[^0-9]/g, '').slice(0, 5);
  
    // Check for numeric value error
    const containsNonNumeric = /[^0-9]/.test(value);
    
    // Check for length error
    const isTooLong = value.length > 5;
  
    // Determine error messages based on validation checks
    if (containsNonNumeric) {
      setError422('Only numeric values are allowed.');
    } else if (isTooLong) {
      setError422('Only up to 5 digits are allowed.');
    } else {
      setError422(''); // Clear the error if the input is valid
    }
  
    // Update the state with the cleaned numeric value
    setPassword(prevState => ({
      ...prevState,
      [name]: numericValue
    }));
  };
  

  const togglePasswordVisibility = (field) => {
    setPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field]
    }));
  };
  const isConsecutive = (str) => {
    return /(?:01234|12345|23456|34567|45678|56789|98765|87654|76543|65432|54321|43210)/.test(str);
  };

  const isRepeated = (str) => {
    return /(\d)\1{4}/.test(str);
  };
  const validatePasscode = (passcode) => {
    if (isConsecutive(passcode)) {
      return 'Passcode cannot be consecutive numbers.';
    }
    if (isRepeated(passcode)) {
      return 'Passcode cannot be repeated numbers.';
    }
    return '';
  };
  const check = useCallback(()=>{
    const valid = (
      password.newpasscode !== '' && password.confirmpasscode !== ''
    );
    setEnabled(valid);
  },[password.newpasscode,password.confirmpasscode]);
  useEffect(()=>{
    check();
  },[ check]);
  const handleClose1 = () => {
    handleClose();
    setError422('')
    setPassword((prev)=>({
        ...prev,newpasscode:'',confirmpasscode:''
    }))
  }
  const handlenext = async() => {
    const newPasscodeError = validatePasscode(password.newpasscode);
    const confirmPasscodeError = validatePasscode(password.confirmpasscode);

    if (newPasscodeError) {
      setError422(newPasscodeError);
      return false;
    }else{
        setError422('');
    } 
    if (confirmPasscodeError) {
      setError422(confirmPasscodeError);
      return false;
    }else{
        setError422('')
    }
    if (password.newpasscode !== password.confirmpasscode) {
      setError422('Passcode do not match.');
      return false;
    }  
    else{
        setError422('');
    }
    const data = {
        kyc_id : kyc_id,
        passcode : password.newpasscode
    }
    try{
       const response = await axiosInstance.post(updatekycpasscodeapiurl,data);
       if(response.status === 200){
        handleClose1();
       }
    }catch(error){
       handleApiError(error,null,null,true,showErrorToast);
    }
  }
  
  return (
    <Modal 
      show={show} 
      dialogClassName="example-walletprofiledialog" 
      contentClassName="example-walletcontent12" 
      onHide={handleClose1} 
      centered
    >
      <Modal.Body style={{ margin: '0', padding: '0' }}>
        <h5 className="walletpasscodeh5">Wallet Passcode</h5>
        <p className='walletpasscodeh5p'>As a final step, secure your wallet by giving it a secure 5-digit passcode. make sure you dont share it to anyone</p>
        <div className="mt-3">
          <label className="walletprofile-label">Set Passcode</label>
          <div style={{ position: "relative" }}>
            <input
              type={password.newpasscodeshow ? 'text' : 'password'}
              className={`form-control mt-1 walletprofile-input ${password.newpasscode ? 'walletprofile-input-active' : ''}`}
              id="newpasscode"
              style={{ position: "relative",}}
              name="newpasscode"
              value={password.newpasscode}
              onChange={handleInputChange}
            />
            <p
              className="toggle-password"
              onClick={() => togglePasswordVisibility('newpasscodeshow')}
              style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
            >
              {password.newpasscodeshow ? (
                password.newpasscode ? (
                    <img src={eye} className='eyeslash' alt="Hide Password" />
                ) : (
                    <img src={eyegrey} className='eyeslash' alt="Hide Password" />
                )
              ) : (
                password.newpasscode ? (
                    <img src={eyeslash} className='eyeslash' alt="Show Password" />
                ) : (
                    <img src={eyeslashgrey} className='eyeslash' alt="Show Password" />
                )
              )}
            </p>
          </div>
        </div>
        <div className="mt-3">
          <label className="walletprofile-label">Confirm Passcode</label>
          <div style={{ position: "relative" }}>
            <input
              type={password.confirmpasscodeshow ? 'text' : 'password'}
              className={`form-control mt-1 walletprofile-input ${password.confirmpasscode ? 'walletprofile-input-active' : ''}`}
              id="confirmpasscode"
              style={{ position: "relative"}}
              name="confirmpasscode"
              value={password.confirmpasscode}
              onChange={handleInputChange}
            />
            <p
              className="toggle-password"
              onClick={() => togglePasswordVisibility('confirmpasscodeshow')}
              style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
            >
              {password.confirmpasscodeshow ? (
                password.confirmpasscode ? (
                    <img src={eye} className='eyeslash' alt="Hide Password" />
                ) : (
                    <img src={eyegrey} className='eyeslash' alt="Hide Password" />
                )
              ) : (
                password.confirmpasscode ? (
                    <img src={eyeslash} className='eyeslash' alt="Show Password" />
                ) : (
                    <img src={eyeslashgrey} className='eyeslash' alt="Show Password" />
                )
              )}
            </p>
          </div>
        </div>
        {error422 && (<div className='errors'>{error422}</div>)}
        <div className="walletprofile-buttons mt-4">
          <button className="walletprofile-cancel-modal me-3" onClick={handleClose1}>
            Cancel
          </button>
          <button className={`walletprofile-next-modal ${enabled ? 'walletprofile-next-modal-active' : 'walletprofile-next-modal-disabled'}`} disabled={!enabled} onClick={handlenext}>
            Submit
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WithToast(PasscodeModal);
