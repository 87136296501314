import React, { memo, useState, useCallback, useEffect,} from 'react';
import {handleApiError, handleKeyPress, validateAndSetMobileChange } from '../../api/utils/utils';
import axiosInstance from '../../api/utils/axiosutils';
import { updatebankapi } from '../../api/helper/urlhelper';
import PhoneInputWithCountrySelector from '../../components/phoneinputcountryselector';
import ReusableModal from '../../components/reusablemodal';
import Closeicon from '../../components/closeicon';
const updatebankapiurl = updatebankapi();
const Editaccountdetailmodal = memo(({show,accountdetails,setMapvalues,setAccountdetails,handleClose,getbanksall,countryData,setMobileChange,mobileChange,setIsLoading4, showErrorToast}) => {
    const [error422,setError422] = useState('');
    const {
      mccId,
      dialCode,
      countryCode,
      countryOptions,
      mobile_length,
      mobile_starting_digits,
      isLoading,
      setMccId,
      setDialCode,
      setCountryCode,
      setMobile_length,
      setMobile_starting_digits,
      loadCountryBySelection,
      loadCountryByIp,
    } = countryData;
    const [enabled,setEnabled] = useState(false);
    const [numberinvalid,setNumberinvalid] = useState(false);
    const [countrychange,setCountrychange] = useState(true);
    useEffect(()=>{
      if(mobileChange && mccId && countryCode && mobile_length && mobile_starting_digits ){
      validateAndSetMobileChange(mobileChange, {
        setMobileChange: setMobileChange,
        setError: (errorMsg) => setAccountdetails((prev) => ({ ...prev, mobileerror: errorMsg })),
        setNumberInvalid: setNumberinvalid,
        mobile_length: mobile_length,             
        mobile_starting_digits: mobile_starting_digits 
      });
    }
    },[mobileChange,mccId,countryCode,mobile_starting_digits,mobile_length]);
        const ip = useCallback(async() =>{
            if(countryCode && countrychange){
                loadCountryBySelection(countryCode) 
            }else if(!countryCode && countrychange){
                loadCountryByIp(true);
            } 
        },[countryCode,countrychange]);        
        useEffect(()=>{
          if(show){  
          ip();
          }
        },[ip,show]);
        const handleKeyPress1 = (e) => {
          const char = String.fromCharCode(e.which);
          if (!/^[a-zA-Z ]$/.test(char)) {
            e.preventDefault();
          }
        };
        const handlePhoneNumberChange = (value) => {
          validateAndSetMobileChange(value, {
            setMobileChange: setMobileChange,
            setError: (errorMsg) => setAccountdetails((prev) => ({ ...prev, mobileerror: errorMsg })),
            setNumberInvalid: setNumberinvalid,
            mobile_length: mobile_length,             
            mobile_starting_digits: mobile_starting_digits 
          });
          setError422('');
        };
        const handlePaste = (e) => {
            const pastedText = e.clipboardData.getData('text');
            let numericPastedText = pastedText.replace(/[^0-9]/g, '');
            const dialCodeWithoutPlus = dialCode.replace('+', '');
            if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
                numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
            }
            validateAndSetMobileChange(numericPastedText, {
              setMobileChange: setMobileChange,
              setError: (errorMsg) => setAccountdetails((prev) => ({ ...prev, mobileerror: errorMsg })),
              setNumberInvalid: setNumberinvalid,
              mobile_length: mobile_length,             
              mobile_starting_digits: mobile_starting_digits 
            });
            setError422('');
            e.preventDefault();
        };
        const handleCountryChange = (selectedOption) => {
            const selectedCountryData = countryOptions.find(
                (country) => country.value === selectedOption.value
            );
            if (selectedCountryData) {
              const { mcc_id, dial_code, country_code, mobile_length, mobile_starting_digits } = selectedCountryData;
          
              setMccId(mcc_id);
              setDialCode(`+${dial_code}`);
              setCountryCode(country_code);
              setMobile_length(mobile_length);
              setMobile_starting_digits(mobile_starting_digits);
              validateAndSetMobileChange(mobileChange, {
                setMobileChange,
                setError: (errorMsg) => setAccountdetails((prev) => ({ ...prev, mobileerror: errorMsg })),
                setNumberInvalid: setNumberinvalid,
                mobile_length,
                mobile_starting_digits,
              });
            }
            setCountrychange(false);
            setError422('');
        };  
    const handleChange = (e) => {
        const { name, value } = e.target;
        setAccountdetails((prevValues) => ({
        ...prevValues,
        [name]: value,
        }));
        setError422('');
    };
    const resetvalues = () => {
        setMapvalues([]);
    }
    const handleClose1 = () =>{
        handleClose();
        resetvalues();
        setCountrychange(true);
        setError422('');
    }
    const check = useCallback(()=>{
     const valid = (
        accountdetails.accountholdername !== '' && accountdetails.accountholdernumber !== '' && mobileChange !== '' && numberinvalid === true
     )
     setEnabled(valid);
    },[accountdetails.accountholdername,accountdetails.accountholdernumber,mobileChange,numberinvalid]);
    useEffect(()=>{
        check();
    },[check]);
    const save = async() => {
        const data = {
            bank_account_id:accountdetails.id,
            country_id : accountdetails.country,
            city_id : accountdetails.city,
            branch_id : accountdetails.branch,
            bank_id : accountdetails.bank,
            account_holdername : accountdetails.accountholdername,
            account_number : accountdetails.accountholdernumber,
            phone_number : mobileChange,
            mcc_id:mccId
        }
      try{
        setIsLoading4(true);
        const response = await axiosInstance.post(updatebankapiurl,data);
        if(response.status === 200){
           await getbanksall();
           handleClose1();
           setIsLoading4(false);
        }
      }catch(error){
         handleApiError(error, setIsLoading4, setError422, false, showErrorToast)
      }
    }
  return (
    <>
    <ReusableModal show={show} handleClose={handleClose1} dialogClassName='card-example-dialog' contentClassName="card-example-content" >
    <div className="text-end">
          <Closeicon className='closeicon mx-1 mt-2' onClick={handleClose1}/>
          </div>
          <div className="card-example-dialog-bodypadd">
            <h5 className="addnewh5">Add Account Details</h5>
            <div className='mt-3'>
            <label htmlFor="Accountholdernumber" className="cardlabel">Account Number<span className="docpopstar">*</span></label>
            <input type="text" id="Accountholdernumber" maxLength={16} onKeyPress={handleKeyPress} placeholder='Enter Account Number'  className={`form-control cardinputbox ${accountdetails.accountholdernumber ? 'cardinputbox_active' : ''}`} value={accountdetails.accountholdernumber} onChange={handleChange} name="accountholdernumber"  />
         </div>
         <div className='mt-2'>
            <label htmlFor="Accountholdername" className="cardlabel">Account Holder<span className="docpopstar">*</span></label>
            <input type="text" id="Accountholdername" placeholder='Enter Account Holder' onKeyPress={handleKeyPress1} className={`form-control cardinputbox ${accountdetails.accountholdername ? 'cardinputbox_active' : ''}`} value={accountdetails.accountholdername} onChange={handleChange} name="accountholdername"  />
         </div>
         <div className='mt-2'>
            <label htmlFor="Accountholderphonenumber" className="cardlabel">Phone Number<span className="docpopstar">*</span></label>
            <div className='position-relative'>
          <PhoneInputWithCountrySelector  countryOptions={countryOptions} handleCountryChange={handleCountryChange} dialCode={dialCode} mobileChange={mobileChange} handlePhoneNumberChange={handlePhoneNumberChange}  handlePaste={handlePaste} countryCode={countryCode} minheight='45px' walletprofileinput = 'walletprofile-input' walletprofileinputactive='walletprofile-input-active' isLoading={isLoading}/>
            </div>
            {accountdetails.mobileerror && <div className="error-message">{accountdetails.mobileerror}</div>}
         </div>
         {error422 && <p className="error-message">{error422}</p>}
        <div className={`${accountdetails.mobileerror ? 'mt-2' : 'mt-4'}`}>
          <button className={`modal_save_card ${enabled ? 'modal_save_card_active' : 'modal_save_card_disabled'}`} disabled={!enabled} onClick={save}>Save</button>
        </div>
          </div> 
    </ReusableModal>
    </>
  )
});
export default Editaccountdetailmodal;