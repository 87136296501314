import React, { useState ,useEffect,useCallback} from "react";
import plus from '../../images/plus.png';
import registerservicce from '../../images/registered_services-01.png';
import Creditcard from "./creditcard";
// import { getcards } from "../../api/addsourcesapi/api";
import { allfunctions, handleApiError} from "../../api/commonfunct.js/common";
import WithToast from "../../api/commonfunct.js/withtoast";
import Cardsmodal from "./cardsmodal";
import { getcards } from "../addsourcegetapi";
function Cards({setIsLoading4,showErrorToast}){
    const [cards,setCard] = useState([]);
    const [show,setShow] = useState(false);
    const getcardall = useCallback(() => {
        getcards()
          .then(res => setCard(res.data.data))
          .catch(error => handleApiError(error, null, null, true, showErrorToast));
      }, [showErrorToast]); // Empty array means `getcardall` doesn't depend on anything
      
      useEffect(() => {
        if (typeof allfunctions === 'function') {
          allfunctions([getcardall]);
        }
      }, [getcardall]);
    const addnewcard = () => {
        setShow(true);
    }
    const handleClose = () => {
        setShow(false);
    }
    return(
        <> 
        {/* <Navbars/>
        <div className="container-fluid " style={{backgroundColor:'#F8F8F8',width:'100%',height:'100%'}}>
            <div className="row pt-4">
                <div className="col-12 col-lg-3 col-md-4 col-xl-3 col-xxl-3 col_corre_2">
                    <Registerservices/>
                </div> */}
                <div className="col-12 col-lg-9 col-md-8 col-xl-9 col-xxl-9 col_corr_1" >
                    <main className="cardsmaindiv" > 
                    <h5 className="ps-4 pt-4 registerservicecard">Cards</h5>
                       <p className="cardsp registerservicecard ps-4 mb-4">This is a system "D Transfer" that are awarded to the app user after engaging in some activity on the app, or website</p>
                       <div className="massage_box_corr">
                        {cards.length > 0 ? (
                           <> 
                           {cards.some((card) => card.card_type.toLowerCase() === 'credit') && (
      <>
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="credith5 ps-2">Credit Cards</h5>
          <button className="addnewcard" onClick={addnewcard}>
            <img src={plus} className="me-2 plus" alt="plus"></img>
            Add new card
          </button>
        </div>

        {/* Map Credit Cards */}
        <div className="cards_multiple">
        {cards
          .filter((card) => card.card_type.toLowerCase() === 'credit')
          .map((card, index) => (
            <Creditcard key={index} card={card} getcardall={getcardall} setIsLoading4={setIsLoading4} />
          ))}
          </div>
      </>
    )}

    {/* Filter and check for Debit Cards */}
    {cards.some((card) => card.card_type.toLowerCase() === 'debit') && (
      <>
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="credith5 ps-2">Debit Cards</h5>
          {!cards.some((card) => card.card_type.toLowerCase() === 'credit') && (
          <button className="addnewcard" onClick={addnewcard}>
            <img src={plus} className="me-2 plus" alt="plus"></img>
            Add new card
          </button>
          )}
        </div>

        {/* Map Debit Cards */}
        <div className="cards_multiple">
        {cards
          .filter((card) => card.card_type.toLowerCase() === 'debit')
          .map((card, index) => (
            <Creditcard key={index} card={card} getcardall={getcardall} setIsLoading4={setIsLoading4}/>
          ))}
          </div>
      </>
    )}
                           </>
                        ) : (
                            <>
                             <div className="d-flex justify-content-between align-items-center">
                             <h5 className="credith5">Credit Cards</h5></div>
                            <div className="d-flex flex-column align-items-center regdiv justify-content-center">
                                <img src={registerservicce} alt="registerservice" className="registerimage" ></img>
                                <p className="registerpcard">Please add your first Card to start making Card transfers</p>
                                <button className="addnewcard1 mb-3" onClick={addnewcard}><img src={plus} className="me-2 plus" alt="plus"></img>Add new card</button>
                            </div>
                            </>
                        )}
                       </div>
                    </main>
                </div>
            {/* </div>
           </div> */}
            <Cardsmodal show={show} handleClose={handleClose} getcardall={getcardall} setIsLoading4={setIsLoading4}/>
        </>
    );
}
export default WithToast(Cards);