import React, { memo,useState,useRef,useCallback,useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import WithToast from '../../../api/commonfunct.js/withtoast';
import { disableFutureDates, handleKeyDown,CustomSuffixIcon, disablePastDates,handleDropfile, handleDragOverfile, formatFileSize, transformString,hanlefrontbackimagechange, handleApiError,getPartAfterKeyword } from '../../../api/commonfunct.js/common';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import delet from '../../../images/times-circle-01.png';
import transparent from '../../../images/Menu_icons/My_documents_black-01.png';
import fileopen from '../../../images/folder-open-01.png';
import eye from '../../../images/eye_green-01.png';
import popdeletes from '../../../images/info_icon/delete-01.png';
import Imageviewer from '../../../api/commonfunct.js/imageviewer';
import { publicDomain, updatekycnationaldocumentapi } from '../../../api/url';
import axiosInstance from '../../../api/commonfunct.js/axiosinstance';
const publicDomainurl = publicDomain();
const updatekycnationaldocumentapiurl = updatekycnationaldocumentapi();
const Nationaleditmodal = memo(({show,showErrorToast,setShow,handleClose,doc,setIsLoading4,setActiveStep, activeStep,getnational,getnationaldocid})=> {
    const [error422,setError422] =useState('');
    const [isenabled,setIsenbled] = useState(false);
    const fileInputRef = useRef(null);
    const fileInputRef1 = useRef(null);
    const [fileSize, setFileSize] = useState('');
    const [format, setFormat] = useState('');
    const [docname,setDocname] = useState('');
    const [error,setError] = useState({
        frontimageerror:'',backimageerror:'',dateofissueerror:''
      })
    const [editImage,setEditImage,] = useState('');
    const [editformat, setEditFormat] = useState('');
    const [editimagedetails,setEditImageDetails] = useState(true);
    const [editProgress, setEditProgress] = useState(0);
    const [editProgressColor, setEditProgressColor] = useState('#28a745');
    const [editDocName, setEditDocName] = useState('');
    const [editFileSize, setEditFileSize] = useState('');
    const [formValues, setFormValues] = useState({
        firstName: '',
        otherNames: '',
        nationalId: '',
        dateOfIssue: '',
        expiryDate: '',
        selectimage:[],
        kyc_id:'',
        id:''
      });
      const acceptPDF = false;
      const [isPreviewVisible, setPreviewVisible] = useState(false);
      const [uploading, setUploading] = useState(false);
      const [progress, setProgress] = useState(0);
      const [progressColor, setProgressColor] = useState("#28a745");
      const [image, setImage] = useState('');
      useEffect(() => {    
        if (doc) {     
            setFormValues((prev)=>({
                ...prev,firstName:doc.first_name || '',
                otherNames:doc.other_name || '',
                nationalId:doc.national_id_number || '',
                dateOfIssue:doc.issue_date || '',
                expiryDate:doc.expiry_date || '',
                kyc_id:doc.kyc_id,
                id:doc.id
            }));
            if(doc.front_img){
              const front = doc.front_img.includes(publicDomainurl) ? doc.front_img : `${publicDomainurl}/${doc.front_img}`
              setImage(front);
            } else{
              setImage('');
            }
            if(doc.back_img){
              const back = doc.back_img.includes(publicDomainurl) ? doc.back_img : `${publicDomainurl}/${doc.back_img}`;
              setEditImage(back);
            }
            // setEditImage(doc.back_img || '');
            setProgress(doc.front_img ? 100 : 0);
            setUploading(doc.front_img ? true : false);
            setEditProgress(doc.back_img ? 100 : 0);
            setEditImageDetails(doc.back_img ? false : true);
        }
    }, [doc]); 
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      };
      const handleFileClick = () => {
        fileInputRef.current.click();
        setError422('');
      }
      const handleBackFileClick = () => {
        fileInputRef1.current.click();
      }      
      const handleFileDrop = (selectedFile,isprimary) => {
        hanlefrontbackimagechange(
          { target: { files: [selectedFile] } },
          isprimary,
          setImage,
          setFileSize,
          setProgress,
          setProgressColor,
          setUploading,
          setFormat,
          setDocname,
          setEditImage,
          setEditFileSize,
          setEditProgress,
          setEditProgressColor,
          setEditImageDetails,
          setEditFormat,
          setEditDocName
        );
      }
      const handleError = (message,isprimary) => {
          if(isprimary){
            setError((prev)=>({
              ...prev,frontimageerror:message
            }));
          }else{
            setError((prev)=>({
              ...prev,backimageerror:message
            }));
          }
      }
      const handleFrontview = (image) => {
         setFormValues((prev)=>({
          ...prev,selectimage:[image]
         }));
         setShow(false);
         setPreviewVisible(true);
      }
      const handleFrontdelete = () => {
        setUploading(false);
        setProgress(0);
        setImage('');
      }
      const handleBackview = (image) => {
        setFormValues((prev)=>({
         ...prev,selectimage:[image]
        }));
        setShow(false);
        setPreviewVisible(true);
     }
     const handleBackdelete = () => {
       setEditImageDetails(true);
       setEditProgress(0);
       setEditImage('');
     }
     const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (open) => {
    setIsOpen(open);
  }; 
  const handleExpiryClick = (e) => {
    if (!formValues.dateOfIssue) {
      setError((prevState) => ({
        ...prevState,
        dateofissueerror:'Please select Date of Issue first'
      }));
      // setExpiryerror('Please select Date of Issue first');
      setIsOpen(false);
    } else {
      setError((prevState) => ({
        ...prevState,
        dateofissueerror:''
      }));
      setIsOpen(true);
    }
  };
  const handleClose1 = () =>{
    handleClose();
    setError((prev) => ({
      ...prev,
      frontimageerror:'',backimageerror:'',dateofissueerror:''
    }));
    setError422('');
}  
  const handleBack = () => {
      handleClose1();
  }
  const handleNext = async() => {
    const data = {
        id:formValues.id,
        kyc_id : formValues.kyc_id,
        first_name : formValues.firstName,
        other_name : formValues.otherNames,
        national_id_number : formValues.nationalId,
        front_img : image.toString(),
        back_img:editImage.toString(),
        issue_date : formValues.dateOfIssue,
        expiry_date : formValues.expiryDate
     }
     try{
       setIsLoading4(true);
       const response = await axiosInstance.post(updatekycnationaldocumentapiurl,data);
       if(response.status === 200){
          await getnational();
          handleClose1();
          const nextStep = activeStep;
           setActiveStep(nextStep);
           // setActiveStep((prevActiveStep) => prevActiveStep + 1);
           localStorage.setItem('activeStep', nextStep);
           setError422('');
           setIsLoading4(false);
       }
     }catch(error){
       handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
     }
  }  
  const check = useCallback(()=>{
    const valid = (
      formValues.firstName !== '' && formValues.otherNames !== '' && formValues.nationalId !== '' && formValues.dateOfIssue !== '' && formValues.expiryDate !== '' && image.length > 0 && editImage.length > 0 
    )
    setIsenbled(valid);
  },[formValues.firstName,formValues.otherNames,formValues.nationalId,formValues.dateOfIssue,formValues.expiryDate,image,editImage]);
  useEffect(()=>{
    check();
  },[check]);
  return (
    <>
      <Modal show={show} size='lg' onHide={handleClose1}>
        <Modal.Body style={{ margin: '0', padding: '0' }}>
           <div className="wallet-profilepad2">
           <div className='text-end modal_delete'>
        <img src={delet} onClick={handleClose1} style={{cursor:'pointer'}} alt='delete' width='30px' height='auto'></img>
       </div>
        <div className='row'>
            <div className='col-6'>
            <label className="walletprofile-label">First Name<span className="docpopstar">*</span></label>
             <input type="text" value={formValues.firstName} onChange={handleInputChange} name='firstName' className={`form-control mt-1 walletprofile-input ${formValues.firstName ? 'walletprofile-input-active' : ''}`} placeholder="Type as it appears on ID" /> 
            </div>
            <div className='col-6'>
            <label className="walletprofile-label">Other Name(S)<span className="docpopstar">*</span></label>
            <input type="text" name='otherNames' value={formValues.otherNames} onChange={handleInputChange} className={`form-control mt-1 walletprofile-input ${formValues.otherNames ? 'walletprofile-input-active' : ''}`} placeholder="Enter other name" /> 
            </div>
        </div>
        <div className='mt-3'>
        <label className="walletprofile-label">National ID number<span className="docpopstar">*</span></label>
        <input type="text" name='nationalId' value={formValues.nationalId} onChange={handleInputChange} className={`form-control mt-1 walletprofile-input ${formValues.nationalId ? 'walletprofile-input-active' : ''}`} placeholder="Enter national ID number" /> 
        </div>
        <div className='mt-3'>
        <div className='row'>  
        <div className='col-6'>      
        <label className="walletprofile-label">Date Of Issue<span className="docpopstar">*</span></label>
        <DatePicker onKeyDown={handleKeyDown} className='mt-1 custom-pick' disabledDate={disableFutureDates} value={formValues.dateOfIssue ? dayjs(formValues.dateOfIssue) : null} onChange={(date) => { setFormValues((prevValues) => ({ ...prevValues, dateOfIssue: date ? dayjs(date).format('YYYY-MM-DD') : '',expiryDate:'' }));setError((prev)=>({...prev,dateofissueerror:''}))}} placeholder='Select your DOB' getPopupContainer={(trigger) => trigger.parentNode} style={{ width: '100%', height: '45px', borderRadius: '6px', border: formValues.dob ? '1px solid black' : '1px solid #DCDCDC', color: formValues.dateOfIssue ? 'black' : '' }} suffixIcon={<CustomSuffixIcon isActive={formValues.dateOfIssue} />} />
        {error.dateofissueerror && <p className="error-message">{error.dateofissueerror}</p>}
        </div>
        <div className='col-6'>      
        <label className="walletprofile-label">Expiry Date<span className="docpopstar">*</span></label>
        <DatePicker onKeyDown={handleKeyDown} onClick={handleExpiryClick} open={isOpen} onOpenChange={handleOpenChange} className='mt-1 custom-pick' disabledDate={disablePastDates(formValues.dateOfIssue)} value={formValues.expiryDate ? dayjs(formValues.expiryDate) : null} onChange={(date) => { setFormValues((prevValues) => ({ ...prevValues, expiryDate: date ? dayjs(date).format('YYYY-MM-DD') : '', }));setError422('')}} getPopupContainer={(trigger) => trigger.parentNode} placeholder='Select your DOB' style={{ width: '100%', height: '45px', borderRadius: '6px', border: formValues.expiryDate ? '1px solid black' : '1px solid #DCDCDC', color: formValues.expiryDate ? 'black' : '' }} suffixIcon={<CustomSuffixIcon isActive={formValues.expiryDate} />} />
        </div>
        </div>
        </div>
        <div className='mt-3'>
            <div className='row'>
           <div className='col-6'>
        <label className="walletprofile-label">Front<span className="docpopstar">*</span></label>
          {uploading ? (
            <div className="popprogressbox">
            <div className="d-flex align-items-center ">
              <div className="d-flex align-items-center mb-2" style={{width:'75%'}}>
              {image && typeof image === 'string' && (image.endsWith('.pdf') || image.startsWith('data:application/pdf'))  ? (
                <img src={transparent} alt="file" className="popuploadfileimg me-2"></img>
              ) : (
                <img src={image} alt="file" className="popuploadfileimg me-2"></img>
              )}
                <div style={{overflow:'hidden'}}>
                <p className="popfilefrontp">{(typeof image === 'string' && (image.startsWith('data:') || image.startsWith('data'))) ? `${transformString(docname)}.${transformString(format)}` : getPartAfterKeyword(image, 'national-documents/')}</p>
                {fileSize && fileSize >= 1024 && (
                      <p className="popfilekb">{formatFileSize(fileSize)}</p>
                      )}
                </div>
              </div>
              <div style={{textAlign:'end',width:'25%'}}>
                {progress === 100 && (
                  <img src={eye} alt="eye" onClick={()=>handleFrontview(image)} className="popupeye me-2"></img>
                )}
                <img src={popdeletes} onClick={handleFrontdelete} alt="delete" className="popupeye" ></img>
              </div>
            </div>
            <div className="progress mt-1 mb-1">
              <div className="progress-bar" role="progressbar" style={{ width: `${progress}%`, backgroundColor: progressColor }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
                {``}
              </div>
            </div>
          </div>
          ):(
            <div className="popinputbox mt-3"onClick={handleFileClick} onDrop={(e) => handleDropfile(e, handleFileDrop,handleError,acceptPDF,true)} onDragOver={handleDragOverfile} >
            <div className="d-flex align-items-center justify-content-center flex-column p-2">
              <img src={fileopen} className="popuploadflle" alt="file" width='48px' height='auto'></img>
              <h5 className="popuploadh5"><span className="popspan">Click to Upload</span>or drag and drop</h5>
              <p className="popuploadp">File format: .png, .jpeg, .jpg</p>
              <p className="popuploadp">( Max. File size : 20 MB)</p>
            </div>
            <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept=".jpg, .jpeg, .png" onChange={(e) => hanlefrontbackimagechange(e,true,setImage, setFileSize, setProgress, setProgressColor,setUploading,setFormat,setDocname , setEditImage,setEditFileSize,setEditProgress,setEditProgressColor,setEditImageDetails,setEditFormat,setEditDocName )} />
          </div>
          )}
              {error.frontimageerror && <p className="error-message">{error.frontimageerror}</p>}
            </div> 
           <div className='col-6'>
        <label className="walletprofile-label">Back<span className="docpopstar">*</span></label>
          {editimagedetails ? (
             <div className="popinputbox mt-3" onClick={handleBackFileClick} onDrop={(e) => handleDropfile(e, handleFileDrop,handleError,acceptPDF,false)} onDragOver={handleDragOverfile}>
               <div className="d-flex align-items-center justify-content-center flex-column p-2">
                 <img src={fileopen} className="popuploadflle" alt="file" width='48px' height='auto'></img>
                 <h5 className="popuploadh5"><span className="popspan">Click to Upload</span>or drag and drop</h5>
                 <p className="popuploadp">File format: .png, .jpeg, .jpg</p>
                 <p className="popuploadp">( Max. File size : 20 MB)</p>
               </div>
               <input type="file" ref={fileInputRef1} style={{ display: 'none' }} accept=".jpg, .jpeg, .png"onChange={(e) => hanlefrontbackimagechange(e,false,setImage, setFileSize, setProgress, setProgressColor,setUploading,setFormat,setDocname , setEditImage,setEditFileSize,setEditProgress,setEditProgressColor,setEditImageDetails,setEditFormat,setEditDocName )} />
             </div>   
          ):(
            <div className="popprogressbox">
            <div className="d-flex align-items-center ">
              <div className="d-flex align-items-center mb-2" style={{width:'75%'}}>
                <img src={editImage} alt="file" className="popuploadfileimg me-2"></img>
                <div style={{overflow:'hidden'}}>
                <p className="popfilefrontp">{(typeof editImage === 'string' && (editImage.startsWith('data:') || editImage.startsWith('data'))) ? `${transformString(editDocName)}.${transformString(editformat)}` : getPartAfterKeyword(editImage, 'national-documents/')}</p>
                {editFileSize && editFileSize >= 1024 && (
                      <p className="popfilekb">{formatFileSize(editFileSize)}</p>
                      )}
                </div>
              </div>
              <div style={{textAlign:'end',width:'25%'}}>
                {editProgress === 100 && (
                  <img src={eye} alt="eye" onClick={()=>handleBackview(editImage)} className="popupeye me-2"></img>
                )}
                <img src={popdeletes} onClick={handleBackdelete} alt="delete" className="popupeye" ></img>
              </div>
            </div>
            <div className="progress mt-1 mb-1">
              <div className="progress-bar" role="progressbar" style={{ width: `${editProgress}%`, backgroundColor: editProgressColor }} aria-valuenow={editProgress} aria-valuemin="0" aria-valuemax="100">
                {``}
              </div>
            </div>
          </div>
              )}
              {error.backimageerror && <p className="error-message">{error.backimageerror}</p>}
              </div>
              </div> 
        </div>
        {error422 && <p className="error-message">{error422}</p>}
        <div className="walletprofile-buttons mt-3">
          <button
            className={`walletprofile-next me-3 ${isenabled ? 'walletprofile-next-active' : 'walletprofile-next-disabled'}`}
            disabled={!isenabled}
            onClick={handleNext}
          >
            Save
          </button>
          <button
            className="walletprofile-cancel"
            // disabled={activeStep === 0}
            onClick={handleBack}
          >
            Cancel
          </button>
        </div>
    </div>
        </Modal.Body> 
      </Modal>
      {formValues.selectimage &&
    <Imageviewer isPreviewVisible={isPreviewVisible} selectedImage1={formValues.selectimage} onClose={() => {setPreviewVisible(false);setShow(true)}} isMultiImage={false}/>
    }
    </>
  )
})

export default WithToast(Nationaleditmodal);