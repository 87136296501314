import React, { memo,useCallback,useState,useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import WithToast from '../../api/hook/withtoasthook';
import closeicon from '../../images/times-circle-01.png';
import { updateorganizationapi } from '../../api/helper/urlhelper';
import axiosInstance from '../../api/utils/axiosutils';
import { handleApiError } from '../../api/utils/utils';
const updateorganizationapiurl = updateorganizationapi();
const Organizationeditmodal= memo(({show,handleClose,getorganizationallall,setIsLoading4,mapvalues,showErrorToast})=>{
    const [formValues,setFormValues] = useState({addorganizationname:'',addcompanyid:'',companyEmail:'',id:''});
    useEffect(()=>{
     if(mapvalues){
       setFormValues((prev)=>({
        ...prev,addorganizationname : mapvalues.organization_name || '',
        addcompanyid : mapvalues.organization_id || '',
        companyEmail : mapvalues.organization_email || '',
        id : mapvalues.id || ''
       }));
     }
    },[mapvalues]);
    const [enabled,setEnabled] = useState(false);
    const [error422,setError422] = useState('');
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
        }));
        setError422('');
    };
    const resetFormValues = () => {
        setFormValues({
            id:'',addorganizationname:'',companyEmail:'',addcompanyid:''
        });
        setError422('');
      };
    const handleClose1 = () => {
       handleClose();
       resetFormValues();
       setIsLoading4(false);
    }
    const check = useCallback(()=>{
        const valid  = (
            formValues.addorganizationname !== '' && formValues.addcompanyid !== '' && formValues.companyEmail !== ''
        )
        setEnabled(valid);
    },[formValues.addorganizationname,formValues.addcompanyid,formValues.companyEmail])
    useEffect(()=>{
        check();
    },[check]);
    const save = async() => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(!emailPattern.test(formValues.companyEmail)){
            setError422('Please enter a valid email address.');
            return;
        }else{
            setError422('');
        }
        const data = {
            organization_company_id:formValues.id,
            organization_name:formValues.addorganizationname,
            organization_id:formValues.addcompanyid,
            organization_email:formValues.companyEmail
        }
        try{
          setIsLoading4(true);
          const response = await axiosInstance.post(updateorganizationapiurl,data);
          if(response.status === 200){
            await getorganizationallall();
            handleClose1();
          }
        }catch(error){
            handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
        }
    }
  return (
   <>
     <Modal show={show} dialogClassName="card-example-dialog" contentClassName="card-example-content" onHide={handleClose1} style={{paddingRight:'0px'}} centered>
        <Modal.Body className='card-example-dialog-body'>
        <div className="text-end">
            <img src={closeicon} onClick={handleClose1} style={{cursor:'pointer'}} className="closeicon mx-1 mt-2" alt="closeicon" ></img>
         </div>
         <div className='card-example-dialog-bodypadd'>
         <h5 className="addnewh5 ">Add Organization/Company</h5>
         <div className='mt-3'>
         <label htmlFor="Addorganizationname" className="cardlabel">Enter Organization/Company Name</label>
         <input type="text" id="Addorganizationname" placeholder='Enter Organization/Company Name'  className={`form-control cardinputbox ${formValues.addorganizationname ? 'cardinputbox_active' : ''}`} value={formValues.addorganizationname} onChange={handleChange} name="addorganizationname"  />
         </div>
         <div className='mt-2'>
         <label htmlFor="EnterOrganization" className="cardlabel">Enter Organization/Company ID</label>
         <input type="text" id="EnterOrganization" placeholder='Enter Organization/Company ID'  className={`form-control cardinputbox ${formValues.addcompanyid ? 'cardinputbox_active' : ''}`} value={formValues.addcompanyid} onChange={handleChange} name="addcompanyid"  />
         </div>
         <div className='mt-2'>
         <label htmlFor="CompanyEmail" className="cardlabel">Enter your Company Email</label>
         <input type="email" id="CompanyEmail" placeholder='Enter your Company Email'  className={`form-control cardinputbox ${formValues.companyEmail ? 'cardinputbox_active' : ''}`} value={formValues.companyEmail} onChange={handleChange} name="companyEmail"  />
         </div>
         {error422 && <p className="error-message">{error422}</p>}
        <div className='mt-4'>
          <button className={`modal_save_card ${enabled ? 'modal_save_card_active' : 'modal_save_card_disabled'}`} disabled={!enabled} onClick={save}>Save</button>
        </div>
         </div>
        </Modal.Body>
        </Modal> 
   </>
  )
})

export default WithToast(Organizationeditmodal);