import React,{memo, useState,useEffect,useCallback} from 'react';
import WithToast from '../../api/hook/withtoasthook';
import Select from 'react-select';
import { customStyles2, validateAndSetMobileChange } from '../../api/utils/utils';
import { handleApiError,} from '../../api/utils/utils';
import { publicDomain,getemergencyrelationlistapi, updateemergencycontactsapi } from '../../api/helper/urlhelper';
import axiosInstance from '../../api/utils/axiosutils';
import { useCountryData } from '../../api/hook/mobilehook';
import PhoneInputWithCountrySelector from '../../components/phoneinputcountryselector';
import ReusableModal from '../../components/reusablemodal';
import Closeicon from '../../components/closeicon';
import ProfileImage from '../../components/profileimage';
const publicDomainurl = publicDomain();
const updateemergencycontactsapiurl = updateemergencycontactsapi();
const getemergencyrelationlistapiurl = getemergencyrelationlistapi();
const Emergencyeditmodal = memo(({show,showErrorToast,handleClose,doc,setIsLoading4,setActiveStep, activeStep,getemergency,setShowAddNew,getemergencydocid}) => {
  const [formValues, setFormValues] = useState({
    changeimage: true,
    name: '',
    image: '',
    email: '',
    relation: null,
    relationoption: [],
    isnextofkin: false,
    responsephoneNumber: '',
    selectOption: '',
    isLoading: false,
    isrelationloading: false,
    kyc_id:'',
    core_emergency_id:null,
    kyc_emergency_id:null,

   });
   const {
    mccId, dialCode, countryCode, countryOptions,mobile_length,mobile_starting_digits,isLoading,setMccId,setDialCode,setCountryCode,setMobile_length,setMobile_starting_digits,
    loadCountryBySelection
  } = useCountryData();
  const [countrychange,setCounrychange] = useState(true);
  const [mobileChange, setMobileChange] = useState('');
  const [numberinvalid,setNumberinvalid] = useState(false);
    const [shouldFetchCountryCode, setShouldFetchCountryCode] = useState(false);
    const [formValuesError, setFormValuesError] = useState({ imageError: '', nameError: '',mobileerror:'',emailerror:'' });
    useEffect(() => {    
        if (doc) {     
            setFormValues({
                changeimage: doc.image ? true : false,
                image: doc.image || '',
                email: doc.email  || '',
                isnextofkin : doc.kin_status ? 1 : 0,
                name:doc.name || '',
                kyc_id :getemergencydocid,
                core_emergency_id:doc.core_emergency_id || null,
                kyc_emergency_id:doc.kyc_emergency_id || null
            });      
            setMobileChange(doc.mobile || '');
            setCountryCode(doc.country_code || "");
            setMccId(doc.mcc_id || '')
        }
    }, [doc,getemergencydocid]); 
    useEffect(()=>{
      if(doc && formValues.relationoption){
        const sa = formValues.relationoption.find(option => option.value === doc.relation);
        setFormValues((prev)=>({
          ...prev,relation:sa || null
        }))
      }
    },[formValues.relationoption,doc])
    const [isenabled,setIsenbled] = useState(false);
    const [error422,setError422] = useState('');
    const handleClose1 = () =>{
        handleClose();
        setFormValuesError((prev) => ({
          ...prev,
          imageError: '',
          nameError: '',
          mobileerror: '',
          emailerror: ''
        }));
        setMobileChange('');
        setCountryCode('');
        setMccId('');
        setMobile_length('');
        setMobile_starting_digits('');
        setDialCode('');
        setError422('');
        setShouldFetchCountryCode(false);
    }    
    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
        setError422('');
    };
    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setFormValues((prev) => ({
              ...prev,
              image: reader.result,
              changeimage: true
            }));
          };
          reader.readAsDataURL(file);
        }
      };
      const handledelete = () => {
        setFormValues((prev)=>({
            ...prev,changeimage:false,image:''
        }));
      }
      const handleKeyDown1 = (e) => {
        const regex = /^[a-zA-Z\s]*$/;
        if (!regex.test(e.key)) {
          e.preventDefault();
          setFormValuesError((prev) => ({
            ...prev,
            nameError: 'Only alphabets are allowed'
          }));
        } else {
          setFormValuesError((prev) => ({
            ...prev,
            nameError: ''
          }));
        }
      };
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        if(name==='name'){
          setError422('');
          setFormValuesError((prev)=>({
            ...prev,nameError : ''
          }));
        }
        if(name==='relation'){
          setError422('');
          
        }
        if(name==='responsephonenumber'){
          setError422('');
          setFormValuesError((prev)=>({
            ...prev,mobileerror : ''
          }));
        }if(name==='email'){
          setError422('');
          setFormValuesError((prev)=>({
            ...prev,emailerror : ''
          }));
        }
      };
      const handlecheckchange = (e) => {
        setFormValues((prev)=>({
          ...prev,
          isnextofkin:e.target.checked
        }));
      } 
      const getrelationlist = useCallback(async()=>{
        try{
           const response = await axiosInstance.get(getemergencyrelationlistapiurl);
           if(response.status === 200){
              const relationlist = response.data.data;
              setFormValues((prev)=>({
               ...prev,relationoption:relationlist.map(relat => ({value:relat.id,label:relat.name}))
              }))              
           }
        }catch(error){
         handleApiError(error, null, null, true, showErrorToast);
        }
     },[showErrorToast]);
     useEffect(()=>{
      if(show  && mobileChange && mccId && countryCode && mobile_length && mobile_starting_digits ){
      validateAndSetMobileChange(mobileChange, {
        setMobileChange: setMobileChange,
        setError: (errorMsg) => setFormValuesError((prev) => ({ ...prev, mobileerror: errorMsg })),
        setNumberInvalid: setNumberinvalid,
        mobile_length: mobile_length,             
        mobile_starting_digits: mobile_starting_digits 
      });
    }
    },[mobileChange,mccId,countryCode,mobile_starting_digits,mobile_length,show]);
      useEffect(() => {
        if (show) {
          setShouldFetchCountryCode(true);
        } else {
          setShouldFetchCountryCode(false);
        }
      }, [show]);
    
      useEffect(() => {
        if (shouldFetchCountryCode && countrychange) {
          // fetchCountryCode();
          loadCountryBySelection(countryCode)
          getrelationlist();
        }
      }, [countryCode,shouldFetchCountryCode,getrelationlist,countrychange]);
      const handlePhoneNumberChange = (value) => {
        validateAndSetMobileChange(value, {
          setMobileChange: setMobileChange,
          setError: (errorMsg) => setFormValuesError((prev) => ({ ...prev, mobileerror: errorMsg })),
          setNumberInvalid: setNumberinvalid,
          mobile_length: mobile_length,             
          mobile_starting_digits: mobile_starting_digits 
        });
        setError422('');
      };
      const handlePaste = (e) => {
          const pastedText = e.clipboardData.getData('text');
          let numericPastedText = pastedText.replace(/[^0-9]/g, '');
          const dialCodeWithoutPlus = dialCode.replace('+', '');
          if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
              numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
          }
          validateAndSetMobileChange(numericPastedText, {
            setMobileChange: setMobileChange,
            setError: (errorMsg) => setFormValuesError((prev) => ({ ...prev, mobileerror: errorMsg })),
            setNumberInvalid: setNumberinvalid,
            mobile_length: mobile_length,             
            mobile_starting_digits: mobile_starting_digits 
          });
          e.preventDefault();
          setError422('');
      };
      const handleCountryChange = (selectedOption) => {
          const selectedCountryData = countryOptions.find(
              (country) => country.value === selectedOption.value
          );
          if (selectedCountryData) {
            const { mcc_id, dial_code, country_code, mobile_length, mobile_starting_digits } = selectedCountryData;
        
            setMccId(mcc_id);
            setDialCode(`+${dial_code}`);
            setCountryCode(country_code);
            setMobile_length(mobile_length);
            setMobile_starting_digits(mobile_starting_digits);
            validateAndSetMobileChange(mobileChange, {
              setMobileChange,
              setError: (errorMsg) => setFormValuesError((prev) => ({ ...prev, mobileerror: errorMsg })),
              setNumberInvalid: setNumberinvalid,
              mobile_length,
              mobile_starting_digits,
            });
          }
          setCounrychange(false);
          setError422('');
      };
      const check = useCallback(()=>{
        const valid = (
          formValues.name !== '' && formValues.relation !== '' &&  formValues.image !== '' && formValues.changeimage !== false && mobileChange !== '' && formValues.email !== '' && numberinvalid === true
         );
         setIsenbled(valid);
      },[formValues.name,formValues.relation,formValues.image,formValues.changeimage,mobileChange,formValues.email,numberinvalid]);
      useEffect(()=>{
        check();
      },[check]);
      const save = async() => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formValues.email)) {
          setFormValuesError((prevstate)=>({
            ...prevstate,emailerror:'Please enter a valid email address.'
          }))
          return;
        }else{
          setFormValuesError((prevstate)=>({
            ...prevstate,emailerror:''
          }))
        }
        const data = {
          kyc_id: formValues.kyc_id,
          image:formValues.image,
          mcc_id:mccId,
          relation:formValues.relation.value,
          kin_status:formValues.isnextofkin ? 1 : 0,
          mobile:mobileChange,
          email : formValues.email,
          name:formValues.name,
        }
        if (formValues.kyc_emergency_id) {
          data.kyc_emergency_id = formValues.kyc_emergency_id;
        } else {
            data.core_emergency_id = formValues.core_emergency_id;
        }
        try{
          setIsLoading4(true);
           const response = await axiosInstance.post(updateemergencycontactsapiurl,data);
           if(response.status === 200){
            await getemergency();
            handleClose1();
            setShowAddNew(false);
            const nextStep = activeStep;
            setActiveStep(nextStep);
            localStorage.setItem('activeStep', nextStep);
            setError422('');
            setMccId('');
            setCountryCode('');
            setDialCode('');
            setMobileChange('');
            setIsLoading4(false);
           }
        }catch(error){
           handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
        }
      }
  return (
    <>
    <ReusableModal show={show} handleClose={handleClose1} dialogClassName='example-walletprofiledialog1' contentClassName="example-walletcontent1" >
    <div className="text-end">
            <Closeicon className='closeicon ' onClick={handleClose1}/>
          </div>
          <div className='example-walletprofiledialog1padd'>
        {formValues.changeimage ? (
         <>   
            <div className="wallet-profilediv">
            <div className="profilediv profilediv1">
          <ProfileImage containerClass="profile_emergecy_list" imageSrc={formValues.image.startsWith('data:') ? formValues.image : `${publicDomainurl}/${formValues.image}`} showeye={false} eyeclassname='profile_eye_' imgClass="modal_user_profile"/>
          <div>
          <p className="walletmaximagepmodal">Upload Your Picture</p>
          <p className='walletmaximagemodal'>Max image size - 2mb</p>
        </div>
      </div>
     
      <div className="profile-uploadbuttondiv profile-uploadbuttondiv1">
        <button className='profile-uploadmodal profile-uploadreplace' onClick={triggerFileInput}>Replace</button>
        <input type="file" accept="image/png, image/jpg, image/jpeg" id="fileInput" style={{ display: 'none' }} onChange={handleImageUpload} />
        <button className="profile-uploadmodal profile-uploaddelete" onClick={handledelete}>Delete</button>
      </div>
      </div>
      </>
        ):(
           <>
           <div className="wallet-profilediv">
          <div className="profilediv">
          <ProfileImage containerClass="profile_emergecy_list" imageSrc={''} showeye={false} eyeclassname='profile_eye_' imgClass="modal_user_profile"/>
            <div>
              <p className="walletmaximagepmodal">Upload Your Picture</p>
              <p className='walletmaximagemodal'>Max image size - 2mb</p>
            </div>
          </div>
          <div className="profile-uploadbuttondiv">
            <input type="file" accept="image/png, image/jpg, image/jpeg" id="fileInput" style={{ display: 'none' }} onChange={handleImageUpload} />
            <button className="profile-uploadmodal" onClick={triggerFileInput}>Upload</button>
          </div>
           </div>
           </>
        )}
        <div className="mt-3">
        <label className="walletprofile-label">Name<span className="docpopstar">*</span></label>
        <input type="text" name='name' value={formValues.name} onKeyDown={handleKeyDown1} onChange={handleChange} className={`form-control mt-1 walletprofile-input-modal ${formValues.name ? 'walletprofile-input-active-modal' : ''}`} placeholder="Enter your name" />
        {formValuesError.nameError && <p className="error-message error-message1">{formValuesError.nameError}</p>}
        </div>
        <div className="mt-3">
        <label className="walletprofile-label">Mobile Number<span className="docpopstar">*</span></label>
        <div style={{ position: 'relative' }}>
        <PhoneInputWithCountrySelector  countryOptions={countryOptions} handleCountryChange={handleCountryChange} dialCode={dialCode} mobileChange={mobileChange} handlePhoneNumberChange={handlePhoneNumberChange}  handlePaste={handlePaste} countryCode={countryCode} minheight='40px' walletprofileinput = 'walletprofile-input-modal' walletprofileinputactive='walletprofile-input-active-modal' isLoading={isLoading}/>
          </div>
        {formValuesError.mobileerror && <p className="error-message error-message1">{formValuesError.mobileerror}</p>}
      </div>
        <div className="mt-3">
        <label className="walletprofile-label">Email Address<span className="docpopstar">*</span></label>
        <input type="text" name='email' value={formValues.email}  onChange={handleChange} className={`form-control mt-1 walletprofile-input-modal ${formValues.email ? 'walletprofile-input-active-modal' : ''}`} placeholder="Enter your email" />
        {formValuesError.emailerror && <p className="error-message error-message1">{formValuesError.emailerror}</p>}
      </div>
      <div className='mt-3'>
      <label className="walletprofile-label">Relation<span className="docpopstar">*</span></label> 
       <Select name="relation" options={formValues.relationoption} value={formValues.relation} onChange={(selectOption)=>setFormValues((prev)=>({...prev,relation:selectOption}))} className='mt-1' placeholder="Select Relation"  styles={customStyles2(Boolean(formValues.relation))}  />
      </div>
      <div className="d-flex align-items-center mt-3" style={{gap:'10px'}}>
      <input type="checkbox" checked={formValues.isnextofkin} onChange={handlecheckchange} className="form-check-input emer-check"></input>
      <p className="emercheckp">Next of Kin</p>
      </div>
      {error422 && <p className="error-message error-message1">{error422}</p>}
      <div className='d-flex align-items-center justify-content-end'>
      <button className='me-3 modalwallet-cancel' onClick={handleClose1}>Cancel</button>
      <button className={`${isenabled ? 'modalwallet-save' : 'modalwallet-save-dis'}`} disabled={!isenabled} onClick={save}>Save</button>
      </div>         
        </div>       
    </ReusableModal>
    </>
  );
})
export default WithToast(Emergencyeditmodal);