import React, { memo, useState, useCallback, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import closeicon from '../../images/times-circle-01.png';
import Select from 'react-select';
import { customStyles3, handleApiError } from '../../api/commonfunct.js/common';
import { getbanklist, getcitylist, getcountrylist,getbankbranchlist } from '../addsourcegetapi';
import Editaccountdetailmodal from './editaccountdetailmodal';
const Editbankdetailmodal = memo(({ show,accountdetails,setMapvalues,setAccountdetails,handleClose,getbanksall, setIsLoading4, showErrorToast}) => {
    const [enabled,setEnabled] = useState(false);
    const [countryLoading, setCountryLoading] = useState(false);
    const [cityLoading, setCityLoading] = useState(false);
    const [bankLoading, setBankLoading] = useState(false);
    const [branchLoading, setBranchLoading] = useState(false);
    const [show1,setShow1] = useState(false);
    const [countryOptions, setCountryOptions] = useState([]);
    const [bankOptions, setBankOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [branchOptions, setBranchOptions] = useState([]);
    const getcountrylistall = useCallback(() => {
        setCountryLoading(true);
        getcountrylist()
          .then((res) => {
            const country = res.data.data;
            setCountryOptions(country.map(company => ({ value: company.id, label: company.name })));
          })
          .catch((error) => handleApiError(error, null, null, true, showErrorToast))
          .finally(()=>setCountryLoading(false));
      }, [showErrorToast]);
      useEffect(()=>{
        if(show){
          getcountrylistall();
        }
      },[getcountrylistall,show]);
      const getbankcitylistall = useCallback(() => {
        if (!accountdetails.country) return;
        const data = { country_id: accountdetails.country };
        setCityLoading(true);
        getcitylist(data)
          .then((res) => {
            const cities = res.data.data;
            setCityOptions(cities.map(city => ({ value: city.id, label: city.destination })));
          })
          .catch((error) => handleApiError(error, null, null, true, showErrorToast))
          .finally(() => setCityLoading(false));
      }, [accountdetails.country, showErrorToast]);
    
      const getbanklistall = useCallback(() => {
        if (!accountdetails.country || !accountdetails.city) return;
        const data = { country_id: accountdetails.country, city_id: accountdetails.city };
        setBankLoading(true);
        getbanklist(data)
          .then((res) => {
            const banks = res.data.data;
            setBankOptions(banks.map(bank => ({ value: bank.id, label: bank.bank_name })));
          })
          .catch((error) => handleApiError(error, null, null, true, showErrorToast))
          .finally(() => setBankLoading(false));
      }, [accountdetails.country, accountdetails.city, showErrorToast]);
    
      const bankbranchall = useCallback(() => {
        if (!accountdetails.country || !accountdetails.city || !accountdetails.bank) return;
        const data = { country_id: accountdetails.country, city_id: accountdetails.city, bank_id: accountdetails.bank };
        setBranchLoading(true);
        getbankbranchlist(data)
          .then((res) => {
            const branches = res.data.data;
            setBranchOptions(branches.map(branch => ({ value: branch.id, label: branch.branch_name })));
          })
          .catch((error) => handleApiError(error, null, null, true, showErrorToast))
          .finally(() => setBranchLoading(false));
      }, [accountdetails.country, accountdetails.city, accountdetails.bank, showErrorToast]);
    
      const handleCountryChange = (selectedCountry) => {
        setAccountdetails((prev) => ({
          ...prev,
          country: selectedCountry.value,
          bank: null,
          city: null,
          branch: null
        }));
        // getbankcitylistall(selectedCountry.value);
        setBankOptions([]);
        setBranchOptions([]);
      };
      useEffect(() => {
        if (accountdetails.country) {
          getbankcitylistall();
        }
      }, [accountdetails.country]);
      useEffect(() => {
        if (accountdetails.city && accountdetails.country) {
          getbanklistall();
        }
      }, [accountdetails.city])
      useEffect(() => {
        if (accountdetails.city && accountdetails.bank && accountdetails.country) {
          bankbranchall();
        }
      }, [accountdetails.bank])
      const handleCityChange = (selectedCity) => {
        setAccountdetails((prev)=>({...prev,city:selectedCity.value,branch:null,bank:null,accountholdername:'',accountholdernumber:'',phonenumber:'',mcc_id:'',country_code:''}))
        // getbanklistall(selectedCity.value);
        setBranchOptions([]);
      };
      const handleBankChange = (selectedBank) => {
        setAccountdetails((prev)=>({...prev,bank:selectedBank.value,branch:null,accountholdername:'',accountholdernumber:'',phonenumber:'',mcc_id:'',country_code:''}));
        // bankbranchall(selectedBank.value);
      };
      const handleBranchChange = (selectedBranch) => {
        // setaccountdetails({ ...accountdetails, branch: selectedBranch.value });
        setAccountdetails((prev)=>({...prev,branch:selectedBranch.value,accountholdername:'',accountholdernumber:'',phonenumber:'',mcc_id:'',country_code:''}));
      };
    const handleClose1 = () => {
        setMapvalues([]);
         handleClose();
    }
    const handleClose2 = () => {
        setShow1(false);
    }
    const check = useCallback(() => {
        const valid = (
          accountdetails.country !== '' && accountdetails.country !== null && accountdetails.bank !== '' && accountdetails.bank !== null && accountdetails.city !== '' && accountdetails.city !== null && accountdetails.branch !== '' && accountdetails.branch !== null 
        );
        setEnabled(valid);
      },[accountdetails.country,accountdetails.bank,accountdetails.city,accountdetails.branch]);
      useEffect(()=>{
        check();
      },[check]);
    const save = () => {
       handleClose();
       setShow1(true);
    }
  return (
    <>
    <Modal show={show} dialogClassName="card-example-dialog" contentClassName="card-example-content" onHide={handleClose1} centered>
        <Modal.Body className="card-example-dialog-body">
          <div className="text-end">
            <img src={closeicon} onClick={handleClose1} style={{ cursor: 'pointer' }} className="closeicon mx-1 mt-2" alt="closeicon"></img>
          </div>
          <div className="card-example-dialog-bodypadd">
            <h5 className="addnewh5">Add Bank Details</h5>
            <div className="mt-3">
            <label htmlFor="country" className="cardlabel">Country<span className="docpopstar">*</span></label>
            <Select options={countryOptions} inputId='country' isLoading={countryLoading} placeholder='Select Country' styles={customStyles3(Boolean(accountdetails.country))}  value={countryOptions.find(option => option.value === accountdetails.country) || null} onChange={handleCountryChange}/>
            </div>
            <div className="mt-2">
            <label htmlFor="CityName" className="cardlabel">City Name<span className="docpopstar">*</span></label>
            <Select options={cityOptions} inputId='CityName' isLoading={cityLoading} placeholder='Select City' styles={customStyles3(Boolean(accountdetails.city))} value={cityOptions.find(option => option.value === accountdetails.city) || null} onChange={handleCityChange} isDisabled={!accountdetails.country} />
            </div>
            <div className="mt-2">
            <label htmlFor="BankName" className="cardlabel">Bank Name<span className="docpopstar">*</span></label>
            <Select options={bankOptions} inputId='BankName' isLoading={bankLoading} placeholder='Select Bank'  styles={customStyles3(Boolean(accountdetails.bank))} value={bankOptions.find(option => option.value === accountdetails.bank) || null} onChange={handleBankChange} isDisabled={!accountdetails.city}  />
            </div>
            <div className="mt-2">
            <label htmlFor="BranchName" className="cardlabel">Branch Name<span className="docpopstar">*</span></label>
            <Select options={branchOptions} inputId='BranchName' isLoading={branchLoading} placeholder='Select Branch' styles={customStyles3(Boolean(accountdetails.branch))} value={branchOptions.find(option => option.value === accountdetails.branch) || null} onChange={handleBranchChange} isDisabled={!accountdetails.bank}/>
            </div>
        <div className='mt-4'>
          <button  className={`modal_save_card ${enabled ? 'modal_save_card_active' : 'modal_save_card_disabled'}`} disabled={!enabled} onClick={save}>Next</button>
        </div>
          </div>
        </Modal.Body>
    </Modal>
    <Editaccountdetailmodal show={show1} accountdetails={accountdetails} setMapvalues={setMapvalues} setAccountdetails={setAccountdetails} handleClose={handleClose2} getbanksall={getbanksall} setIsLoading4={setIsLoading4} showErrorToast={showErrorToast}/>
    </>
  )
})

export default Editbankdetailmodal;