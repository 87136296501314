import React, { memo,useEffect,useState } from 'react';
import Editbankdetailmodal from './editbankdetailmodal';
const Bankeditcomponents = memo(({ show,mapvalues,setMapvalues,handleClose,getbanksall, setIsLoading4, showErrorToast})=> {
    const [accountdetails,setAccountdetails] = useState({accountholdername:'',accountholdernumber:'',phonenumber:'',countryOptions:[], dial_code:'',mcc_id: null,country_code:'',isLoading:false,country: null, bank: null, city: null, branch: null,id:''}); 
    useEffect(()=>{
      if(mapvalues){
        setAccountdetails((prev)=>({
            ...prev,
            accountholdername:mapvalues.account_holdername || '',
            accountholdernumber:mapvalues.account_number || '',
            phonenumber:mapvalues.phone_number || '',
            country_code:mapvalues.country_code || '',
            country:mapvalues.country_id || '',
            city:mapvalues.city_id || '',
            bank:mapvalues.bank_id || '',
            branch:mapvalues.branch_id || '',
            id:mapvalues.id || ''
        }))
      }
    },[mapvalues]); 
  return (
    <>
     <Editbankdetailmodal show={show} accountdetails={accountdetails} setMapvalues={setMapvalues} setAccountdetails={setAccountdetails} handleClose={handleClose} getbanksall={getbanksall} setIsLoading4={setIsLoading4} showErrorToast={showErrorToast}  />
    </>
  )
})

export default Bankeditcomponents;