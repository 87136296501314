import React, { memo, useState, useCallback, useEffect,useRef } from 'react';
import closeicon from '../../images/times-circle-01.png';
import { Modal } from 'react-bootstrap';
import { handleApiError, handleKeyPress, validateAndSetMobileChange } from '../../api/utils/utils';
import axiosInstance from '../../api/utils/axiosutils';
import {updatemobilemoneysapi, verifymobilemoneysapi } from '../../api/helper/urlhelper';
import { useCountryData } from '../../api/hook/mobilehook';
import PhoneInputWithCountrySelector from '../../components/phoneinputcountryselector';
import Closeicon from '../../components/closeicon';
import ReusableModal from '../../components/reusablemodal';
const updatemobilemoneysapiurl = updatemobilemoneysapi();
const verifymobilemoneysapiurl = verifymobilemoneysapi();
const Mobilemoneyedit = memo(({show,getmobilemoneyall,setIsLoading4,handleclose,showErrorToast,mapvalues})=> {
  const [error422,setError422] = useState('');
  const [otpshows,setOtpshows] = useState(true);
  const [enabled,setEnabled] = useState(false);
  const [countrychange,setCounrychange] = useState(true);
  const [otpenabled,setOtpEnabled] = useState(false);
  const {
    mccId, dialCode, countryCode, countryOptions,mobile_length,mobile_starting_digits,isLoading,setMccId,setDialCode,setCountryCode,setMobile_length,setMobile_starting_digits,
    loadCountryBySelection
  } = useCountryData();
  const [mobileChange, setMobileChange] = useState('');
  const [numberinvalid,setNumberinvalid] = useState(false);
  const [formValues,setFormValues] = useState({mobileerror:'',otp:'',id:''});
  useEffect(()=>{
    if(mapvalues){
      setFormValues((prev)=>({
        ...prev,
        // phonenumber:mapvalues.mobile_number || '',
        // mcc_id:mapvalues.mobile_mcc_id || '',
        // country_code:mapvalues.country_code || '',
        id:mapvalues.id || ''
      }));
      setMobileChange(mapvalues.mobile_number || '');
      setMccId(mapvalues.mobile_mcc_id || '');
      setCountryCode(mapvalues.country_code || '');
    }
  },[mapvalues]);
  useEffect(()=>{
    if(mobileChange && mccId && countryCode && mobile_length && mobile_starting_digits ){
    validateAndSetMobileChange(mobileChange, {
      setMobileChange: setMobileChange,
      setError: (errorMsg) => setFormValues((prev) => ({ ...prev, mobileerror: errorMsg })),
      setNumberInvalid: setNumberinvalid,
      mobile_length: mobile_length,             
      mobile_starting_digits: mobile_starting_digits 
    });
  }
  },[mobileChange,mccId,countryCode,mobile_starting_digits,mobile_length]);
  const ip = useCallback(()=>{
     if(show && countryCode && countrychange){
      loadCountryBySelection(countryCode);
     }
  },[show,countryCode , countrychange]);
useEffect(() => {
     if(show){
      ip();
     }
}, [show,ip]);
  const handleClose1 = () => {
    handleclose();
    setFormValues((prev)=>({
        ...prev,otp:'',id:'',mobileerror:''
    }));
    setMobileChange('');
    setMccId('');
    setCountryCode('');
    setDialCode('');
    setOtpshows(true);
    setCounrychange(true);
    setError422('');
  }  
  const handleotpchange = (value) => {
    setFormValues((prev) => ({
      ...prev, otp: value
    }));
    setError422('');
  }
  const handlePhoneNumberChange = (value) => {
    validateAndSetMobileChange(value, {
      setMobileChange: setMobileChange,
      setError: (errorMsg) => setFormValues((prev) => ({ ...prev, mobileerror: errorMsg })),
      setNumberInvalid: setNumberinvalid,
      mobile_length: mobile_length,             
      mobile_starting_digits: mobile_starting_digits 
    });
    setError422('');
  };
  const handlePaste = (e) => {
      const pastedText = e.clipboardData.getData('text');
      let numericPastedText = pastedText.replace(/[^0-9]/g, '');
      const dialCodeWithoutPlus = dialCode.replace('+', '');
      if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
          numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
      }
      validateAndSetMobileChange(numericPastedText, {
        setMobileChange: setMobileChange,
        setError: (errorMsg) => setFormValues((prev) => ({ ...prev, mobileerror: errorMsg })),
        setNumberInvalid: setNumberinvalid,
        mobile_length: mobile_length,             
        mobile_starting_digits: mobile_starting_digits 
      });
      e.preventDefault();
      setError422('');
  };
  const handleCountryChange = (selectedOption) => {
      const selectedCountryData = countryOptions.find(
          (country) => country.value === selectedOption.value
      );
      if (selectedCountryData) {
        const { mcc_id, dial_code, country_code, mobile_length, mobile_starting_digits } = selectedCountryData;
    
        setMccId(mcc_id);
        setDialCode(`+${dial_code}`);
        setCountryCode(country_code);
        setMobile_length(mobile_length);
        setMobile_starting_digits(mobile_starting_digits);
        validateAndSetMobileChange(mobileChange, {
          setMobileChange,
          setError: (errorMsg) => setFormValues((prev) => ({ ...prev, mobileerror: errorMsg })),
          setNumberInvalid: setNumberinvalid,
          mobile_length,
          mobile_starting_digits,
        });
      }
      setCounrychange(false);
      setError422('');
  };
  const check = useCallback(()=>{
    const valid = (
    mobileChange !== '' && numberinvalid === true
    )
    setEnabled(valid);
  },[mobileChange,numberinvalid]);
  useEffect(()=>{
    check();
  },[check]);
  const check1 = useCallback(()=>{
    const valid = (
    formValues.otp !== ''
    )
    setOtpEnabled(valid);
  },[formValues.otp]);
  useEffect(()=>{
    check1();
  },[check1]);

  const save = async() => {
    const data = {
        mobile_money_detail_id : formValues.id,
        mobile_mcc_id : mccId,
        mobile_number : mobileChange
    }
    try{
       setIsLoading4(true);
       const response = await axiosInstance.post(updatemobilemoneysapiurl,data);
       if(response.status === 200){
         setFormValues((prev)=>({
            ...prev,id:response.data.data.id
         }))
         setOtpshows(false);
         setIsLoading4(false);
       }
    }catch(error){
        handleApiError(error, setIsLoading4, setError422, false, showErrorToast)
    }
  }
  const otpsave = async() => {
    const data = {
        mobile_money_detail_id : formValues.id,
        mobile_otp : formValues.otp
    }
    try{
       setIsLoading4(true);
       const response = await axiosInstance.post(verifymobilemoneysapiurl,data);
       if(response.status === 200){
        handleClose1();
        await getmobilemoneyall();
        setIsLoading4(false);
       }
    }catch(error){
        handleApiError(error, setIsLoading4, setError422, false, showErrorToast)
    }
  }
  return (
    <>
    <ReusableModal show={show} handleClose={handleClose1} dialogClassName='card-example-dialog' contentClassName="card-example-content" >
    <div className="text-end">
           <Closeicon className='closeicon mx-1 mt-2' onClick={handleClose1}/>
          </div>
          <div className="card-example-dialog-bodypadd">
            <h5 className="addnewh5">Add Mobile Money</h5>
            <div className="mt-3">
            <label htmlFor="mobilenumber" className="cardlabel">Mobile Number</label>
            <div className='position-relative'>
            <PhoneInputWithCountrySelector  countryOptions={countryOptions} handleCountryChange={handleCountryChange} dialCode={dialCode} mobileChange={mobileChange} handlePhoneNumberChange={handlePhoneNumberChange}  handlePaste={handlePaste} countryCode={countryCode} minheight='40px' walletprofileinput = 'cardinputbox' walletprofileinputactive='cardinputbox_active' isLoading={isLoading}/>
            </div>
            {formValues.mobileerror && <p className="error-message">{formValues.mobileerror}</p>}
            </div>
            {otpshows ? (
            <>    
            {error422 && <p className="error-message">{error422}</p>}
        <div className='mt-4'>
          <button className={`modal_save_card ${enabled ? 'modal_save_card_active' : 'modal_save_card_disabled'}`} disabled={!enabled} onClick={save}>Submit & Save</button>
        </div>
        </>
            ) : (
           <>
            <div className='mt-2'>
            <label htmlFor="otpcode" className="cardlabel">OTP</label>
            <input type="text" id="otpcode" maxLength={6} onKeyPress={handleKeyPress} placeholder='Enter OTP'  className={`form-control cardinputbox ${formValues.otp ? 'cardinputbox_active' : ''}`} value={formValues.otp}  name="otp"  onChange={(e) => handleotpchange(e.target.value)} />
            </div>
            {error422 && <p className="error-message">{error422}</p>}
        <div className='mt-4'>
          <button className={`modal_save_card ${otpenabled ? 'modal_save_card_active' : 'modal_save_card_disabled'}`} disabled={!otpenabled} onClick={otpsave}>Submit & Save</button>
        </div>
           </>
            )}
          </div>
    </ReusableModal>
    </>
  )
})

export default Mobilemoneyedit;