import React, { useCallback, useEffect, useState,memo } from 'react';
import WithToast from '../../api/hook/withtoasthook';
import { getkycemailmobileapi, kycsendotpapi , updatekycemailmobileapi} from '../../api/helper/urlhelper';
import axiosInstance from '../../api/utils/axiosutils';
import { allfunctions, handleApiError,useRouteParams, validateAndSetMobileChange } from '../../api/utils/utils';
import Otpmodal from './otpmodal';
import checkcircle from '../../images/check-circle-01.png';
import { useNavigate } from 'react-router-dom';
import { useCountryData } from '../../api/hook/mobilehook';
import PhoneInputWithCountrySelector from '../../components/phoneinputcountryselector';
const getkycemailmobileapiurl = getkycemailmobileapi();
const kycsendotpapiurl = kycsendotpapi();
const updatekycemailmobileapiurl = updatekycemailmobileapi();
const Emailphone = memo(({ setActiveStep, activeStep, setIsLoading4, showErrorToast }) => {
  const navigate = useNavigate();
  const params = useRouteParams();
  const [emailphone1,setEmailphone1] = useState([]);
  const {
    mccId, dialCode, countryCode, countryOptions,mobile_length,mobile_starting_digits,isLoading,setMccId,setDialCode,setCountryCode,setMobile_length,setMobile_starting_digits,
    loadCountryBySelection,
  } = useCountryData();
  
  const [mobileChange, setMobileChange] = useState('');
  const [numberinvalid,setNumberinvalid] = useState(false);
  const [formValues, setFormValues] = useState({
    responseemail: '',
    oldresponseemail: '',
    oldresponsephoneNumber: '',
    response_is_email_verified: 0,
    response_is_mobile_verified: 0,
    kyc_id: '',
    selectOption: '',
    oldmcc_id:'',
    mobileerror: '',
    emailerror: '',
    isLoading: false,
    showemailget: false,
    showmobileget: false
  });
  const [countryvalue,setCountryvalue] = useState(true);
  const [initialloading,setInitialloading] = useState(true);
  const [error422, setError422] = useState('');
  const [show, setShow] = useState(false);
  useEffect(()=>{
    if(initialloading && mobileChange && mccId && countryCode && mobile_length && mobile_starting_digits ){
    validateAndSetMobileChange(mobileChange, {
      setMobileChange: setMobileChange,
      setError: (errorMsg) => setFormValues((prev) => ({ ...prev, mobileerror: errorMsg })),
      setNumberInvalid: setNumberinvalid,
      mobile_length: mobile_length,             
      mobile_starting_digits: mobile_starting_digits 
    });
  }
  },[mobileChange,mccId,countryCode,mobile_starting_digits,mobile_length,initialloading]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    if(name === 'responseemail'){
        setFormValues((prev)=>({
            ...prev,
            emailerror:''
        }));
        setError422('');
    }
    if(name === 'responsephoneNumber'){
        setFormValues((prev)=>({
            ...prev,
            mobileerror:''
        }));
        setError422('');
    }
    setError422('');
  };
  useEffect(()=>{
  if(!initialloading && countryvalue){
    loadCountryBySelection(countryCode);
  }
  },[countryCode,initialloading,countryvalue]);

  const handlePhoneNumberChange = (value) => {
    validateAndSetMobileChange(value, {
      setMobileChange: setMobileChange,
      setError: (errorMsg) => setFormValues((prev) => ({ ...prev, mobileerror: errorMsg })),
      setNumberInvalid: setNumberinvalid,
      mobile_length: mobile_length,             
      mobile_starting_digits: mobile_starting_digits 
    });
    setError422('');
  };
  const handlePaste = (e) => {
      const pastedText = e.clipboardData.getData('text');
      let numericPastedText = pastedText.replace(/[^0-9]/g, '');
      const dialCodeWithoutPlus = dialCode.replace('+', '');
      if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
          numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
      }
      validateAndSetMobileChange(numericPastedText, {
        setMobileChange: setMobileChange,
        setError: (errorMsg) => setFormValues((prev) => ({ ...prev, mobileerror: errorMsg })),
        setNumberInvalid: setNumberinvalid,
        mobile_length: mobile_length,             
        mobile_starting_digits: mobile_starting_digits 
      });
      setError422('');
      e.preventDefault();
  };
  const handleCountryChange = (selectedOption) => {
      const selectedCountryData = countryOptions.find(
          (country) => country.value === selectedOption.value
      );
      if (selectedCountryData) {
        const { mcc_id, dial_code, country_code, mobile_length, mobile_starting_digits } = selectedCountryData;
    
        setMccId(mcc_id);
        setDialCode(`+${dial_code}`);
        setCountryCode(country_code);
        setMobile_length(mobile_length);
        setMobile_starting_digits(mobile_starting_digits);
        validateAndSetMobileChange(mobileChange, {
          setMobileChange,
          setError: (errorMsg) => setFormValues((prev) => ({ ...prev, mobileerror: errorMsg })),
          setNumberInvalid: setNumberinvalid,
          mobile_length,
          mobile_starting_digits,
        });
      }
      setCountryvalue(false);
      setError422('');
  };
  
  const getkycemailmobile = useCallback(async () => {
    try {
      const response = await axiosInstance.get(getkycemailmobileapiurl);
      if (response.status === 200) {
        const emailphone = response.data.data;
        setEmailphone1(emailphone);
      }
    } catch (error) {
      if(error.response){
        if(error.response.status === 422){
          const code = error.response.data.error_code;
          if(code === 'Kyc not available'){
            navigate(`/u/${params}`);
          }else{
            handleApiError(error, null, null, true, showErrorToast);
          }
        }
      }
    }
  }, [showErrorToast]);
  useEffect(()=>{
    if (Array.isArray(emailphone1) && emailphone1.length === 0) {
      return;
    }
    if (emailphone1 !== '') {
    setFormValues(prev => ({
        ...prev,
        responseemail: emailphone1?.email || '',
        oldmcc_id:emailphone1?.mcc_id || '',
        response_is_mobile_verified: emailphone1?.is_mobile_verified || 0,
        response_is_email_verified: emailphone1?.is_email_verified || 0,
        kyc_id: emailphone1?.kyc_id || '',
        oldresponseemail: emailphone1?.is_email_verified ? emailphone1?.email : '',
        oldresponsephoneNumber: emailphone1?.is_mobile_verified ? emailphone1?.mobile : '',
      }));
      setMobileChange(emailphone1?.mobile || '');
      setMccId(emailphone1?.mcc_id || '');
      setCountryCode(emailphone1?.country_code || '');
      setDialCode(emailphone1?.code || '');
    }
  },[emailphone1]);
  useEffect(()=>{
    allfunctions([getkycemailmobile])
    .finally(() => setInitialloading(false));;
  }, [allfunctions]);
  const checkVerificationStatus = useCallback(() => {
    setFormValues((prev) => ({
      ...prev,
      showemailget: prev.responseemail === prev.oldresponseemail,
      showmobileget: mobileChange === prev.oldresponsephoneNumber && mccId === prev.oldmcc_id,
    }));
  }, [formValues.responseemail,mobileChange,formValues.oldresponseemail,formValues.oldresponsephoneNumber,mccId,formValues.oldmcc_id]);

  useEffect(() => {
    checkVerificationStatus();
  }, [checkVerificationStatus]);

  const isNextButtonActive = () => {
    return (
      formValues.response_is_mobile_verified === 1 &&
      formValues.response_is_email_verified === 1 &&
      formValues.showemailget &&
      formValues.showmobileget
    );
  };

  const handleNext = async () => {
    const data = {
      kyc_id : formValues.kyc_id
    }
    try{
      setIsLoading4(true);
      const response = await axiosInstance.post(updatekycemailmobileapiurl,data);
      if(response.status === 200){
        setError422('');
        const nextStep = activeStep + 1;
        setActiveStep(nextStep);
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        localStorage.setItem('activeStep', nextStep);
        setMobileChange('');
        setCountryCode('');
        setMccId('');
        setDialCode('');
        setMobile_length('');
        setMobile_starting_digits('');
        setIsLoading4(false);
      }
    }catch(error){
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
  };

  const handleBack = async () => {
    const nextStep = activeStep - 1;
        setActiveStep(nextStep);
        localStorage.setItem('activeStep', nextStep);
        setMobileChange('');
        setCountryCode('');
        setMccId('');
        setDialCode('');
        setMobile_length('');
        setMobile_starting_digits('');
  };

  const getOtpEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formValues.responseemail)) {
      setFormValues(prevState => ({
        ...prevState,
        emailerror: 'Please enter a valid email address.'
      }));
      return;
    }
    setFormValues(prev => ({
      ...prev, selectOption: 'email'
    }));
    const data = {
      email: formValues.responseemail,
      kyc_id: formValues.kyc_id,
      data_mode: 'email'
    };
    sendOtp(data);
  };

  const getOtpMobile = () => {
    if(numberinvalid === false) return
    setFormValues(prev => ({
      ...prev, selectOption: 'mobile'
    }));
    const data = {
      data_mode: 'mobile',
      mobile: mobileChange,
      mcc_id: mccId,
      kyc_id: formValues.kyc_id
    };
    sendOtp(data);
  };

  const sendOtp = async (data) => {
    try {
      setIsLoading4(true);
      const response = await axiosInstance.post(kycsendotpapiurl, data);
      if (response.status === 200) {
        setIsLoading4(false);
        setShow(true);
      }
    } catch (error) {
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
  };

  const handleClose = () => {
    setShow(false);
    // getkycemailmobile();
  };


  return (
    <>
      <div className="wallet-profilepad">
        <div>
          <label className="walletprofile-label">Email<span className="docpopstar">*</span></label>
          <div style={{ position: 'relative' }}>
            <input type="email" name="responseemail" value={formValues.responseemail} onChange={handleInputChange} className={`form-control mt-1 walletprofile-input ${formValues.responseemail ? 'walletprofile-input-active' : ''}`} placeholder="Enter your email address"/>
            {formValues.response_is_email_verified && formValues.showemailget ? (
              <p style={{ position: 'absolute', right: '10px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer', marginBottom: '0px' }}>
                <img src={checkcircle} alt='check' width='20px' height='auto' />
              </p>
            ): null}
          </div>
          {formValues.emailerror && <p className="error-message">{formValues.emailerror}</p>}
          {(formValues.response_is_email_verified === 0 && formValues.responseemail) || (!formValues.showemailget && formValues.responseemail) ? (
            <div className='d-flex justify-content-end'>
              <p className="wallet-pofileotpp" onClick={getOtpEmail}>Get OTP</p>
            </div>
          ) : null}
        </div>
        <div className={`${formValues.responseemail ? 'mt-1' : 'mt-3'}`}>
          <label className="walletprofile-label">Mobile Number<span className="docpopstar">*</span></label>
              <div style={{ position: 'relative' }}>
          <PhoneInputWithCountrySelector  countryOptions={countryOptions} handleCountryChange={handleCountryChange} dialCode={dialCode} mobileChange={mobileChange} handlePhoneNumberChange={handlePhoneNumberChange}  handlePaste={handlePaste} countryCode={countryCode} minheight='45px' walletprofileinput = 'walletprofile-input' walletprofileinputactive='walletprofile-input-active' isLoading={isLoading}/>
          {formValues.response_is_mobile_verified && formValues.showmobileget ? (
              <p style={{ position: 'absolute', right: '10px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer', marginBottom: '0px' }}>
                <img src={checkcircle} alt='check' width='20px' height='auto' />
              </p>
            ) : null}
            </div>
          {formValues.mobileerror && <p className="error-message">{formValues.mobileerror}</p>}
          {(formValues.response_is_mobile_verified === 0 && mobileChange) || (!formValues.showmobileget && mobileChange) ? (
            <div className='d-flex justify-content-end'>
              <p className="wallet-pofileotpp" onClick={getOtpMobile}>Get OTP</p>
            </div>
          ) : null}
        </div>
        <hr className="walletprofile-mar" />
        {error422 && <p className="error-message">{error422}</p>}
        <div className="walletprofile-buttons mt-3">
          <button
            className={`walletprofile-next me-3 ${isNextButtonActive() ? 'walletprofile-next-active' : 'walletprofile-next-disabled'}`}
            onClick={handleNext}
            disabled={!isNextButtonActive()}
          >
            Next
          </button>
          <button
            className="walletprofile-cancel"
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Cancel
          </button>
        </div>
      </div>
      <Otpmodal
        show={show}
        selectedOption={formValues.selectOption}
        kyc_id={formValues.kyc_id}
        responseemail={formValues.responseemail}
        responsephoneNumber={mobileChange}
        responsecountrycode={dialCode}
        handleClose={handleClose}
        getkycemailmobile={getkycemailmobile}
        setIsLoading4={setIsLoading4}
      />
    </>
  );
})

export default WithToast(Emailphone);
