import React, { useCallback, useEffect, useState ,useRef} from 'react';
import ReusableModal from '../components/reusablemodal';
import Profileimage from '../components/profileimage';
import qrcode from "../images/Rectangle 1212.svg";
import Copybutton from '../components/copybutton';
import sharee from "../images/Icons (1)/share-01.png";
import { addwalletcustomer, getwalletdetails } from '../api/helper/apiurlhelper';
import { allfunctions, handleApiError } from '../api/utils/utils';
import { QRCodeCanvas } from 'qrcode.react';
function Directdepositmodal({show,handleClose,contentClassName = '',dialogClassName = '',BodyClassname = '',showErrorToast}) {
  const [walletdetails,setWalletdetails] = useState([]);
  const qrCodeRef = useRef();
  const getwallet_detail = useCallback(() => {
     getwalletdetails().then(res=> setWalletdetails(res.data.data))
     .catch(error=>handleApiError(error, null, null, true, showErrorToast))
  },[showErrorToast]);
  useEffect(()=>{
    if(show){
    if (typeof allfunctions === 'function') {
      allfunctions([getwallet_detail]);
    }
    addwalletcustomer();
  }
  },[getwallet_detail,show]);
  console.log(walletdetails);
  
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('Copied to clipboard!');
      })
      .catch(() => {
        alert('Failed to copy!');
      });
  };
  const qrData = JSON.stringify({
    name: walletdetails[0]?.name || '',
    email: walletdetails[0]?.email || '',
    wallet_id: walletdetails[0]?.wallet_id || '',
    wallet_url: walletdetails[0]?.wallet_url || '',
  });
 

  const handleShareQR = async () => {
    try {
      const qrData1 = walletdetails[0]?.wallet_url || 'https://default-url.com';
      const canvas = qrCodeRef.current.querySelector('canvas');
      if (!canvas) throw new Error('QR Code canvas not found.');
  
      // Create a new canvas with custom styling
      const styledCanvas = document.createElement('canvas');
      const ctx = styledCanvas.getContext('2d');
  
      // Set dimensions larger than the QR code for styling
      const padding = 20;
      styledCanvas.width = canvas.width + padding * 2;
      styledCanvas.height = canvas.height + padding * 2;
  
      // Draw background with rounded corners
      ctx.fillStyle = '#f0f0f0'; // Light gray background
      ctx.fillRect(0, 0, styledCanvas.width, styledCanvas.height);
      ctx.fillStyle = '#ffffff';
      ctx.fillRect(padding, padding, canvas.width, canvas.height);
  
      // Add the QR code
      ctx.drawImage(canvas, padding, padding);
  
    
  
      // Convert the styled canvas to PNG
      const pngImage = styledCanvas.toDataURL('image/png');
  
      // Check Clipboard API support
      if (!navigator.clipboard || !window.ClipboardItem) {
        alert('Clipboard API not supported in this browser.');
        return;
      }
  
      // Create a ClipboardItem for the PNG image and Wallet URL
      const imageBlob = await fetch(pngImage).then((res) => res.blob());
      const textBlob = new Blob([`Wallet URL: ${qrData1}`], { type: 'text/plain' });
  
      await navigator.clipboard.write([
        new ClipboardItem({
          'image/png': imageBlob,
          'text/plain': textBlob,
        }),
      ]);
  
      alert('QR Code and Wallet URL copied to clipboard!');
    } catch (error) {
      console.error('Error copying QR code and wallet URL:', error);
      alert('Failed to copy QR code and Wallet URL.');
    }
  };
  
  
  
  
  

  return (
    <ReusableModal show={show} handleClose={handleClose} dialogClassName={dialogClassName}  contentClassName={contentClassName} BodyClassname={BodyClassname}>
       <div className='row me-0 ms-0 mt-0'>
        <div className="col-6 deposit_fund_modal deposit_fund_modal_right_border">
          <Profileimage containerClass="modal_user_div" imageSrc="https://next-images.123rf.com/index/_next/image/?url=https://assets-cdn.123rf.com/index/static/assets/top-section-bg.jpeg&w=3840&q=75"  imgClass="modal_user_profile" />
          <h6 className='modal_user_h6 mt-2 mb-0'>{walletdetails[0]?.name}</h6>
          <p className='modal_user_p'>{walletdetails[0]?.email}</p>
          <div className='copyinput'><p className='mb-0 copyinputp'>{walletdetails[0]?.wallet_id}</p><Copybutton onClick={()=>handleCopy(qrcode)} title='Copy' copybuttons='copybuttons' /></div>
          <div className='copyinput mt-4'><p className='mb-0 copyinputp'>{walletdetails[0]?.wallet_url}</p><Copybutton title='Copy' copybuttons='copybuttons' /></div>
        </div>
        <div className='col-6 deposit_fund_modal deposit_fund_modal_right_border_radius' ref={qrCodeRef}>
        {/* <img src={qrcode} className="qrcode" alt="qrcode"></img> */}
        <QRCodeCanvas value={walletdetails[0]?.wallet_url} size={150} />
        <button className="shareqrbutton mt-3" onClick={handleShareQR}>
        <img className="me-3" src={sharee} alt="share" width="14px" height="14px"></img>Share QR</button>
        </div>
       </div>
    </ReusableModal>
  )
}

export default Directdepositmodal;