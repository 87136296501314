import React from 'react';
import { formatFileSize, getPartAfterKeyword, handleDragOver, handleDrop, handleFileChange, transformString } from '../api/utils/utils';
import popdeletes from '../images/info_icon/delete-01.png';
import fileopen from '../images/folder-open-01.png';
import eye from '../images/eye_green-01.png';
function Fileuploader({
    handleFrontview = () => {},
    docname,
    acceptPDF,
    fileInputRef,setImage,format,fileSize,
    image,progress,progressColor,
    uploading, setFileSize,
    setProgress,
    setProgressColor,
    setUploading,
    setFormat,
    setDocname,
    setImageerror,
    setError422,
    Acceptformat,
    National
}) {
    const handleFileClick = () => {
        fileInputRef.current.click();
        setError422('');
    }
    const handleFileDrop = (selectedFile) => {
        handleFileChange(
          { target: { files: [selectedFile] } },
          setImage,
          setFileSize,
          setProgress,
          setProgressColor,
          setUploading,
          setFormat,
          setDocname,
          setImageerror,
          Acceptformat
    )}      
    const handleimagedelete = () => {
        setUploading(false);
        setProgress(0);
        setImage('');
    }
    const handleError = (message) => {
        setImageerror(message)
    } 
  return (
    <>
     {uploading ? (
            <div className="popprogressbox">
            <div className="d-flex align-items-center ">
              <div className="d-flex align-items-center mb-2" style={{width:'75%'}}>
                <img src={image} alt="file" className="popuploadfileimg me-2"></img>
                <div style={{overflow:'hidden'}}>
                {National ? (  
                <p className="popfilefrontp">{`${transformString(docname)}.${transformString(format)}`}</p>
                ) : (
                <p className="popfilefrontp">{(typeof image === 'string' && (image.startsWith('data:') || image.startsWith('data'))) ? `${transformString(docname)}.${transformString(format)}` : getPartAfterKeyword(image, 'national-documents/')}</p>
                )}
                {fileSize && fileSize >= 1024 && (
                  <p className="popfilekb">{formatFileSize(fileSize)}</p>
                )}
                </div>
              </div>
              <div style={{textAlign:'end',width:'25%'}}>
                {progress === 100 && (
                  <img src={eye} alt="eye" onClick={()=>handleFrontview(image)} className="popupeye me-2"></img>
                )}
                <img src={popdeletes} onClick={handleimagedelete} alt="delete" className="popupeye" ></img>
              </div>
            </div>
            <div className="progress mt-1 mb-1">
              <div className="progress-bar" role="progressbar" style={{ width: `${progress}%`, backgroundColor: progressColor }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
                {``}
              </div>
            </div>
          </div>
          ):(
            <div className="popinputbox mt-3"onClick={handleFileClick} onDrop={(e) => handleDrop(e, handleFileDrop,handleError,acceptPDF,)} onDragOver={handleDragOver} >
            <div className="d-flex align-items-center justify-content-center flex-column p-2">
              <img src={fileopen} className="popuploadflle" alt="file" width='48px' height='auto'></img>
              <h5 className="popuploadh5"><span className="popspan">Click to Upload</span>or drag and drop</h5>
              <p className="popuploadp">File format: .png, .jpeg, .jpg</p>
              <p className="popuploadp">( Max. File size : 20 MB)</p>
            </div>
            <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept=".jpg, .jpeg, .png" onChange={(e) => handleFileChange(e,setImage, setFileSize, setProgress, setProgressColor,setUploading,setFormat,setDocname,setImageerror,Acceptformat)} />
          </div>
          )}
    </>
  )
}

export default Fileuploader;