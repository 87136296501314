import React, { useCallback, useEffect,useState,memo } from 'react'
import { publicDomain,getemergencyrelationlistapi, updateemergencycontactsapi } from '../../api/helper/urlhelper';
import axiosInstance from '../../api/utils/axiosutils';
import WithToast from '../../api/hook/withtoasthook';
import Select from 'react-select';
import { handleApiError ,customStyles1, validateAndSetMobileChange} from '../../api/utils/utils';
import tick from "../../images/user-circle.png";
import ProfileImage from '../../components/profileimage';
import { useCountryData } from '../../api/hook/mobilehook';
import PhoneInputWithCountrySelector from '../../components/phoneinputcountryselector';
const publicDomainurl = publicDomain();
const getemergencyrelationlistapiurl = getemergencyrelationlistapi();
const updateemergencycontactsapiurl = updateemergencycontactsapi();
const Emergency = memo(({ setActiveStep, activeStep, setIsLoading4, showErrorToast,getemergency,getemergencydocid,name,setShowAddNew})=> {
    const [formValues, setFormValues] = useState({
      image: '',
      name: '',
      email:'',
      relation:null,
      relationoption:[],
      isnextofkin:false,
      responsephoneNumber:'',
      dial_code:'',
      selectOption: '',
      countryOptions: [],
      mcc_id: null,
      isLoading: false,
      isrelationloading:false,
      gender: '',
      dob: '',
      changeimage: false,
      id: getemergencydocid,
      Selectimage:null,
      isPreviewVisible1:true
    });
    const {
      mccId, dialCode, countryCode, countryOptions,mobile_length,mobile_starting_digits,isLoading,setMccId,setDialCode,setCountryCode,setMobile_length,setMobile_starting_digits,
      loadCountryByIp
    } = useCountryData();
    const [mobileChange, setMobileChange] = useState('');
    const [numberinvalid,setNumberinvalid] = useState(false);
    useEffect(()=>{
      setFormValues((prev)=>({
        ...prev,id:getemergencydocid
      }));      
    },[getemergencydocid]);
    const [isenabled,setIsenbled] = useState(false);
    const [error422,setError422] = useState('');
    const [formValuesError, setFormValuesError] = useState({ imageError: '', nameError: '', genderError: '', dobError: '',mobileerror:'',emailerror:'' });
    const getrelationlist = useCallback(async()=>{
       try{
          const response = await axiosInstance.get(getemergencyrelationlistapiurl);
          if(response.status === 200){
             const relationlist = response.data.data;
             setFormValues((prev)=>({
              ...prev,relationoption:relationlist.map(relat => ({value:relat.id,label:relat.name}))
             }))
          }
       }catch(error){
        handleApiError(error, null, null, true, showErrorToast);
       }
    },[showErrorToast]);
    useEffect(()=>{
      if(name === 'stalin'){
      getrelationlist();
      }
    },[getrelationlist,name]);
    const handleImageUpload = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFormValues((prev) => ({
            ...prev,
            image: reader.result,
            changeimage: true
          }));
        };
        reader.readAsDataURL(file);
      }
    };
      useEffect(() => {
        if(name === 'stalin'){
        loadCountryByIp(true);
        }
      }, [name]);
    const triggerFileInput = () => {
      document.getElementById('fileInput').click();
      setError422('');
    };
    const empty = () => {
      setFormValues((prev)=>({
        ...prev,
        image: '',
        name: '',
        email:'',
        relation:null,
        relationoption:[],
        isnextofkin:false,
        selectOption: '',
        isrelationloading:false,
        gender: '',
        dob: '',
        changeimage: false,
        id: getemergencydocid,
        Selectimage:null,
        isPreviewVisible1:true
      }));
      setMobileChange('');
      setDialCode('');
      setCountryCode('');
    }
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      if(name==='name'){
        setError422('');
      }
      if(name==='relation'){
        setError422('');
      }
      if(name==='responsephonenumber'){
        setError422('');
      }if(name==='email'){
        setError422('');
      }
    };
    const handlePhoneNumberChange = (value) => {
      validateAndSetMobileChange(value, {
        setMobileChange: setMobileChange,
        setError: (errorMsg) => setFormValuesError((prev) => ({ ...prev, mobileerror: errorMsg })),
        setNumberInvalid: setNumberinvalid,
        mobile_length: mobile_length,             
        mobile_starting_digits: mobile_starting_digits 
      });
      setError422('');
    };
    const handlePaste = (e) => {
        const pastedText = e.clipboardData.getData('text');
        let numericPastedText = pastedText.replace(/[^0-9]/g, '');
        const dialCodeWithoutPlus = dialCode.replace('+', '');
        if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
            numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
        }
        validateAndSetMobileChange(numericPastedText, {
          setMobileChange: setMobileChange,
          setError: (errorMsg) => setFormValuesError((prev) => ({ ...prev, mobileerror: errorMsg })),
          setNumberInvalid: setNumberinvalid,
          mobile_length: mobile_length,             
          mobile_starting_digits: mobile_starting_digits 
        });
        e.preventDefault();
        setError422('');
    };
    const handleCountryChange = (selectedOption) => {
        const selectedCountryData = countryOptions.find(
            (country) => country.value === selectedOption.value
        );
        if (selectedCountryData) {
          const { mcc_id, dial_code, country_code, mobile_length, mobile_starting_digits } = selectedCountryData;
      
          setMccId(mcc_id);
          setDialCode(`+${dial_code}`);
          setCountryCode(country_code);
          setMobile_length(mobile_length);
          setMobile_starting_digits(mobile_starting_digits);
          validateAndSetMobileChange(mobileChange, {
            setMobileChange,
            setError: (errorMsg) => setFormValuesError((prev) => ({ ...prev, mobileerror: errorMsg })),
            setNumberInvalid: setNumberinvalid,
            mobile_length,
            mobile_starting_digits,
          });
        }
        setError422('');
    };
    const handlecheckchange = (e) => {
      setFormValues((prev)=>({
        ...prev,
        isnextofkin:e.target.checked
      }));
    }
  
    const handleKeyDown1 = (e) => {
      const regex = /^[a-zA-Z\s]*$/;
      if (!regex.test(e.key)) {
        e.preventDefault();
        setFormValuesError((prev) => ({
          ...prev,
          nameError: 'Only alphabets are allowed'
        }));
      } else {
        setFormValuesError((prev) => ({
          ...prev,
          nameError: ''
        }));
      }
    };
    const handledelete = () => {
      setFormValues((prev)=>({
          ...prev,changeimage:false,image:''
      }));
    }
    const handleNext = async() => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(formValues.email)) {
        setFormValuesError((prevstate)=>({
          ...prevstate,emailerror:'Please enter a valid email address.'
        }))
        return;
      }else{
        setFormValuesError((prevstate)=>({
          ...prevstate,emailerror:''
        }))
      }
      const data = {
        kyc_id : formValues.id,
        image:formValues.image,
        mcc_id:mccId,
        relation:formValues.relation.value,
        kin_status:formValues.isnextofkin ? 1 : 0,
        mobile:mobileChange,
        email : formValues.email,
        name:formValues.name
      }
      try{
        setIsLoading4(true);
         const response = await axiosInstance.post(updateemergencycontactsapiurl,data);
         if(response.status === 200){
          await getemergency();
          empty();
          setShowAddNew(false);
          const nextStep = activeStep;
          setActiveStep(nextStep);
          // setActiveStep((prevActiveStep) => prevActiveStep + 1);
          localStorage.setItem('activeStep', nextStep);
          setError422('');
          setMobileChange('');
          setCountryCode('');
          setMccId('');
          setDialCode('');
          setIsLoading4(false);
         }
      }catch(error){
         handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
      }
    }
    const handleBack = () => {
      const nextStep = activeStep - 1;
        setActiveStep(nextStep);
        localStorage.setItem('activeStep', nextStep);
        setMobileChange('');
        setCountryCode('');
        setMccId('');
        setDialCode('');
    }
    const check = useCallback(()=>{
      const valid = (
        formValues.name !== '' && formValues.relation !== '' &&  formValues.image !== '' && formValues.changeimage !== false && mobileChange !== '' && formValues.email && numberinvalid === true
       );
       setIsenbled(valid);
    },[formValues.name,formValues.relation,formValues.image,formValues.changeimage,mobileChange,formValues.email,numberinvalid]);
    useEffect(()=>{
      check();
    },[check]);
  return (
    <>
       <div className="wallet-profilepad" style={{padding:'0px 40px 10px 55px'}}>
       {formValues.changeimage ? (
        <div className="wallet-profilediv">
          <div className="profilediv profilediv1">
          <ProfileImage containerClass="profile_user_image_div" imageSrc={formValues.image.startsWith('data:') ? formValues.image : `${publicDomainurl}/${formValues.image}`} showeye={false} eyeclassname='profile_eye_' imgClass="modal_user_profile"/>
            {/* <div className="header-profile-user border-dark user" style={{ width: "70px", height: "70px", borderRadius: "50%", marginRight: "10px", position: 'relative' }}>
              <img id="login_img_for_profile" src={formValues.image.startsWith('data:') ? `${formValues.image}`: `${publicDomainurl}/${formValues.image}`} className="rounded-circle border-dark" alt='profille' style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center center" }} /> */}
              {/* <img src={eye2} onClick={()=>imageview(formValues.image)} alt='eye2' style={{ width: "28px", height: "auto", position: "absolute", top: "63%", right: "-5px", objectFit: "cover" }} /> */}
            {/* </div> */}
            <div>
              <p className="walletmaximagep">Upload Your Picture</p>
              <p className='walletmaximage'>Max image size - 2mb</p>
            </div>
          </div>
          <div className="profile-uploadbuttondiv profile-uploadbuttondiv1">
            <button className='profile-upload profile-uploadreplace' onClick={triggerFileInput}>Replace</button>
            <input type="file" accept="image/png, image/jpg, image/jpeg" id="fileInput" style={{ display: 'none' }} onChange={handleImageUpload} />
            <button className="profile-upload profile-uploaddelete" onClick={handledelete}>Delete</button>
          </div>
        </div>
      ) : (
        <div className="wallet-profilediv">
          <div className="profilediv">
          <ProfileImage containerClass="profile_user_image_div" imageSrc={tick} showeye={false} eyeclassname='profile_eye_' imgClass="modal_user_profile"/>
            {/* <div className="header-profile-user border-dark user" style={{ width: "70px", height: "70px", borderRadius: "50%", marginRight: "10px", position: 'relative' }}>
              <img id="login_img_for_profile" src={tick} alt='proile' className="rounded-circle border-dark" style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center center" }} /> */}
              {/* <img src={eye2} onClick={()=>imageview(formValues.image)} alt='eye2' style={{ width: "28px", height: "auto", position: "absolute", top: "63%", right: "-5px", objectFit: "cover" }} /> */}
            {/* </div> */}
            <div>
              <p className=" walletmaximagep">Upload Your Picture</p>
              <p className='walletmaximage'>Max image size - 2mb</p>
            </div>
          </div>
          <div className="profile-uploadbuttondiv">
            <input type="file" accept="image/png, image/jpg, image/jpeg" id="fileInput" style={{ display: 'none' }} onChange={handleImageUpload} />
            <button className="profile-upload" onClick={triggerFileInput}>Upload</button>
          </div>
        </div>
      )}
      <div className="mt-3">
        <label className="walletprofile-label">Name<span className="docpopstar">*</span></label>
        <input type="text" name='name' value={formValues.name} onKeyDown={handleKeyDown1} onChange={handleChange} className={`form-control mt-1 walletprofile-input ${formValues.name ? 'walletprofile-input-active' : ''}`} placeholder="Enter your name" />
        {formValuesError.nameError && <p className="error-message">{formValuesError.nameError}</p>}
      </div>
      <div className="mt-3">
        <label className="walletprofile-label">Mobile Number<span className="docpopstar">*</span></label>
        <div style={{ position: 'relative' }}>
        <PhoneInputWithCountrySelector  countryOptions={countryOptions} handleCountryChange={handleCountryChange} dialCode={dialCode} mobileChange={mobileChange} handlePhoneNumberChange={handlePhoneNumberChange}  handlePaste={handlePaste} countryCode={countryCode} minheight='45px' walletprofileinput = 'walletprofile-input' walletprofileinputactive='walletprofile-input-active' isLoading={isLoading}/>
          </div>
        {formValuesError.mobileerror && <div className="error-message">{formValuesError.mobileerror}</div>}
      </div>
      <div className="mt-3">
        <label className="walletprofile-label">Email Address<span className="docpopstar">*</span></label>
        <input type="text" name='email' value={formValues.email}  onChange={handleChange} className={`form-control mt-1 walletprofile-input ${formValues.email ? 'walletprofile-input-active' : ''}`} placeholder="Enter your email" />
        {formValuesError.emailerror && <p className="error-message">{formValuesError.emailerror}</p>}
      </div>
      <div className='mt-3'>
      <label className="walletprofile-label">Relation<span className="docpopstar">*</span></label> 
       <Select name="relation" options={formValues.relationoption} onChange={(selectOption)=>setFormValues((prev)=>({...prev,relation:selectOption}))} className='mt-1' placeholder="Select Relation"  styles={customStyles1(Boolean(formValues.relation))}  />
      </div>
      <div className="d-flex align-items-center mt-3" style={{gap:'10px'}}>
                  <input type="checkbox" checked={formValues.isnextofkin} onChange={handlecheckchange} className="form-check-input emer-check"></input>
                  <p className="emercheckp">Next of Kin</p>
                </div>
                <hr className="walletprofile-mar" />
        {error422 && <p className="error-message">{error422}</p>}
        <div className="walletprofile-buttons mt-3">
          <button
            className={`walletprofile-next me-3 ${isenabled ? 'walletprofile-next-active' : 'walletprofile-next-disabled'}`}
            disabled={!isenabled}
            onClick={handleNext}
          >
            Next
          </button>
          <button
            className="walletprofile-cancel"
            // disabled={activeStep === 0}
            onClick={handleBack}
          >
            Cancel
          </button>
        </div>
       </div>
      
    </>
  )
})

export default WithToast(Emergency)