import React, { useState,useEffect,useCallback, useRef ,memo} from 'react'
import WithToast from '../../api/commonfunct.js/withtoast'
import checkcircle from '../../images/check-circle-01.png';
import Select, { components } from 'react-select';
import axiosInstance from '../../api/commonfunct.js/axiosinstance';
import { useIpContext } from '../../api/commonfunct.js/ipContext';
import useMenuHeight, { allfunctions, handleApiError, handleKeyPress, useRouteParams } from '../../api/commonfunct.js/common';
import {  updatekycrecoveryemailmobileapi,getkycrecoveryapi,kycrecoverysendotpapi, mcclistapi} from '../../api/url';
import Otpmodal from './otpmodal';
import { useNavigate } from 'react-router-dom';
const mcclistapiurl = mcclistapi();
const getkycrecoveryapiurl = getkycrecoveryapi();
const kycrecoverysendotpapiurl = kycrecoverysendotpapi();
const updatekycrecoveryemailmobileapiurl = updatekycrecoveryemailmobileapi();

const Recoveryemailphone = memo(({ setActiveStep, activeStep, setIsLoading4, showErrorToast }) => {
  const navigate = useNavigate();
  const params = useRouteParams();
    const [error422,setError422] = useState('');
    const [formValues, setFormValues] = useState({
        responseemail: '',
        responsephoneNumber: '',
        oldresponseemail: '',
        oldresponsephoneNumber: '',
        response_is_email_verified: 0,
        response_is_mobile_verified: 0,
        kyc_id: '',
        selectOption: '',
        countryOptions: [],
        mcc_id: null,
        dial_code: '',
        country_code: '',
        mobileerror: '',
        emailerror: '',
        isLoading: false,
        showemailget: false,
        showmobileget: false,
        isip:false,
        oldmcc_id:''
    });
    const [initialloading,setInitialloading] = useState(true);
    const [show,setShow] = useState(false);    
    const [emailphone1,setEmailphone1] = useState([]);
    const mobileInputRef = useRef(null);
    const { ipCountryCode, getUserIpCountryCode } = useIpContext();
    const getkycemailmobile = useCallback(async () => {
        try {
            const response = await axiosInstance.get(getkycrecoveryapiurl);
            if (response.status === 200) {
                const emailphone = response.data.data;
                setEmailphone1(emailphone);
                // setFormValues(prev => ({
                //     ...prev,
                //     responseemail: emailphone.email || '',
                //     responsephoneNumber: emailphone.mobile || '',
                //     mcc_id: emailphone.mcc_id || '',
                //     response_is_mobile_verified: emailphone.is_mobile_verified || 0,
                //     response_is_email_verified: emailphone.is_email_verified || 0,
                //     country_code: emailphone.country_code || '',
                //     dial_code: emailphone.code || '',
                //     kyc_id: emailphone.kyc_id || '',
                //     oldresponseemail: emailphone.is_email_verified ? emailphone.email : '',
                //     oldresponsephoneNumber: emailphone.is_mobile_verified ? emailphone.mobile : '',
                //     isip:emailphone.country_code ? true : false,
                // }));
            }
        } catch (error) {
          if(error.response){
            if(error.response.status === 422){
              const code = error.response.data.error_code;
              if(code === 'Kyc not available'){
                navigate(`/u/${params}`);
              }else{
                handleApiError(error, null, null, true, showErrorToast);
              }
            }
          }
            handleApiError(error, null, null, true, showErrorToast);
        }
    }, [showErrorToast]);
    const updateExampleNumber = useCallback((iso2) => {
      if (window.intlTelInputUtils) {
        try {
          const exampleNumber = window.intlTelInputUtils.getExampleNumber(
            iso2,
            true,
            window.intlTelInputUtils.numberFormat.E164
          );
          const cleanedNumber = exampleNumber.replace(/\D/g, '').replace(/^0+/, '');
          const maxLength = cleanedNumber.length;
          return maxLength;
        } catch (error) {
          console.error('Error fetching example number for country code:', iso2, error);
          return 15; // Fallback to a default length
        }
      } else {
        console.error('intlTelInputUtils is not loaded');
        return 15; // Fallback to a default length
      }
    }, []);
    useEffect(() => {
      if (emailphone1) {
          setFormValues((prev) => ({
              ...prev,
              responseemail: emailphone1.email || '',
              responsephoneNumber: emailphone1.mobile || '',
              mcc_id: emailphone1.mcc_id || '',
              oldmcc_id: emailphone1.is_mobile_verified ? emailphone1.mcc_id : '' ,
              response_is_mobile_verified: emailphone1.is_mobile_verified || 0,
              response_is_email_verified: emailphone1.is_email_verified || 0,
              country_code: emailphone1.country_code || '',
              dial_code: emailphone1.code || '',
              kyc_id: emailphone1.kyc_id || '',
              oldresponseemail: emailphone1.is_email_verified ? emailphone1.email : '',
              oldresponsephoneNumber: emailphone1.is_mobile_verified ? emailphone1.mobile : '',
              isip: emailphone1.country_code ? true : false
          }));
      }
  }, [emailphone1]);
  const fetchCountryCode = useCallback(async () => {
    if(!initialloading){
    if (ipCountryCode || formValues.country_code){ 
    if (formValues.countryOptions.length > 0) return;
    setFormValues((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
  
    try {
      const response = await axiosInstance.get(mcclistapiurl);
      const data = response.data;
  
      if (data.message === 'Success') {
        const mccList = data.data;
        const codes = mccList.map((mcc) => ({
          country_code: mcc.country_code,
          dial_code: mcc.code,
          mcc_id: mcc.id,
          label: `${mcc.name} (+${mcc.code})`,
          value: mcc.country_code,
          flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
        }));
  
        setFormValues((prevState) => ({
          ...prevState,
          countryOptions: codes,
        }));
        let code;
        if(!formValues.isip){
           code = ipCountryCode
        }else{
          code = formValues.country_code
        }
        const selectedCountryData = codes.find(
          (country) => country.country_code === code
        );
  
        if (selectedCountryData) {
          setFormValues((prevState) => ({
            ...prevState,
            mcc_id: selectedCountryData.mcc_id,
            dial_code: `+${selectedCountryData.dial_code}`,
            country_code: selectedCountryData.country_code,
          }));
  
          const maxLength =  updateExampleNumber(selectedCountryData.country_code.toLowerCase());
          if (maxLength !== null && mobileInputRef.current) {
            mobileInputRef.current.setAttribute('maxlength', maxLength);
          }
        }
      } else {
        console.error('Failed to fetch country codes');
      }
    } catch (error) {
      handleApiError(error, null, null, true, showErrorToast);
    } finally {
      setFormValues((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  }
}
  }, [formValues.country_code, updateExampleNumber, showErrorToast,formValues.isip,ipCountryCode,initialloading]);
  
  const ip = useCallback(()=>{
    if(!initialloading){
      if (!formValues.isip) {
          if (!ipCountryCode) {
              getUserIpCountryCode();
          }
         fetchCountryCode();
      }else{
        fetchCountryCode();
      }
    }
  } ,[fetchCountryCode, formValues.isip, getUserIpCountryCode, ipCountryCode,initialloading])

  useEffect(() => {
        ip();
}, [ip]);

    const handleCountryChange = (selectedOption) => {
      const selectedCountryData = formValues.countryOptions.find(
        country => country.value === selectedOption.value
      );
    
      if (selectedCountryData) {
        setFormValues(prevState => ({
          ...prevState,
          country_code: selectedCountryData.country_code,
          dial_code: `+${selectedCountryData.dial_code}`,
          mcc_id: selectedCountryData.mcc_id
        }));
    
        // Fetch maximum length based on selected country
        const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
        if (maxLength !== null && mobileInputRef.current) {
          mobileInputRef.current.setAttribute('maxlength', maxLength);
        }
    
        // Handle phone number truncation if necessary
        setFormValues(prevState => ({
          ...prevState,
          responsephoneNumber: prevState.responsephoneNumber.slice(0, maxLength)
        }));
      }
    
      setError422('');
    };
    
    useEffect(() => {
        // getkycemailmobile().finally(()=>setInitialloading(false));
        allfunctions([getkycemailmobile]).finally(()=>setInitialloading(false));
    }, [getkycemailmobile]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        if(name === 'responseemail'){
            setFormValues((prev)=>({
                ...prev,
                emailerror:''
            }));
            setError422('');
        }
        if(name === 'responsephoneNumber'){
            setFormValues((prev)=>({
                ...prev,
                mobileerror:''
            }));
            setError422('');
        }
        setError422('');
    };
    const handlePhoneNumberChange = (value) => {
        if (value.startsWith('0')) {
          value = value.slice(1);
        }
        const maxLength = mobileInputRef.current.getAttribute('maxlength');
        if (value.length > maxLength) {
          value = value.slice(0, maxLength);
        }
        setFormValues(prevState => ({
          ...prevState,
          responsephoneNumber: value
        }));
        setError422('');
    };
    const handlePaste = (e) => {
      setError422('');
      const pastedText = e.clipboardData.getData('text');
      let numericPastedText = pastedText.replace(/[^0-9]/g, '');
  
      // Define the dial code without the '+'
      const dialCodeWithoutPlus = formValues.dial_code.replace('+', '');
  
      // Remove dial code if the pasted text starts with '+'
      if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
          numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
      }
  
      // Remove leading '0' if present
      if (numericPastedText.startsWith('0')) {
          numericPastedText = numericPastedText.slice(1);
      }
  
      // Calculate the max length and truncate the number if necessary
      const maxLength = mobileInputRef.current.getAttribute('maxlength');
      const truncatedPastedText = numericPastedText.slice(0, maxLength);
      setFormValues(prevState => ({
        ...prevState,
        responsephoneNumber: truncatedPastedText
      }));
    }
      const handleNext = async () => {
        const data = {
          kyc_id : formValues.kyc_id
        }
        try{
          setIsLoading4(true);
          const response = await axiosInstance.post(updatekycrecoveryemailmobileapiurl,data);
          if(response.status === 200){
            setError422('');
            const nextStep = activeStep + 1;
        setActiveStep(nextStep);
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        localStorage.setItem('activeStep', nextStep);
            setIsLoading4(false);
          }
        }catch(error){
          handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
        }
      };
    const handleBack = async() => {
      setFormValues((prev)=>({
        ...prev,countryOptions:[]
      }));
      const nextStep = activeStep - 1;
        setActiveStep(nextStep);
        localStorage.setItem('activeStep', nextStep);
    }
    const customSingleValue = ({ data }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
            src={data.flag}
            alt=""
            style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
        />
        </div>
    );

    const customOption = (props) => (
        <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
            src={props.data.flag}
            alt=""
            style={{ width: '25px', height: 'auto', marginRight: '10px' }}
            />
            {props.data.label}
        </div>
        </components.Option>
    );

    const isNextButtonActive = () => {
        return formValues.response_is_mobile_verified === 1 && formValues.response_is_email_verified === 1;
    };
    
    const getOtpEmail = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formValues.responseemail)) {
          setFormValues(prevState => ({
            ...prevState,
            emailerror: 'Please enter a valid email address.'
          }));
          return;
        }
        setFormValues(prev => ({
          ...prev, selectOption: 'email'
        }));
        const data = {
          email: formValues.responseemail,
          kyc_id: formValues.kyc_id,
          data_mode: 'email'
        };
        sendOtp(data);
      };
    
      const getOtpMobile = () => {
        if (formValues.responsephoneNumber.length < 9 || formValues.responsephoneNumber.length > 15) {
          setFormValues(prev => ({
            ...prev,
            mobileerror: 'The mobile number must be between 9 and 15 digits'
          }));
          return;
        }
        setFormValues(prev => ({
          ...prev, selectOption: 'mobile'
        }));
        const data = {
          data_mode: 'mobile',
          mobile: formValues.responsephoneNumber,
          mcc_id: formValues.mcc_id,
          kyc_id: formValues.kyc_id
        };
        sendOtp(data);
      };
    
      const sendOtp = async (data) => {
        try {
          setIsLoading4(true);
          const response = await axiosInstance.post(kycrecoverysendotpapiurl, data);
          if (response.status === 200) {
            setIsLoading4(false);
            setShow(true);
          }
        } catch (error) {
          handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
        }
      };
       
      const handleClose = () => {
        setShow(false);
        setFormValues((prev)=>({
          ...prev,countryOptions:[]
        }));
        // getkycemailmobile();
        // ip();
      };
      const checkVerificationStatus = useCallback(() => {
        setFormValues((prev) => ({
          ...prev,
          showemailget: prev.responseemail === prev.oldresponseemail,
          showmobileget: prev.responsephoneNumber === prev.oldresponsephoneNumber && prev.mcc_id === prev.oldmcc_id,
        }));
      }, [formValues.responseemail,formValues.responsephoneNumber,formValues.oldresponseemail,formValues.oldresponsephoneNumber,formValues.mcc_id,formValues.oldmcc_id]);
    
    
      useEffect(() => {
        checkVerificationStatus();
      }, [checkVerificationStatus]);
     
    
      const calculateWidth = (label) => {
        const tempDiv = document.createElement('p');
        tempDiv.style.position = 'absolute';
        tempDiv.style.visibility = 'hidden';
        tempDiv.style.fontSize = '14px';
        tempDiv.style.fontWeight = '400';
        tempDiv.style.whiteSpace = 'nowrap';
        tempDiv.style.margin = '0';
        document.body.appendChild(tempDiv);
    
        tempDiv.innerText = label;
        const newWidth = tempDiv.offsetWidth;
        document.body.removeChild(tempDiv);
    
        return newWidth;
      };
    
      const [selwidth, setSelwidth] = useState(0);
    
      const selectWidth = useCallback(() => {
        const label = formValues.dial_code || '';
        const newWidth = Math.round(calculateWidth(label));
        setSelwidth(newWidth + 82);
      }, [formValues.dial_code]);
    
      useEffect(() => {
        selectWidth();
      }, [selectWidth]);
    
      useEffect(() => {
        const handleResize = () => {
          selectWidth();
        };
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [selectWidth]);
    
      const menuHeight = useMenuHeight(200, 400);
    
  return (
    <>
     <div className="wallet-profilepad">
        <div>
          <label className="walletprofile-label">Recovery Email<span className="docpopstar">*</span></label>
          <div style={{ position: 'relative' }}>
            <input
              type="email"
              name="responseemail"
              value={formValues.responseemail}
              onChange={handleInputChange}
              className={`form-control mt-1 walletprofile-input ${formValues.responseemail ? 'walletprofile-input-active' : ''}`}
              placeholder="Enter your email address"
            />
            {formValues.response_is_email_verified && formValues.showemailget ? (
              <p style={{ position: 'absolute', right: '10px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer', marginBottom: '0px' }}>
                <img src={checkcircle} alt='check' width='20px' height='auto' />
              </p>
            ): null}
          </div>
          {formValues.emailerror && <p className="error-message">{formValues.emailerror}</p>}
          {(formValues.response_is_email_verified === 0 && formValues.responseemail) || (!formValues.showemailget && formValues.responseemail) ? (
            <div className='d-flex justify-content-end'>
              <p className="wallet-pofileotpp" onClick={getOtpEmail}>Get OTP</p>
            </div>
          ) : null}
        </div>
        <div className={`${formValues.responseemail ? 'mt-1' : 'mt-3'}`}>
          <label className="walletprofile-label">Recovery Mobile Number<span className="docpopstar">*</span></label>
          <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', left: 0, zIndex: 1 }}>
              <Select
                options={formValues.countryOptions || []}
                onChange={handleCountryChange}
                value={formValues.countryOptions.find(option => option.value === formValues.country_code)}
                components={{ SingleValue: customSingleValue, Option: customOption }}
                placeholder=""
                isLoading={formValues.isLoading}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: formValues.isLoading ? '80px' : '80px',
                    height: '45px',
                    border: 'none',
                    boxShadow: 'none',
                    background: 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    padding: '0 8px',
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    display: 'flex',
                    alignItems: 'center',
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: '0',
                    display: 'flex',
                    alignItems: 'center',
                  }),
                  input: (provided) => ({
                    ...provided,
                    margin: '0',
                    padding: '0',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    margin: '0',
                    padding: '0',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    minWidth: '300px',
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    padding: 0,
                    height: menuHeight,
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
            <div style={{ height: '30px', width: '2px', backgroundColor: '#ccc', margin: '0 8px', position: 'absolute', left: `${selwidth}px`, top: '50%', transform: 'translateY(-50%)' }}></div>
            <p style={{ position: 'absolute', left: '80px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer', color: 'hsl(0, 0%, 20%)', fontSize: '14px', lineHeight: '1.5', marginBottom: '0px', fontWeight: '400' }}>{formValues.dial_code}</p>
            {formValues.response_is_mobile_verified && formValues.showmobileget ? (
              <p style={{ position: 'absolute', right: '10px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer', marginBottom: '0px' }}>
                <img src={checkcircle} alt='check' width='20px' height='auto' />
              </p>
            ) : null}
            <input type="text" name="responsephoneNumber" onPaste={(e) => handlePaste(e)} value={formValues.responsephoneNumber} ref={mobileInputRef} onKeyPress={handleKeyPress} onChange={(e) => handlePhoneNumberChange(e.target.value)} className={`form-control mt-1 walletprofile-input ${formValues.responsephoneNumber ? 'walletprofile-input-active' : ''}`} style={{ paddingLeft: `${selwidth + 18}px` }} placeholder="Enter your Mobile number"/>
          </div>
          {formValues.mobileerror && <p className="error-message">{formValues.mobileerror}</p>}
          {(formValues.response_is_mobile_verified === 0 && formValues.responsephoneNumber) || (!formValues.showmobileget && formValues.responsephoneNumber) ? (
            <div className='d-flex justify-content-end'>
              <p className="wallet-pofileotpp" onClick={getOtpMobile}>Get OTP</p>
            </div>
          ) : null}
        </div>
        <hr className="walletprofile-mar" />
        {error422 && <p className="error-message">{error422}</p>}
        <div className="walletprofile-buttons mt-3">
          <button
            className={`walletprofile-next me-3 ${isNextButtonActive() ? 'walletprofile-next-active' : 'walletprofile-next-disabled'}`}
            onClick={handleNext}
            disabled={!isNextButtonActive()}
          >
            Next
          </button>
          <button
            className="walletprofile-cancel"
            onClick={handleBack}
          >
            Cancel
          </button>
        </div>
      </div>
      <Otpmodal
        show={show}
        selectedOption={formValues.selectOption}
        kyc_id={formValues.kyc_id}
        responseemail={formValues.responseemail}
        responsephoneNumber={formValues.responsephoneNumber}
        responsecountrycode={formValues.dial_code}
        handleClose={handleClose}
        getkycemailmobile={getkycemailmobile}
        ip={ip}
        setIsLoading4={setIsLoading4}
        isRecoveryText={true}
      />
    </>
  )
})

export default WithToast(Recoveryemailphone)