import React, { memo, useState, useCallback, useEffect } from 'react';
import WithToast from '../../api/hook/withtoasthook';
import Select from 'react-select';
import { customStyles3, handleApiError } from '../../api/utils/utils';
import Addaccountdetailmodal from './addaccountdetailmodal';
import { getbanklist, getcitylist, getcountrylist,getbankbranchlist } from '../../api/helper/apiurlhelper';
import ReusableModal from '../../components/reusablemodal';
import Closeicon from '../../components/closeicon';
const Addbankdetailmodal = memo(({ show,name,handleClose,getbanksall, setIsLoading4, showErrorToast }) => {
  const [formValues, setFormValues] = useState({ country: null, bank: null, city: null, branch: null });
  const [enabled,setEnabled] = useState(false);
  const [countryLoading, setCountryLoading] = useState(false);
  const [cityLoading, setCityLoading] = useState(false);
  const [bankLoading, setBankLoading] = useState(false);
  const [branchLoading, setBranchLoading] = useState(false);
  const [show1,setShow1] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [bankOptions, setBankOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const getcountrylistall = useCallback(() => {
    setCountryLoading(true);
    getcountrylist()
      .then((res) => {
        const country = res.data.data;
        setCountryOptions(country.map(company => ({ value: company.id, label: company.name })));
      })
      .catch((error) => handleApiError(error, null, null, true, showErrorToast))
      .finally(()=>setCountryLoading(false));
  }, [showErrorToast]);
  useEffect(()=>{
    if(name === 'stalin'){
      getcountrylistall();
    }
  },[getcountrylistall,name]);
  const getbankcitylistall = useCallback((country) => {
    const data = { 
      country_id:country
   }
   setCityLoading(true);
    getcitylist(data)
    .then((res) => {
      const country = res.data.data;
      setCityOptions(country.map(company => ({ value: company.id, label: company.destination })));
    })
    .catch((error) => handleApiError(error, null, null, true, showErrorToast))
    .finally(()=>setCityLoading(false));
  },[showErrorToast]);
  const getbanklistall = useCallback((city) => {
    const data = { 
      country_id:formValues.country,
      city_id:city 
   }
   setBankLoading(true);
    getbanklist(data)
    .then((res) => {
      const country = res.data.data;
      setBankOptions(country.map(company => ({ value: company.id, label: company.bank_name })));
    })
    .catch((error) => handleApiError(error, null, null, true, showErrorToast))
    .finally(()=>setBankLoading(false));
  },[formValues.country,showErrorToast]);
  const bankbranchall = useCallback((bank)=>{
    const data = { 
      country_id:formValues.country,
      city_id:formValues.city,
      bank_id:bank, 
   }
   setBranchLoading(true);
    getbankbranchlist(data)
    .then((res) => {
      const country = res.data.data;
      setBranchOptions(country.map(company => ({ value: company.id, label: company.branch_name })));
    })
    .catch((error) => handleApiError(error, null, null, true, showErrorToast))
    .finally(()=>setBranchLoading(false));
  },[formValues.country,formValues.city,showErrorToast]);
  const handleCountryChange = (selectedCountry) => {
    setFormValues((prev) => ({
      ...prev,
      country: selectedCountry.value,
      bank: null,
      city: null,
      branch: null
    }));
    getbankcitylistall(selectedCountry.value);
    setBankOptions([]);
    setBranchOptions([]);
  };
  const handleCityChange = (selectedCity) => {
    setFormValues((prev)=>({...prev,city:selectedCity.value,branch:null,bank:null}))
    getbanklistall(selectedCity.value);
    setBranchOptions([]);
  };
  const handleBankChange = (selectedBank) => {
    setFormValues((prev)=>({...prev,bank:selectedBank.value,branch:null}));
    bankbranchall(selectedBank.value);
  };
  const handleBranchChange = (selectedBranch) => {
    // setFormValues({ ...formValues, branch: selectedBranch.value });
    setFormValues((prev)=>({...prev,branch:selectedBranch.value}));
  };
  const resetvalues = () => {
    setFormValues((prev)=>({
      ...prev,country:'',bank:'',city:'',branch:''
    }));
  }
  const handleClose1 = () => {
    handleClose();
    resetvalues();
  };
  const check = useCallback(() => {
    const valid = (
      formValues.country !== '' && formValues.country !== null && formValues.bank !== '' && formValues.bank !== null && formValues.city !== '' && formValues.city !== null && formValues.branch !== '' && formValues.branch !== null 
    );
    setEnabled(valid);
  },[formValues.country,formValues.bank,formValues.city,formValues.branch]);
  useEffect(()=>{
    check();
  },[check]);
  const save = async() => {
    handleClose();
    setShow1(true);
  } 
  const handleClose2 = () => {
    setShow1(false);
    resetvalues();
  }
  return (
    <>
     <ReusableModal show={show} handleClose={handleClose1} dialogClassName='card-example-dialog' contentClassName="card-example-content" >
     <div className="text-end">
     <Closeicon className='closeicon mx-1 mt-2' onClick={handleClose1}/>
          </div>
          <div className="card-example-dialog-bodypadd">
            <h5 className="addnewh5">Add Bank Details</h5>
            <div className="mt-3">
            <label htmlFor="country" className="cardlabel">Country<span className="docpopstar">*</span></label>
            <Select options={countryOptions} inputId='country' isLoading={countryLoading} placeholder='Select Country' styles={customStyles3(Boolean(formValues.country))}  value={countryOptions.find(option => option.value === formValues.country) || null} onChange={handleCountryChange}/>
            </div>
            <div className="mt-2">
            <label htmlFor="CityName" className="cardlabel">City Name<span className="docpopstar">*</span></label>
            <Select options={cityOptions} inputId='CityName' isLoading={cityLoading} placeholder='Select City' styles={customStyles3(Boolean(formValues.city))} value={cityOptions.find(option => option.value === formValues.city) || null} onChange={handleCityChange} isDisabled={!formValues.country} />
            </div>
            <div className="mt-2">
            <label htmlFor="BankName" className="cardlabel">Bank Name<span className="docpopstar">*</span></label>
            <Select options={bankOptions} inputId='BankName' isLoading={bankLoading} placeholder='Select Bank'  styles={customStyles3(Boolean(formValues.bank))} value={bankOptions.find(option => option.value === formValues.bank) || null} onChange={handleBankChange} isDisabled={!formValues.city}  />
            </div>
            <div className="mt-2">
            <label htmlFor="BranchName" className="cardlabel">Branch Name<span className="docpopstar">*</span></label>
            <Select options={branchOptions} inputId='BranchName' isLoading={branchLoading} placeholder='Select Branch' styles={customStyles3(Boolean(formValues.branch))} value={branchOptions.find(option => option.value === formValues.branch) || null} onChange={handleBranchChange} isDisabled={!formValues.bank}/>
            </div>
        <div className='mt-4'>
          <button  className={`modal_save_card ${enabled ? 'modal_save_card_active' : 'modal_save_card_disabled'}`} disabled={!enabled} onClick={save}>Next</button>
        </div>
          </div>
     </ReusableModal>
      <Addaccountdetailmodal show={show1} formValues={formValues} getbanksall={getbanksall} handleClose={handleClose2} setIsLoading4={setIsLoading4} showErrorToast={showErrorToast}/>
    </>
  );
});

export default WithToast(Addbankdetailmodal);
