import React, { memo,useState,useCallback,useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import WithToast from '../../api/hook/withtoasthook';
import closeicon from '../../images/times-circle-01.png';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { disablePastDates, handleApiError, handleKeyDown, handleKeyPress } from '../../api/utils/utils';
import Loader from '../../loader';
import axiosInstance from '../../api/utils/axiosutils';
import { updatecardsapi } from '../../api/helper/urlhelper';
const updatecardsapiurl = updatecardsapi();
const Editcardmodal = memo(({show,handleClose,getcardall,mapvalues,showErrorToast})=> {
    const [formValues,setFormValues] = useState({cardnumber:'',expireMonth:'',expireYear:'',cvv:'',cardholdername:'',cardType: 'debit',dateofissue:'',id:''});
    const [enabled,setEnabled] = useState(false);
    const [isLoading4,setIsLoading4] = useState(false);
    const [cvvmasked,setCvvMasked] = useState('');
    const [error422,setError422] = useState('');
    useEffect(()=>{
        if(mapvalues){
          let cardNumber = mapvalues.card_number || '';
          cardNumber = cardNumber.replace(/(.{4})/g, '$1 ').trim();
          setFormValues((prev)=>({
            ...prev,
            cardnumber:cardNumber || '',
            cardholdername:mapvalues.cardholder_name || '',
            id:mapvalues.id || '',
            cardType:mapvalues.card_type || '',
            cvv:mapvalues.cvv || '',
            expireMonth:mapvalues.expired_month || '',
            expireYear:mapvalues.expired_year || '',
            dateofissue: mapvalues.expired_month && mapvalues.expired_year ? dayjs(`${mapvalues.expired_year}-${mapvalues.expired_month}`) : '',
          }));
          setCvvMasked(mapvalues.cvv || '');
        }
    },[mapvalues])
    console.log(formValues.dateofissue);
    
    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === 'cardnumber') {
        let cardNumber = value.replace(/\D/g, '');

        // Limit the length to 16 digits
       cardNumber = cardNumber.slice(0, 16);

       // Add spaces after every 4 digits
         cardNumber = cardNumber.replace(/(.{4})/g, '$1 ').trim();
 
     setFormValues((prevValues) => ({
       ...prevValues,
       [name]: cardNumber,
     }));
      } else {
        // For other fields, use default handling
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      }
      setError422('');
    };
    const handlePasteCardNumber = (e) => {
      e.preventDefault();
      let pastedText = e.clipboardData.getData('text');
      let cardNumber = pastedText.replace(/\D/g, '');
      cardNumber = cardNumber.slice(0, 16);
      cardNumber = cardNumber.replace(/(.{4})/g, '$1 ').trim();
      setFormValues((prevValues) => ({
        ...prevValues,
        cardnumber: cardNumber,
      }));
      setError422('');
    };
    const handleChangecvv = (e) => {
      const currentInput = e.target.value;
  
      // Check if the total length of cvv exceeds 3
      if (currentInput.length <= 3) {
        const newCharacter = currentInput.slice(-1);
        const newPassword = formValues.cvv + newCharacter;
        
        setFormValues((prev) => ({
          ...prev,
          cvv: newPassword,
        }));
        setCvvMasked('*'.repeat(newPassword.length));
      }
    };
    const handlePastecvv = (e) => {
      e.preventDefault(); // Prevent the default paste action
  
      let pastedText = e.clipboardData.getData('text');
      
      // Filter only numeric characters
      pastedText = pastedText.replace(/\D/g, '');
    
      // Determine the remaining allowed characters to reach the 3 digit max
      const remainingLength = 3 - formValues.cvv.length;
      
      // If there's space, take only the allowed number of characters
      if (remainingLength > 0) {
        pastedText = pastedText.slice(0, remainingLength);
        const newPassword = formValues.cvv + pastedText;
        
        setFormValues((prev) => ({
          ...prev,
          cvv: newPassword,
        }));
        setCvvMasked('*'.repeat(newPassword.length));
      }
    };
    const handleKeyDown1 = (e) => {
      if (e.key === 'Backspace') {
        // Prevent default behavior so we control the backspace manually
        e.preventDefault(); 
    
        // Update the actual CVV value
        const newRealPassword = formValues.cvv.slice(0, -1);
    
        // Update the masked CVV
        const newMaskedPassword = cvvmasked.slice(0, -1);
    
        // Update the state with the new values
        setFormValues((prev) => ({
          ...prev,
          cvv: newRealPassword,
        }));
        setCvvMasked(newMaskedPassword);
      }
    };
    const handleChangeCardholderName = (e) => {
      let { value } = e.target;
      value = value.replace(/[0-9]/g, '');
      setFormValues((prevValues) => ({
        ...prevValues,
        cardholdername: value,
      }));
      setError422('');
    };
    
    const handleRadioChange = (e) => {
      setFormValues((prevValues) => ({
        ...prevValues,
        cardType: e.target.value, 
      }));
      setError422('');
    };
    const handleDateChange = (date) => {
      if (!date || !dayjs(date).isValid()) {
        // If date is cleared, reset the date-related fields
        setFormValues((prev) => ({
          ...prev,
          dateofissue: '',
          expireMonth: '',
          expireYear: '',
        }));
        setError422('');
      } else {
        const formattedDate = dayjs(date).format('MM/YY'); // Format as MM/YY
        const month = dayjs(date).format('MM');
        const year = dayjs(date).format('YY'); // Format year as YY
    
        setFormValues((prev) => ({
          ...prev,
          dateofissue: formattedDate,
          expireMonth: month,
          expireYear: year,
        }));
        setError422('');
      }
    };
  
    const resetFormValues = () => {
      setFormValues({
        cardnumber: '',
        expireMonth: '',
        expireYear: '',
        cvv: '',
        cardholdername: '',
        cardType: 'debit', 
        dateofissue:''
      });
      setError422('');
    };
    const handleClose1 = () => {
      handleClose();
      resetFormValues();
    }
    const check = useCallback(() => {
      const valid = (
       formValues.cardnumber !== '' && formValues.dateofissue !== '' && formValues.cvv !== '' && formValues.cardholdername !== '' && formValues.cardType !== ''
      )
      setEnabled(valid);
    },[formValues.cardnumber,formValues.cardholdername,formValues.dateofissue,formValues.cvv,formValues.cardType]);
    useEffect(()=>{
      check();
    },[check]);
    const save = async()=>{
      const data = {
          card_id : formValues.id,
          card_type : formValues.cardType,
          card_number : formValues.cardnumber.replace(/\s+/g, '').trim(),
          expired_month : parseInt(formValues.expireMonth),
          expired_year : parseInt(formValues.expireYear),
          cardholder_name : formValues.cardholdername,
          cvv : formValues.cvv
      }
      try{
          setIsLoading4(true);
          const response = await axiosInstance.post(updatecardsapiurl,data);
          if(response.status === 200){
              getcardall();
              handleClose1();
              setIsLoading4(false);
          }
      }catch(error){
          handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
      }
    }
  return (
    <>
     {isLoading4 && <Loader/>}
      <Modal show={show} dialogClassName="card-example-dialog" contentClassName="card-example-content" onHide={handleClose1} style={{paddingRight:'0px'}} centered>
        <Modal.Body className='card-example-dialog-body'>
        <div className="text-end">
            <img src={closeicon} onClick={handleClose1} style={{cursor:'pointer'}} className="closeicon mx-1 mt-2" alt="closeicon" ></img>
         </div>
         <div className='card-example-dialog-bodypadd'>
         <h5 className="addnewh5 ">Add new card</h5>
         <div className="d-flex align-items-center cardscards mt-3">
        <div className="d-flex align-items-center me-3">
            <input type="radio" id="debitRadioId" className='creditcard me-2' value="debit" checked={formValues.cardType === 'debit'} onChange={handleRadioChange} name="yourRadioGroupName" />
            <label htmlFor="debitRadioId" className="debitcardlabel">Debit Card</label>
        </div>
        <div className="d-flex align-items-center">
            <input type="radio" id="creditRadioId" name="yourRadioGroupName" className="me-2" value="credit" checked={formValues.cardType === 'credit'} onChange={handleRadioChange} />
            <label htmlFor="creditRadioId" className="debitcardlabel">Credit Card</label>
        </div>
        </div>
        <div className='mt-3'>
        <label htmlFor="cardNumber" className="cardlabel">Card Number:</label>
        <input type="text" id="cardNumber" placeholder='Enter Card Number' onPaste={handlePasteCardNumber}  onKeyPress={handleKeyPress} className={`form-control cardinputbox ${formValues.cardnumber ? 'cardinputbox_active' : ''}`} value={formValues.cardnumber} onChange={handleChange} name="cardnumber"  />
        </div>
        <div className='row mt-2'>
            <div className='col-6'>
            <label htmlFor="dateofissues" className="cardlabel">Expire Date</label>
            <DatePicker id='dateofissues' disabledDate={disablePastDates()} onKeyDown={handleKeyDown} value={formValues.dateofissue ? dayjs(formValues.dateofissue, 'MM/YY') : null}  onChange={handleDateChange} picker="month" placeholder="MM/YY" className={`${formValues.dateofissue ? 'ant-picker-active' : 'ant-picker'} modaldate`} style={{width:'100%',height:'40px',borderColor: formValues.dateofissue ? 'black' : '#9B9898',boxShadow:'none' , padding:'.375rem .75rem',borderRadius:'6px'}} format="MM/YY" getPopupContainer={(trigger) => trigger.parentNode} suffixIcon={null}/>
            </div> 
            <div className='col-6'>
            <label htmlFor="cvv" className="cardlabel">CVV</label>
            <input type='text' id='cvv' value={cvvmasked} onPaste={handlePastecvv}onKeyDown={handleKeyDown1} onChange={handleChangecvv} placeholder='CVV' maxLength={3} className={`form-control cardinputbox ${formValues.cvv ? 'cardinputbox_active' : ''}`} name="cvv" onKeyPress={handleKeyPress}></input>
            </div>
        </div>
        <div className='mt-2'>
        <label htmlFor="cardholdername" className="cardlabel">Cardholder Name</label>
        <input type='text' id='cardholdername' value={formValues.cardholdername} onChange={handleChangeCardholderName} placeholder='Enter Cardholder Name'  className={`form-control cardinputbox ${formValues.cvv ? 'cardinputbox_active' : ''}`} name="cardholdername"></input>
        </div>
        {error422 && <p className="error-message">{error422}</p>}
        <div className='mt-4'>
          <button className={`modal_save_card ${enabled ? 'modal_save_card_active' : 'modal_save_card_disabled'}`} disabled={!enabled} onClick={save}>Save</button>
        </div>
         </div>
        </Modal.Body>
      </Modal>
    </>
  )
})

export default WithToast(Editcardmodal);


