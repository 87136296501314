import React,{useState,useCallback,useEffect} from 'react'
import WithToast from '../../api/commonfunct.js/withtoast';
import registerservicce from '../../images/registered_services-01.png';
import Swal from 'sweetalert2';
import plus from '../../images/plus.png';
import { allfunctions, handleApiError, maskMobileNumber } from '../../api/commonfunct.js/common';
import { getbanks } from '../addsourcegetapi';
import Addbankdetailmodal from './addbankdetailmodal';
import trash  from '../../images/paypal/trash-01.png';
import edit from '../../images/paypal/pen-01.png';
import { deletebankapi } from '../../api/url';
import axiosInstance from '../../api/commonfunct.js/axiosinstance';
import Bankeditcomponents from './bankeditcomponents';
const deletebankapiurl = deletebankapi();
function Bank({setIsLoading4,showErrorToast}) {
    const [bank,setBank] = useState([]);
    const [show,setShow] = useState(false);
    const [mapvalues,setMapvalues] = useState([]);
    const [editshow,setEditshow] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const getbanksall = useCallback(() => {
      getbanks()
          .then(res => setBank(res.data.data))
          .catch(error => handleApiError(error, null, null, true, showErrorToast));
      }, [showErrorToast]); // Empty array means `getcardall` doesn't depend on anything
      
      useEffect(() => {
        if (typeof allfunctions === 'function') {
          allfunctions([getbanksall]);
        }
      }, [getbanksall]);
      const addbank = () => {
        setShow(true);
      }
      const handleClose = () => {
        setShow(false);
      }
      const handleRadioChange = (id) => {
        setSelectedOrganization(id); // Set the selected organization ID when the radio button is clicked
      };
    const editorganization = (item) => {
        setMapvalues(item);
        setEditshow(true);
    }
    const deleteorganization = async(item) => {
      try{
          const result = await Swal.fire({
            title: `Delete Bank?`,
            text: `Are you sure you want to delete this Bank`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          });
          if(result.isConfirmed){
            setIsLoading4(true);
            const response = await axiosInstance.get(`${deletebankapiurl}?bank_account_id=${item.id}`);
            if(response.status === 200){
              await getbanksall();
            }
          }
        }catch(error){
            handleApiError(error,setIsLoading4,null,true,showErrorToast);
        }finally{
          setIsLoading4(false);
        }
    }
    const handleClose1 = () => {
      setEditshow(false);
    }
  return (
    <>
       <div className="col-12 col-lg-9 col-md-8 col-xl-9 col-xxl-9 col_corr_1" >
                    <main className="cardsmaindiv"> 
                    <h5 className="ps-4 pt-4 registerservicecard">Bank Account</h5>
                       <p className="cardsp registerservicecard ps-4 mb-4">This is a system "D Transfer" that are awarded to the app user after engaging in some activity on the app, or website</p>
                       <div className="massage_box_corr">
                        {bank.length > 0 ? (
                            <>
                            <div className="d-flex justify-content-between align-items-center">
                            <h5 className="credith5 ps-2">Bank Account</h5>
                            <button className="addnewcard addnewcard2" onClick={addbank}>
                            <img src={plus} className="me-2 plus" alt="plus"></img>Add bank account</button>
                            </div>
                            {bank.map((item,index)=>(
                            <div className="d-flex radio align-items-center w-100 paypalradio " key={index}>
                                    <input type="radio" name={`organization${item.id}`} id={`myRadio${item.id}`}  checked={selectedOrganization === item.id} onChange={() => handleRadioChange(item.id)} style={{  marginRight: '20px', }}></input>
                                    <div className="paybox">
                                    <div className="d-flex justify-content-between align-items-center">
                                    <div className="bankcol-80">
                                    <div className="d-flex justify-content-between bankcol-70div">
                                                    <div className="bankcol-70divmt">
                                                        <p className="paymobilename">Bank Name</p>
                                                        <p className="paybankname">{item.bank_name}</p>
                                                    </div>
                                                    <div className="bankcol-70divmt">
                                                        <p className="paymobilename">Account Holder</p>
                                                        <p className="paybankname">{item.account_holdername}</p>
                                                    </div>
                                                    <div className="bankcol-70divmt">
                                                        <p className="paymobilename">Account Number</p>
                                                        <p className="paybankname">{maskMobileNumber(item.account_number,4)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bankcol-20">
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <img src={edit} onClick={()=>editorganization(item)} className="me-3 paypaldelete paypaldelete1" alt="edit"></img>
                                                        <img src={trash} onClick={()=>deleteorganization(item)} className="paypaldelete" alt="trash"></img>
                                                    </div>   
                                    </div>   
                                    </div>   
                                    </div>
                            </div>
                            ))}
                            </>
                        ) : (
                            <>
                            <div className="d-flex justify-content-between align-items-center">
                             <h5 className="credith5">Bank Account</h5></div>
                            <div className="d-flex flex-column align-items-center regdiv justify-content-center">
                                <img src={registerservicce} alt="registerservice" className="registerimage" ></img>
                                <p className="registerpcard">Please add your first Bank Account to start making Bank transfers</p>
                                <button className="addnewcard1 mb-3" onClick={addbank}><img src={plus} className="me-2 plus" alt="plus"></img>Add Bank Account</button>
                            </div>
                            </>
                        )}
                       </div>
                      </main>
                      </div>
                      <Addbankdetailmodal show={show} name={show ? 'stalin' : ''} handleClose={handleClose} getbanksall={getbanksall} setIsLoading4={setIsLoading4}/>
                      <Bankeditcomponents show={editshow} setMapvalues={setMapvalues} handleClose={handleClose1} mapvalues={mapvalues} getbanksall={getbanksall} setIsLoading4={setIsLoading4} showErrorToast={showErrorToast}/>
    </>
  )
}

export default WithToast(Bank);