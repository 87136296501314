import React,{useState,useEffect} from "react";
import Navbars from "../../navbar";
import Profile from "./profile";
import Privacyterms from "./privacy&terms";
import Emailphone from "./emailphone";
import Recoveryemailphone from "./recoveryemailphone";
import Emergencyseprate from "./emergencyseprate";
import Nationalseperate from "./nationalpagecomponents/nationalseperate";
import Generalseperate from "./generaldocumentcomponents/generalseperate";
const steps = [
    "Profile",
    "Privacy & Terms",
    "Email & Phone",
    "National ID & Documents",
    "General ID Documents",
    "Recovery email & Mobile",
    "Emergency contacts",
  ];
function Profilesetupcomponentchange(){
  const [activeStep, setActiveStep] = useState(() => {
    const storedStep = localStorage.getItem('activeStep');
    return storedStep ? parseInt(storedStep, 10) : 0;
  });

  useEffect(() => {
    localStorage.setItem('activeStep', activeStep);
  }, [activeStep]);
    const [isLoading4,setIsLoading4] = useState(false);
 
    return(
        <>
         {isLoading4 && (
                <>   
                    <div className="loaderover">
                    <div className="loader ">
                    </div>
                    </div>
                </>
            )} 
        <Navbars />
        <div className="container-fluid profile-setup">
        <div className="profilebox">
        <div className="row">
            <div className="col-4 profilesetup">
              <div className="custom-vertical-stepper">
                {steps.map((label, index) => (
                  <div
                    key={index}
                    className={`step ${
                      index === activeStep
                        ? "active"
                        : index < activeStep
                        ? "completed"
                        : ""
                    }`}
                  >
                    <div
                      className={`step-circle ${
                        index === activeStep
                          ? "active"
                          : index < activeStep
                          ? "completed"
                          : ""
                      }`}
                    >
                      <span>{(index + 1).toString().padStart(2, "0")}</span>
                    </div>
                    {/* {index < steps.length - 1 && (
                      <div className="MuiStepConnector-line MuiStepConnector-lineVertical" />
                    )} */}
                    <div
                      className={`step-label ${
                        index === activeStep ? "active" : ""
                      }`}
                    >
                      {label}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-8 profilesetups vertical-lines">
               {activeStep === 0 &&  (
                <Profile setActiveStep={setActiveStep} activeStep={activeStep}   setIsLoading4={setIsLoading4}/>
               )} 
               {activeStep === 1 &&  (
                <Privacyterms setActiveStep={setActiveStep} activeStep={activeStep}   setIsLoading4={setIsLoading4} />
               )}
               {activeStep === 2 && (
                <Emailphone setActiveStep={setActiveStep} activeStep={activeStep}  setIsLoading4={setIsLoading4}/>
               )}
               {activeStep === 3 && (
                <Nationalseperate setActiveStep={setActiveStep} activeStep={activeStep}  setIsLoading4={setIsLoading4}/>
               )}
               {activeStep === 4 &&(
                <Generalseperate setActiveStep={setActiveStep} activeStep={activeStep}  setIsLoading4={setIsLoading4}/>
               )}
               {activeStep === 5 && (
                <Recoveryemailphone setActiveStep={setActiveStep} activeStep={activeStep}  setIsLoading4={setIsLoading4}/>
               )}
               {activeStep === 6 && (
                <Emergencyseprate setActiveStep={setActiveStep} activeStep={activeStep}  setIsLoading4={setIsLoading4}/>
               )}
               {/* {activeStep === 4 && (
                <Emergency setActiveStep={setActiveStep} activeStep={activeStep} setIsLoading4={setIsLoading4}/>
               )}
               {activeStep === 5 && (
                 <Emergencylist setActiveStep={setActiveStep} activeStep={activeStep} setIsLoading4={setIsLoading4} />
               )} */}
            </div>    
        </div>
        </div>    
        </div>
        </>
    );
}
export default Profilesetupcomponentchange;
