import React, { memo,useState,useCallback,useEffect,useRef } from 'react';
import WithToast from '../../../api/hook/withtoasthook';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import delet from '../../../images/times-circle-01.png';
import { disablePastDates,disableFutureDates,handleKeyDown,CustomSuffixIcon, handleApiError} from '../../../api/utils/utils';
import ImageViewer from '../../../components/imageviewer';
import axiosInstance from '../../../api/utils/axiosutils';
import { publicDomain, updatekycgeneraldocumnetapi } from '../../../api/helper/urlhelper';
import { Modal } from 'react-bootstrap';
import Fileuploader from '../../../components/fileuploader';
const publicDomainurl = publicDomain();
const updatekycgeneraldocumnetapiurl = updatekycgeneraldocumnetapi();
const Generaldocumentmodal = memo(({show,setShow,handleClose,doc,setActiveStep, activeStep, setIsLoading4,getgeneraldocid,getgeneral,setShowAddNew,showErrorToast})=>{
    const [error422,setError422] =useState('');
    const [isenabled,setIsenbled] = useState(true);
    const fileInputRef = useRef(null);
    const [formValues, setFormValues] = useState({
        type:'',
        DocumentName:'',
        dateOfIssue: '',
        kyc_id:getgeneraldocid,
        expiryDate: '',
        id:''
      });
      const[isPreviewVisible,setPreviewVisible]=useState(false);
      const [fileSize, setFileSize] = useState('');
      const [format, setFormat] = useState('');
      const [docname,setDocname] = useState('');
      const [uploading, setUploading] = useState(false);
      const [progress, setProgress] = useState(0);
      const [progressColor, setProgressColor] = useState("#28a745");
      const [image, setImage] = useState('');
      const [isOpen, setIsOpen] = useState(false);
      const [error,setError] = useState({
        dateofissueerror:'',frontimageerror:''
      });
      useEffect(() => {    
        if (doc) {     
           setFormValues((prev)=>({
            ...prev,
            type:doc.document_type || '',
            DocumentName:doc.document_name || '',
            dateOfIssue:doc.issue_date || '',
            expiryDate:doc.expiry_date || '',
            kyc_id:doc.kyc_id || '',
            id:doc.id || ''
           }))
           if(doc.front_img){
             const front = doc.front_img.includes(publicDomainurl) ? doc.front_img : `${publicDomainurl}/${doc.front_img}`
            setImage(front);
            setProgress(100);
            setUploading(true);
           }else{
            setImage('');
            setProgress(0);
            setUploading(false);
           }
        }
    }, [doc]); 
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        setError((prev)=>({
          ...prev,dateofissueerror:'',frontimageerror:''
        }))
        setError422('');
      };
      const handleOpenChange = (open) => {
        setIsOpen(open);
      }; 
      const handleExpiryClick = (e) => {
        if (!formValues.dateOfIssue) {
          setError((prevState) => ({
            ...prevState,
            dateofissueerror:'Please select Date of Issue first'
          }));
          // setExpiryerror('Please select Date of Issue first');
          setIsOpen(false);
        } else {
          setError((prevState) => ({
            ...prevState,
            dateofissueerror:''
          }));
          setIsOpen(true);
        }
      };
      const handleFrontview = (image) => {
        setFormValues((prev)=>({
            ...prev,selectimage:[image]
           }));
           setPreviewVisible(true);
           setShow(false);
      }

      const handleNext = async() => {
        const data = {
            kyc_id : formValues.kyc_id,
            document_type: formValues.type,
            document_name:formValues.DocumentName,
            issue_date:formValues.dateOfIssue,
            expiry_date:formValues.expiryDate,
            front_img:image.toString(),
            id:formValues.id
        }
        try{
          setIsLoading4(true);  
          const response = await axiosInstance.post(updatekycgeneraldocumnetapiurl,data);
          if(response.status === 200){
            await getgeneral();
            setShowAddNew(false);
             handleClose1();
             const nextStep = activeStep;
              setActiveStep(nextStep);
              // setActiveStep((prevActiveStep) => prevActiveStep + 1);
              localStorage.setItem('activeStep', nextStep);
              setError422('');
              setIsLoading4(false);
          }
        }catch(error){
          handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
        }
      }
      const handleBack = () => {
         handleClose1();
      }
    const handleClose1 = () => {
        handleClose();
        setError((prev) => ({
          ...prev,
          dateofissueerror:'',frontimageerror:''
        }));
        setError422('');
    }
    const check = useCallback(()=>{
        const valid = (
           formValues.type !== '' && formValues.dateOfIssue !== '' && formValues.expiryDate !== '' && formValues.DocumentName !== '' && image !== ''
        )
        setIsenbled(valid);
    },[formValues.type,formValues.dateOfIssue,formValues.expiryDate,formValues.DocumentName,image]);
    useEffect(()=>{
        check();
    },[check]);
  return (
    <>
      <Modal show={show} size='lg' onHide={handleClose1} contentClassName="generaldocument-content1" centered>
      <Modal.Body style={{ margin: '0', padding: '0' }}>
      <div className="wallet-profilepad2">
      <div className='text-end modal_delete'>
        <img src={delet} onClick={handleClose1} style={{cursor:'pointer'}} alt='delete' width='30px' height='auto'></img>
       </div>
      <div className='row'>
        <div className='col-6'>
        <label className="walletprofile-label">Document Type<span className="docpopstar">*</span></label>
        {/* <Select name="type" options={formValues.type} onChange={(selectOption)=>setFormValues((prev)=>({...prev,type:selectOption}))} className='mt-1' placeholder="Select Document Type"  styles={customStyles1(Boolean(formValues.type))}  /> */}
        <input type="text" value={formValues.type} onChange={handleInputChange} name='type' className={`form-control mt-1 walletprofile-input ${formValues.type ? 'walletprofile-input-active' : ''}`} placeholder="Enter Document Type" /> 
        </div>
        <div className='col-6'>
        <label className="walletprofile-label">Document Name<span className="docpopstar">*</span></label>
        <input type="text" value={formValues.DocumentName} onChange={handleInputChange} name='DocumentName' className={`form-control mt-1 walletprofile-input ${formValues.DocumentName ? 'walletprofile-input-active' : ''}`} placeholder="Enter Document Name" /> 
         </div>   
        </div>
        <div className='mt-3'>
        <div className='row'>
        <div className='col-6'>
        <label className="walletprofile-label">Date Of Issue<span className="docpopstar">*</span></label>
        <DatePicker onKeyDown={handleKeyDown} format="DD-MM-YYYY" getPopupContainer={(trigger) => trigger.parentNode} className='mt-1 custom-pick' disabledDate={disableFutureDates} value={formValues.dateOfIssue ? dayjs(formValues.dateOfIssue) : null} onChange={(date) => { setFormValues((prevValues) => ({ ...prevValues, dateOfIssue: date ? dayjs(date).format('YYYY-MM-DD') : '',expiryDate:''})); setError((prev)=>({...prev,dateofissueerror:'',frontimageerror:''}));}} placeholder='YYYY-MM-DD' style={{ width: '100%', height: '45px', borderRadius: '6px', border: formValues.dob ? '1px solid black' : '1px solid #DCDCDC', color: formValues.dateOfIssue ? 'black' : '' }} suffixIcon={<CustomSuffixIcon isActive={formValues.dateOfIssue} />} />
        {error.dateofissueerror && <p className="error-message">{error.dateofissueerror}</p>}
        </div>
        <div className='col-6'>
        <label className="walletprofile-label">Expiry Date<span className="docpopstar">*</span></label>
        <DatePicker onKeyDown={handleKeyDown} format="DD-MM-YYYY" getPopupContainer={(trigger) => trigger.parentNode} onClick={handleExpiryClick} open={isOpen} onOpenChange={handleOpenChange} className='mt-1 custom-pick' disabledDate={disablePastDates(formValues.dateOfIssue)} value={formValues.expiryDate ? dayjs(formValues.expiryDate) : null} onChange={(date) => { setFormValues((prevValues) => ({ ...prevValues, expiryDate: date ? dayjs(date).format('YYYY-MM-DD') : '', }));setError((prev)=>({...prev,frontimageerror:''}));}} placeholder='YYYY-MM-DD' style={{ width: '100%', height: '45px', borderRadius: '6px', border: formValues.expiryDate ? '1px solid black' : '1px solid #DCDCDC', color: formValues.expiryDate ? 'black' : '' }} suffixIcon={<CustomSuffixIcon isActive={formValues.expiryDate} />} />
         </div>   
        </div>
        </div>
        <Fileuploader handleFrontview={handleFrontview} docname={docname} acceptPDF={false} fileInputRef={fileInputRef} setImage={setImage} format={format} fileSize={fileSize} image={image} progress={progress} setProgress={setProgress} progressColor={progressColor} setProgressColor = {setProgressColor} setFileSize={setFileSize} uploading={uploading} setUploading={setUploading} setFormat={setFormat} setDocname={setDocname} setError422={setError422} setImageerror={(message) =>setError((prev) => ({...prev,frontimageerror: message}))} Acceptformat={['jpg','jpeg','png']}/>
        {error.frontimageerror && <p className="error-message">{error.frontimageerror}</p>}
        {/* {uploading ? (
            <div className="popprogressbox">
            <div className="d-flex align-items-center ">
              <div className="d-flex align-items-center mb-2" style={{width:'75%'}}>
                <img src={image} alt="file" className="popuploadfileimg me-2"></img>
                <div style={{overflow:'hidden'}}>
                <p className="popfilefrontp">{doc.document_name}</p>
                {fileSize && fileSize >= 1024 && (
                  <p className="popfilekb">{formatFileSize(fileSize)}</p>
                )}
                </div>
              </div>
              <div style={{textAlign:'end',width:'25%'}}>
                {progress === 100 && (
                  <img src={eye} alt="eye" onClick={()=>handleFrontview(image)} className="popupeye me-2"></img>
                )}
                <img src={popdeletes} onClick={handleFrontdelete} alt="delete" className="popupeye" ></img>
              </div>
            </div>
            <div className="progress mt-1 mb-1">
              <div className="progress-bar" role="progressbar" style={{ width: `${progress}%`, backgroundColor: progressColor }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
                {``}
              </div>
            </div>
          </div>
          ):(
        <div className="popinputbox mt-3" onClick={imageuploadclick} onDrop={(e) => handleDrop(e, handleFileDrop,handleError,acceptPDF,true)} onDragOver={handleDragOver} >
                <div className="d-flex align-items-center justify-content-center flex-column p-2">
                  <img src={fileopen} className="popuploadflle" alt="file" width='48px' height='auto'></img>
                  <h5 className="popuploadh5"><span className="popspan">Click to Upload</span>or drag and drop</h5>
                  <p className="popuploadp">File format: .png, .jpeg, .jpg</p>
                  <p className="popuploadp">( Max. File size : 20 MB)</p>
                </div>
                <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept=".jpg, .jpeg, .png" onChange={(e) => handleFileChange(e, setImage, setFileSize,setProgress, setProgressColor, setUploading,setFormat,setDocname)} />
              </div>
          )} */}
        {error422 && <p className="error-message">{error422}</p>}
        <div className="walletprofile-buttons mt-3">
          <button
            className={`walletprofile-next me-3 ${isenabled ? 'walletprofile-next-active' : 'walletprofile-next-disabled'}`}
            // disabled={!isenabled}
            onClick={handleNext}
          >
            Save
          </button>
          <button
            className="walletprofile-cancel"
            // disabled={activeStep === 0}
            onClick={handleBack}
          >
            Cancel
          </button>
        </div>
        </div>
      </Modal.Body>
      </Modal>   
      {formValues.selectimage &&
    <ImageViewer isPreviewVisible={isPreviewVisible} selectedImage1={formValues.selectimage} onClose={() => {setPreviewVisible(false);setShow(true)}} isMultiImage={false}/>
    }
    </>
  )
});
export default WithToast(Generaldocumentmodal);