import React from 'react';
import copy from '../images/Group 481.svg';
function Copybutton({title,copybuttons, onClick = () => {}}) {
  return (
    <>
    <button className={copybuttons} onClick={onClick} ><img src={copy} className='me-1' alt='copy' ></img>{title}</button>
    </>
  )
}

export default Copybutton