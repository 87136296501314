import React,{useState,useCallback,useEffect} from 'react';
import WithToast from '../../api/commonfunct.js/withtoast';
import Swal from 'sweetalert2';
import registerservicce from '../../images/registered_services-01.png';
import plus from '../../images/plus.png';
import { allfunctions, handleApiError } from '../../api/commonfunct.js/common';
import { getorganization } from '../addsourcegetapi';
import axiosInstance from '../../api/commonfunct.js/axiosinstance';
import Organizationmodal from './organizationmodal';
import { deleteorganizationapi } from '../../api/url';
import trash  from '../../images/paypal/trash-01.png';
import edit from '../../images/paypal/pen-01.png';
import Organizationeditmodal from './organizationeditmodal';
const deleteorganizationapiurl = deleteorganizationapi();
function Organization({setIsLoading4,showErrorToast}) {
    const [organization,setOrganization] = useState([]);
    const [mapvalues,setMapvalues] = useState([]);
    const [show,setShow] = useState(false);
    const [editshow,setEditshow] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const getorganizationallall = useCallback(() => {
        getorganization()
          .then(res => setOrganization(res.data.data))
          .catch(error => handleApiError(error, null, null, true, showErrorToast));
      }, [showErrorToast]);
      
      useEffect(() => {
        if (typeof allfunctions === 'function') {
          allfunctions([getorganizationallall]);
        }
      }, [getorganizationallall]);
      const addorganization = () => {
          setShow(true);
      }
      const handleClose = () => {
          setShow(false);
      }
      const handleClose1 = () => {
        setEditshow(false);
        setMapvalues([]);
    }
      const handleRadioChange = (id) => {
        setSelectedOrganization(id); // Set the selected organization ID when the radio button is clicked
      };
    const editorganization = (item) => {
        setMapvalues(item);
        setEditshow(true);
    }
      const deleteorganization = async(item) => {
        try{
            const result = await Swal.fire({
              title: `Delete Organization/Company?`,
              text: `Are you sure you want to delete this Organization/Company`,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!'
            });
            if(result.isConfirmed){
              setIsLoading4(true);
              const response = await axiosInstance.get(`${deleteorganizationapiurl}?organization_company_id=${item.id}`);
              if(response.status === 200){
                await getorganizationallall();
              }
            }
          }catch(error){
              handleApiError(error,setIsLoading4,null,true,showErrorToast);
          }finally{
            setIsLoading4(false);
          }
      }
  return (
    <>
       <div className="col-12 col-lg-9 col-md-8 col-xl-9 col-xxl-9 col_corr_1" >
                    <main className="cardsmaindiv" > 
                    <h5 className="ps-4 pt-4 registerservicecard">Organization/Company</h5>
                       <p className="cardsp registerservicecard ps-4 mb-4">This is a system "D Transfer" that are awarded to the app user after engaging in some activity on the app, or website</p>
                       <div className="massage_box_corr">
                        {organization.length > 0 ? (
                            <>
                            <div className="d-flex justify-content-between align-items-center">
                            <h5 className="credith5 ps-2">Organization/Company</h5>
                            <button className="addnewcard addnewcard2" onClick={addorganization}>
                            <img src={plus} className="me-2 plus" alt="plus"></img>Add Organization/Company</button>
                            </div>
                            {organization.map((item,index)=>(
                            <div className="d-flex radio align-items-center w-100 paypalradio " key={index}>
                                    <input type="radio" name={`organization${item.id}`} id={`myRadio${item.id}`}  checked={selectedOrganization === item.id} onChange={() => handleRadioChange(item.id)} style={{  marginRight: '20px', }}></input>
                                    <div className="paybox">
                                    <div className="d-flex justify-content-between align-items-center">
                                    <div className="bankcol-80">
                                    <div className="d-flex justify-content-between bankcol-70div">
                                                    <div className="bankcol-70divmt">
                                                        <p className="paymobilename">Company Name</p>
                                                        <p className="paybankname">{item.organization_name}</p>
                                                    </div>
                                                    <div className="bankcol-70divmt">
                                                        <p className="paymobilename">Company ID</p>
                                                        <p className="paybankname">{item.organization_id}</p>
                                                    </div>
                                                    <div className="bankcol-70divmt">
                                                        <p className="paymobilename">Email</p>
                                                        <p className="paybankname">{item.organization_email}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bankcol-20">
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <img src={edit} onClick={()=>editorganization(item)} className="me-3 paypaldelete paypaldelete1" alt="edit"></img>
                                                        <img src={trash} onClick={()=>deleteorganization(item)} className="paypaldelete" alt="trash"></img>
                                                    </div>   
                                    </div>   
                                    </div>   
                                    </div>
                            </div>
                            ))}        
                            </>
                        ) : (
                            <>
                            <div className="d-flex justify-content-between align-items-center">
                             <h5 className="credith5">Organization/Company</h5></div>
                            <div className="d-flex flex-column align-items-center regdiv justify-content-center">
                                <img src={registerservicce} alt="registerservice" className="registerimage" ></img>
                                <p className="registerpcard">Please add your first Organization/Company to start making Organization/Company  transfers</p>
                                <button className="addnewcard1 mb-3" onClick={addorganization}><img src={plus} className="me-2 plus" alt="plus"></img>Add Organization/Company</button>
                            </div>
                            </>
                        )}
                       </div>
                      </main>
                      </div>

                      <Organizationmodal show={show} handleClose={handleClose} getorganizationallall={getorganizationallall} setIsLoading4={setIsLoading4}/>
                      <Organizationeditmodal show={editshow} handleClose={handleClose1} getorganizationallall={getorganizationallall} setIsLoading4={setIsLoading4} mapvalues={mapvalues}/>
    </>
  )
}

export default WithToast(Organization);