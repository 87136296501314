import React,{useState,useCallback,useEffect} from 'react'
import WithToast from '../../api/commonfunct.js/withtoast';
import registerservicce from '../../images/registered_services-01.png';
import Swal from 'sweetalert2';
import plus from '../../images/plus.png';
import { allfunctions, handleApiError, maskMobileNumber } from '../../api/commonfunct.js/common';
import { getmobilemoney } from '../addsourcegetapi';
import Addmobilemodal from './addmobilemodal';
import { deletemobilemoneysapi } from '../../api/url';
import trash  from '../../images/paypal/trash-01.png';
import edit from '../../images/paypal/pen-01.png';
import axiosInstance from '../../api/commonfunct.js/axiosinstance';
import Mobilemoneyedit from './mobilemoneyedit';
const deletemobilemoneysapiurl = deletemobilemoneysapi();
function Mobilemoney({setIsLoading4,showErrorToast}) {
    const [mobilemoney,setMobilemoney] = useState([]);
    const [show,setShow] = useState(false);
    const [mapvalues,setMapvalues] = useState([]);
    const [editshow,setEditshow] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const getmobilemoneyall = useCallback(() => {
        getmobilemoney()
          .then(res => setMobilemoney(res.data.data))
          .catch(error => handleApiError(error, null, null, true, showErrorToast));
      }, [showErrorToast]); // Empty array means `getcardall` doesn't depend on anything
      
      useEffect(() => {
        if (typeof allfunctions === 'function') {
          allfunctions([getmobilemoneyall]);
        }
      }, [getmobilemoneyall]);
      const addmobilemoney = () => {
        setShow(true);
      } 
      const handleRadioChange = (id) => {
        setSelectedOrganization(id); // Set the selected organization ID when the radio button is clicked
      };
      const handleclose = () => {
        setShow(false);
      }
      const editorganization = (item) => {
        setMapvalues(item);
        setEditshow(true);
      }
      const handleClose1 = () => {
        setEditshow(false);
        setMapvalues([]);
    }
      const deleteorganization = async(item) => {
        try{
            const result = await Swal.fire({
              title: `Delete Mobile Money?`,
              text: `Are you sure you want to delete this Mobile Money`,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!'
            });
            if(result.isConfirmed){
              setIsLoading4(true);
              const response = await axiosInstance.get(`${deletemobilemoneysapiurl}?mobile_money_detail_id=${item.id}`);
              if(response.status === 200){
                await getmobilemoneyall();
              }
            }
          }catch(error){
              handleApiError(error,setIsLoading4,null,true,showErrorToast);
          }finally{
            setIsLoading4(false);
          }
      }
  return (
    <>
      <div className="col-12 col-lg-9 col-md-8 col-xl-9 col-xxl-9 col_corr_1" >
                    <main className="cardsmaindiv"> 
                    <h5 className="ps-4 pt-4 registerservicecard">Mobile Money</h5>
                       <p className="cardsp registerservicecard ps-4 mb-4">This is a system "D Transfer" that are awarded to the app user after engaging in some activity on the app, or website</p>
                       <div className="massage_box_corr">
                        {mobilemoney.length > 0 ? (
                            <>
                            <div className="d-flex justify-content-between align-items-center">
                            <h5 className="credith5 ps-2">Mobile Money</h5>
                            <button className="addnewcard addnewcard2" onClick={addmobilemoney}>
                            <img src={plus} className="me-2 plus" alt="plus"></img>Add Mobile money</button>
                            </div>
                            {mobilemoney.map((item,index)=>(
                            <div className="d-flex radio align-items-center w-100 paypalradio " key={index}>
                                    <input type="radio" name={`organization${item.id}`} id={`myRadio${item.id}`}  checked={selectedOrganization === item.id} onChange={() => handleRadioChange(item.id)} style={{  marginRight: '20px', }}></input>
                                    <div className="paybox">
                                    <div className="d-flex justify-content-between align-items-center">
                                    <div className="bankcol-80">
                                    <div className="d-flex justify-content-between bankcol-70div">
                                      <div>
                                                        <p className="paymobilename">Mobile Number</p>
                                                        <p className="paybankname">{`+${item.code}${maskMobileNumber(item.mobile_number,4)}`}</p>

                                                        </div>
                                                </div>
                                            </div>
                                            <div className="bankcol-20">
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <img src={edit} onClick={()=>editorganization(item)} className="me-3 paypaldelete paypaldelete1" alt="edit"></img>
                                                        <img src={trash} onClick={()=>deleteorganization(item)} className="paypaldelete" alt="trash"></img>
                                                    </div>   
                                    </div>   
                                    </div>   
                                    </div>
                            </div>
                            ))} 
                            </>
                        ) : (
                            <>
                            <div className="d-flex justify-content-between align-items-center">
                             <h5 className="credith5">Mobile Money</h5></div>
                            <div className="d-flex flex-column align-items-center regdiv justify-content-center">
                                <img src={registerservicce} alt="registerservice" className="registerimage" ></img>
                                <p className="registerpcard">Please add your first Mobile Money to start making Mobile Money transfers</p>
                                <button className="addnewcard1 mb-3" onClick={addmobilemoney}><img src={plus} className="me-2 plus" alt="plus"></img>Add Mobile money</button>
                            </div>
                            </>
                        )}
                       </div>
                      </main>
                      </div>
                      <Addmobilemodal show={show} getmobilemoneyall={getmobilemoneyall} handleclose={handleclose} setIsLoading4={setIsLoading4} showErrorToast={showErrorToast}/>
                      <Mobilemoneyedit show={editshow} getmobilemoneyall={getmobilemoneyall} handleclose={handleClose1} setIsLoading4={setIsLoading4} showErrorToast={showErrorToast} mapvalues={mapvalues}/>
    </>
  )
}

export default WithToast(Mobilemoney);