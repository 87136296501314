import React,{useState,useEffect} from 'react';
import Select,{components} from 'react-select';
import useMenuHeight, { countrystyle, selectWidth } from '../api/utils/utils';
const PhoneInputWithCountrySelector = ({countryOptions = [],handleCountryChange,dialCode,mobileChange,handlePhoneNumberChange, handlePaste,isLoading = false,minheight,walletprofileinput,walletprofileinputactive,countryCode}) => {
    
  const customSingleValue = ({ data }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={data.flag}
        alt=""
        style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
      />
    </div>
  );

  const customOption = (props) => (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={props.data.flag}
          alt=""
          style={{ width: '25px', height: 'auto', marginRight: '10px' }}
        />
        {props.data.label}
      </div>
    </components.Option>
  );
  const [selwidth, setSelWidth] = useState(selectWidth(dialCode));
  const menuHeight = useMenuHeight(200, 400);
  useEffect(() => {
    setSelWidth(selectWidth(dialCode));
  }, [dialCode]);
  const handleKeyPress = (e) => {
    // Allow only numeric characters (0-9) and some specific control keys
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
  
    if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };
  return (
      <>
      <div style={{ position: 'absolute', left: 0, zIndex: 1 }}>
        <Select options={countryOptions} onChange={handleCountryChange} value={countryOptions.find((option) => option.value === countryCode)} components={{ SingleValue: customSingleValue, Option: customOption }} placeholder="" isLoading={isLoading}
          styles={countrystyle('82px',menuHeight,minheight)}
        />
      </div>
      <div
        style={{
          height: '30px',
          width: '2px',
          backgroundColor: '#ccc',
          margin: '0 8px',
          position: 'absolute',
          left: `${selwidth}px`,
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      ></div>
      <p style={{position: 'absolute',left: '80px',top: '51%',transform: 'translateY(-50%)',cursor: 'pointer',color: 'hsl(0, 0%, 20%)',fontSize: '14px',lineHeight: '1.5', marginBottom: '0px',fontWeight: '400',}}> {dialCode}</p>
      <input type="text" name="responsephoneNumber" onPaste={handlePaste} value={mobileChange} onKeyPress={handleKeyPress} onChange={(e) => handlePhoneNumberChange(e.target.value)} className={`form-control mt-1 ${walletprofileinput} ${ mobileChange ? walletprofileinputactive : '' }`} style={{ paddingLeft: `${selwidth + 18}px` }} placeholder="Enter your Mobile number"/>
      </>
  );
};

export default PhoneInputWithCountrySelector;
