import axiosInstance from "../../api/utils/axiosutils";
import { getbankapi, getCardsapi, getmobilemoneysapi, getorganzationapi,getbankcountryapi,getbankcityapi,getbanklistapi,getbankbranchapi, country_codeapi, getwalletdetailsapi, addwalletuserapi } from "./urlhelper";
const getCardsapiurl = getCardsapi();
const getmobilemoneysapiurl = getmobilemoneysapi();
const getorganzationapiurl = getorganzationapi();
const getbankapiurl = getbankapi();
const getbankcountryapiurl = getbankcountryapi();
const getbankcityapiurl = getbankcityapi();
const getbanklistapiurl = getbanklistapi();
const getbankbranchapiurl = getbankbranchapi();
const countrycodeurl = country_codeapi();
const getwalletdetailsapiurl = getwalletdetailsapi();
const addwalletuserapiurl = addwalletuserapi();
export const getcards = async() => {
    return await axiosInstance.get(getCardsapiurl);
}
export const getmobilemoney = async() => {
    return await axiosInstance.get(getmobilemoneysapiurl);
} 
export const getorganization = async() => {
    return await axiosInstance.get(getorganzationapiurl);
} 
export const getbanks = async() => {
    return await axiosInstance.get(getbankapiurl);
}
export const getcountrylist = async() => {
    return await axiosInstance.get(getbankcountryapiurl);
}
export const getcitylist = async(data) => {
    return await axiosInstance.post(getbankcityapiurl,data);
}
export const getbanklist = async(data) => {
    return await axiosInstance.post(getbanklistapiurl,data)
}
export const getbankbranchlist = async(data) => {
    return await axiosInstance.post(getbankbranchapiurl,data);
}
export const mcc_list_ip = async() => {
    return await axiosInstance.get(countrycodeurl);
}
export const getwalletdetails = async() => {
    return await axiosInstance.get(getwalletdetailsapiurl);
}
export const addwalletcustomer = async() => {
    return await axiosInstance.get(addwalletuserapiurl);
}