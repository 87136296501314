import { useParams } from "react-router-dom";
import { useState,useCallback,useEffect } from "react";
import dayjs from 'dayjs';
export const Error401 = () => {
  const lod4 = useRouteParams();
  window.location.href = `/refresh?X-Authuser=${encodeURIComponent(lod4)}`;
  return lod4;
}
export const convertImageToBase64 = (file, setImage, setFileSize,) => {
    const reader = new FileReader();
  
    reader.onloadend = () => {
      setImage(reader.result);
    };
  
    if (file) {
      reader.readAsDataURL(file);
      setFileSize(file.size);
    }
};
export const formatFileSize = (sizeInBytes) => {
    const sizeInKB = sizeInBytes / 1024;
    const sizeInMB = sizeInKB / 1024;
  
    if (sizeInMB >= 1) {
      return `${sizeInMB.toFixed(2)} MB`;
    } else {
      return `${sizeInKB.toFixed(2)} KB`;
    }
};
export const handleFileChange = (
    e,
    setImage,
    setFileSize,
    setProgress,
    setProgressColor,
    setUploading,
    setFormat,
    setDocname
  ) => {
    const selectedFile = e.target.files[0];
    const fileName = selectedFile.name;
    const fileFormat = fileName.substring(fileName.lastIndexOf('.') + 1);
    setFormat(fileFormat);
    const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, "");
    const updatedDocname = `${fileNameWithoutExtension}`;
    setDocname(updatedDocname);
    convertImageToBase64(
      selectedFile,
      (image) => {
        // Callback function for setting the image state
        setImage(image);
      },
      (size) => setFileSize(size), // Callback function for setting file size
    );
  
    const totalSteps = 100;
    const processingSteps = 70;
    const stepInterval = 100;
    let currentStep = 0;
    setUploading(true);
    const progressInterval = setInterval(() => {
      currentStep += 50;
      const currentProgress = (currentStep / totalSteps) * 100;
      setProgress(currentProgress);
      if (currentStep === totalSteps) {
        clearInterval(progressInterval);
        setTimeout(() => {
          setProgressColor("#28a745");
          setUploading(true);
          // setEditImage(true);
        }, 500);
      } else if (currentStep === processingSteps) {
        setUploading(true);
      }
    }, stepInterval);
};  
export const handleDragOver = (e) => {
    e.preventDefault();
};
export const handleDrop = (e, handleFileDrop,handleError, acceptPDF = true) => {
  e.preventDefault();

  const droppedFiles = e.dataTransfer.files;
  if (droppedFiles.length > 0) {
    const selectedFile = droppedFiles[0];
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    
    if (acceptPDF) {
      allowedTypes.push('application/pdf');
    }
    if (allowedTypes.includes(selectedFile.type)) {
      handleFileDrop(selectedFile);
    } else {
      handleError('Only JPG, JPEG, PNG' + (acceptPDF ? ', and PDF' : '') + ' files are allowed.');
    }
};
}
export const truncateModel = (model) => {
    if (model && model.length) {
      return model.length > 4 ? model.substring(0, 4) + '...' : model;
    } else {
      return ''; // or any default value you want to return when model is null or undefined
    }
};
export const getMaskedEmail = (email) => {
    if (!email) {
      return null;
    }
    const [username, domain] = email.split('@');
    const maskedUsername = username.charAt(0) + '*'.repeat(username.length - 1);  
    return `${maskedUsername}@${domain}`;
};
export const getMaskedPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      return null;
    }
  
    const lastTwoDigits = phoneNumber.slice(-2);
  
    return `**********${lastTwoDigits}`;
};
export const error401 = () => {
    localStorage.removeItem('access_token');
}
export const useRouteParams = () => {
  const params = useParams();
  const user = params.user
  return user;
}
export const handleApiError = (error, setIsLoading=null, setError422 = null, handle422AsToast = true, showErrorToast) => {
  if (error.response) {
    if (error.response.status === 401) {
      const error_code = error.response.data.error_code;
      if (error_code === 'token_expired' || error_code === 'already_logged_out' ) {
        Error401();
      }else if(error_code === 'session_error'){
        window.location.href = 'https://accounts.dev.evzone.app/'
      }else if(error_code === 'auth_clear'){

      }
      else {
        showErrorToast(error.response.data.mobile_error, '401Error');
      }
    } else if (error.response.status === 422) {
      if (handle422AsToast) {
        showErrorToast(error.response.data.mobile_error, '422Error');
      } else if (setError422) {
        setError422(error.response.data.mobile_error);
      }
    } else if (error.response.status === 500) {
      showErrorToast(error.response.data.mobile_error, '500Error');
    } else {
      showErrorToast(error.response.data.mobile_error, 'UnexpectedError');
    }
  }
  if (typeof setIsLoading === 'function') {
    setIsLoading(false);
  }
};
export const customStyles1 = (hasValue) => ({
  control: (provided,state) => ({
    ...provided,
    borderColor: hasValue ? 'black' : '#DCDCDC',
    height:'45px',
    fontSize:'14px',
    borderRaius:'6px',
    boxShadow: state.isFocused ? 'none' : 'none',
    '&:hover': {
      borderColor: hasValue ? 'black' : '#DCDCDC',
    },
  }),placeholder: (provided) => ({
    ...provided,
    color: '#ABABAB',
    width: '100%',
    fontSize:'14px',
    fontWeight:'500',
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    color: 'black',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: hasValue ? 'black' : '#DCDCDC',
    svg: {
      fill: hasValue ? 'black' : '#B9B9B9',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
});
export const customStyles2 = (hasValue) => ({
  control: (provided,state) => ({
    ...provided,
    borderColor: hasValue ? 'black' : '#DCDCDC',
    height:'40px',
    fontSize:'14px',
    borderRaius:'6px',
    boxShadow: state.isFocused ? 'none' : 'none',
    '&:hover': {
      borderColor: hasValue ? 'black' : '#DCDCDC',
    },
  }),placeholder: (provided) => ({
    ...provided,
    color: '#ABABAB',
    width: '100%',
    fontSize:'13px',
    fontWeight:'500',
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    color: 'black',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: hasValue ? 'black' : '#DCDCDC',
    svg: {
      fill: hasValue ? 'black' : '#B9B9B9',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
});
export const customStyles3 = (hasValue) => ({
  control: (provided,state) => ({
    ...provided,
    borderColor: hasValue ? 'black' : '#9B9898',
    height:'40px',
    fontSize:'14px',
    borderRaius:'6px',
    boxShadow: state.isFocused ? 'none' : 'none',
    '&:hover': {
      borderColor: hasValue ? 'black' : '#9B9898',
    },
  }),placeholder: (provided) => ({
    ...provided,
    color: '#ABABAB',
    width: '100%',
    fontSize:'14px',
    fontWeight:'500',
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    color: 'black',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: hasValue ? 'black' : '#9B9898',
    svg: {
      fill: hasValue ? 'black' : '#9B9898',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
});
export const CustomSuffixIcon = ({isActive}) => (
  <span className="ant-picker-suffix-icon">
    <svg viewBox="64 64 896 896" fill={isActive ? 'hsl(0, 0%, 20%)' : '#DCDCDC'} focusable="false" data-icon="calendar" width="1em" height="1em"  aria-hidden="true"><path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path></svg>
  </span>
);
export const handleKeyDown = (e) => {
  const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Delete", "/", "Tab"];
  if (!allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
};
export const calculateMenuHeight = (offset = 200, maxHeight = 400) => {
  const availableHeight = window.innerHeight - offset;
  return `${Math.min(availableHeight, maxHeight)}px`;
};
const useMenuHeight = (offset = 200, maxHeight = 400) => {
  const [menuHeight, setMenuHeight] = useState(calculateMenuHeight(offset, maxHeight));

  const updateMenuHeight = useCallback(() => {
    setMenuHeight(calculateMenuHeight(offset, maxHeight));
  }, [offset, maxHeight]);

  useEffect(() => {
    updateMenuHeight(); // Initial height calculation
    window.addEventListener('resize', updateMenuHeight); // Listen for resize events

    return () => {
      window.removeEventListener('resize', updateMenuHeight); // Cleanup
    };
  }, [updateMenuHeight]);

  return menuHeight;
};
export default useMenuHeight;
export const handleKeyPress = (e) => {
  // Allow only numeric characters (0-9) and some specific control keys
  const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];

  if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
};
export const disableFutureDates = (current) => {
  return current && current > dayjs().endOf('day');
};
export const disablePastDates = (referenceDate) => (current) => {
  return current && current <= dayjs(referenceDate).startOf('day');
};
// export const disablePastMonth = () => (current) => {
//   return current && current < dayjs().startOf('month');
// };
export const disablePastMonth = (referenceDate) => (current) => {
  return current && current < dayjs(referenceDate).startOf('month');
};
export const handleDragOverfile = (e) => {
  e.preventDefault();
};
export const handleDropfile = (e, handleFileDrop,handleError, acceptPDF = true ,isprimary) => {
  e.preventDefault();

  const droppedFiles = e.dataTransfer.files;
  if (droppedFiles.length > 0) {
    const selectedFile = droppedFiles[0];
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    
    if (acceptPDF) {
      allowedTypes.push('application/pdf');
    }

    if (allowedTypes.includes(selectedFile.type)) {
      handleFileDrop  (selectedFile,isprimary);
    } else {
      handleError('Only JPG, JPEG, PNG' + (acceptPDF ? ', and PDF' : '') + ' files are allowed.',isprimary);
    }
  }
};
export const transformString = (str) => {
  if (!str) return '';
  return str.replace(/\s+/g, '-').toLowerCase();
};
export const getPartAfterKeyword=(url, keyword)=> {
  if (url.includes(keyword)) {
    const partAfterKeyword = url.split(keyword)[1];
    return `${partAfterKeyword.trim()}`;
  }

  // If keyword doesn't match, return 'national' and the file type
  const defaultText = 'national';
  const fileType = url.split('.').pop(); // Extract file type from the URL
  return `${defaultText}.${fileType}`;
}
export const hanlefrontbackimagechange = (
  e,
  isPrimary,
  setImage,
  setFileSize,
  setProgress,
  setProgressColor,
  setUploading,
  setFormat,
  setDocname,
  setEditImage,
  setEditFileSize,
  setEditProgress,
  setEditProgressColor,
  setEditImageDetails,
  setEditFormat,
  setEditDocName
) => {
  const selectedFile = e.target.files[0];
  const fileName = selectedFile.name;
  const fileFormat = fileName.substring(fileName.lastIndexOf('.') + 1);
  const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, "");
  const updatedDocname = `${fileNameWithoutExtension}`;

  (isPrimary ? setFormat : setEditFormat)(fileFormat);
  (isPrimary ? setDocname : setEditDocName)(updatedDocname);

  convertImageToBase64(
    selectedFile,
    (image) => (isPrimary ? setImage(image) : setEditImage(image)),
    (size) => (isPrimary ? setFileSize(size) : setEditFileSize(size))
  );

  const totalSteps = 100;
  const processingSteps = 70;
  const stepInterval = 100;
  let currentStep = 0;
  if(isPrimary){
  setUploading(true);
  }else{
    setEditImageDetails(true);
  }
  const progressInterval = setInterval(() => {
    currentStep += 50;
    const currentProgress = (currentStep / totalSteps) * 100;
    if(isPrimary){
    setProgress(currentProgress);
    }else{
      console.log(currentProgress);
      setEditProgress(currentProgress);
    }
    if (currentStep === totalSteps) { 
      clearInterval(progressInterval);
      setTimeout(() => {
        if(isPrimary){
        setProgressColor("#28a745");
        setUploading(true);
        }else{
          setEditProgressColor("#28a745");
          setEditImageDetails(false)
        }
        // setEditImage(true);
      }, 500);
    } else if (currentStep === processingSteps) {
      if(isPrimary){
      setUploading(true);
      }else{
        setEditImageDetails(false);
      }
    }
  }, stepInterval);
};
export const maskMobileNumber = (number,length) => {
  if (!number) return '';
  const lastFour = number.slice(-length);
  const maskedSection = number.slice(0, -length).replace(/\d/g, '*');
  return `${maskedSection}${lastFour}`;
};


export const allfunctions = (nextFunctions = []) => {
  return new Promise((resolve, reject) => {
    makeAjaxCall()
      .then(() => {
        // Execute the provided functions sequentially
        if (Array.isArray(nextFunctions)) {
          nextFunctions.forEach(func => {
            if (typeof func === 'function') {
              func(); // Call each function
            }
          });
        }
        resolve(); // Resolve after all functions are called
      })
      .catch(error => {
        handleApiError(error);
        reject(error); // Reject in case of an error
      });
  });
};


// export const allfunctions = (nextFunctions = []) => {
//   return new Promise((resolve, reject) => {
//     if (Array.isArray(nextFunctions)) {
//       try {
//         // Execute each function
//         nextFunctions.forEach(func => {
//           if (typeof func === 'function') {
//             func();
//           }
//         });
//         // Resolve the promise once all functions are executed
//         resolve();
//       } catch (error) {
//         // Reject the promise if an error occurs
//         reject(error);
//       }
//     } else {
//       // Reject the promise if nextFunctions is not an array
//       reject(new TypeError('Expected an array of functions'));
//     }
//   });
// };